import * as React from "react";
import { Card, Box, Button } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
export default function NewsItem({ title, content, category, subtitle }) {
  const theme = useTheme();

  return (
    <Card sx={{ margin: "10px" }}>
      <CardActionArea>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "20px",
            backgroundColor: "#fff",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
        >
          <Typography
            gutterBottom
            sx={{ padding: "10px", fontFamily: "Poppins", fontWeight: 700 }}
            component="div"
          >
            {category}
          </Typography>
        </Box>
        <CardMedia
          component="img"
          height="240"
          image={process.env.PUBLIC_URL + "/metalsPic.jpg"}
          alt="green iguana"
        />
        <Box sx={{ bottom: "30px", position: "absolute", left: "20px" }}>
          <Typography
            variant="body2"
            color={theme.palette.secondary.main}
            sx={{ fontFamily: "Poppins", fontWeight: 800 }}
          >
            {subtitle}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="#fff"
            sx={{ fontFamily: "Poppins", fontWeight: 700 }}
          >
            {title}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: "40px", color: "#fff" }}
          >
            Read More
          </Button>
        </Box>
        {/* <CardContent>
          
          
        </CardContent> */}
      </CardActionArea>
    </Card>
  );
}
