import React from 'react';
import {
  Box,
  Card,
  Stack,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PartCard from "../PartCard";
import PartsChart from "../PartsChart";
import { useTheme } from "@mui/material/styles";

const PartDetailsModal = ({ 
  part, 
  open, 
  onClose, 
  onAddPart,
  showAddButtons = true 
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        padding: "20px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          padding: "40px"
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: theme.palette.primary.main,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Card variation="elevated">
          <PartCard
            part={part}
            minPrice={part?.Price}
            maxPrice={part?.Price}
          />
        </Card>
        
        {showAddButtons && (
          <Card variation="elevated" style={{ marginTop: "20px" }}>
            <Stack direction="row" justifyContent="center" spacing={10}>
              <Button
                size="large"
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  width: "60px",
                  height: "60px",
                }}
                onClick={() => onAddPart(part, 1)}
              >
                +1
              </Button>

              <Button
                size="large"
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  width: "60px",
                  height: "60px",
                }}
                onClick={() => onAddPart(part, 0.5)}
              >
                +½
              </Button>
            </Stack>
          </Card>
        )}
      </Box>
    </Modal>
  );
};

export default PartDetailsModal; 