import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Snackbar,
  Stack,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateUser, updatePricelist } from "../../graphql/mutations";
import { listPricelists } from "../../graphql/queries";  // Add this import
import { API, graphqlOperation, Storage } from "aws-amplify";
import { colors } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import awsmobile from "../../aws-exports";

const validationSchema = yup.object({
  contactName: yup.string("Enter contact name").required("Contact name is required"),
  email: yup.string("Enter email").email("Enter a valid email").required("Email is required"),
  cellNumber: yup.string("Enter cell number").required("Cell number is required"),
  companyName: yup.string("Enter company name"),
  city: yup.string("Enter city name"),
  address: yup.string("Enter address"),
  state: yup.string("Enter state"),
  zip: yup.string("Enter zip"),
  phoneNumber: yup.string("Enter phone number"),
});

const ColorSwatch = ({ color, onClick, selected }) => (
  <IconButton
    onClick={onClick}
    sx={{
      width: 40,
      height: 40,
      backgroundColor: color,
      border: selected ? '2px solid black' : 'none',
      "&:hover": {
        backgroundColor: color,
      },
    }}
  />
);

const SuperAdminUpdateForm = ({ user, onComplete }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [logo, setLogo] = useState(user.companyLogo);

  const formik = useFormik({
    initialValues: {
      contactName: user.contactName || "",
      email: user.email || "",
      cellNumber: user.cellNumber || "",
      companyName: user.companyName || "",
      address: user.address || "",
      city: user.city || "",
      state: user.state || "",
      zip: user.zip || "",
      phoneNumber: user.phoneNumber || "",
      companyPath: user.companyPath || "",
      subscriptionActive: user.subscriptionActive || false,
      subscriptionTier: user.subscriptionTier || "",
      subscriptionTerm: user.subscriptionTerm || "",
      searchesRemaining: user.searchesRemaining || 0,
      primaryColor: user.primaryColor || colors.blue[500],
      secondaryColor: user.secondaryColor || colors.orange[500],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("Form submitted with values:", values);
      try {
        const updatedUser = await API.graphql(graphqlOperation(updateUser, {
          input: {
            id: user.id,
            ...values,
            companyLogo: logo,
          }
        }));
        console.log("User updated successfully:", updatedUser.data.updateUser);
        setSnackbarMessage("User details updated successfully!");
        setShowFeedback(true);
        onComplete(updatedUser.data.updateUser);
      } catch (error) {
        console.error("Error updating user:", error);
        if (error.errors) {
          error.errors.forEach(err => console.error("GraphQL error:", err.message));
        }
        setSnackbarMessage("Error updating user details. Please check the console for more information.");
        setShowFeedback(true);
      }
    },
  });

  const handleColorChange = (colorType, color) => {
    formik.setFieldValue(colorType, color);
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `logos/${user.id}-${Date.now()}.${fileExtension}`;
      
      await Storage.put(fileName, file, {
        contentType: file.type,
        acl: 'public-read' // This makes the file publicly readable
      });

      // Generate a public URL that doesn't require authentication
      const logoUrl = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${fileName}`;
      setLogo(logoUrl);
      formik.setFieldValue('companyLogo', logoUrl);
      
      // Update all pricelists with the new logo
      const pricelistsResponse = await API.graphql(graphqlOperation(listPricelists, {
        filter: { userId: { eq: user.id } }
      }));
      const pricelists = pricelistsResponse.data.listPricelists.items;

      for (const pricelist of pricelists) {
        await API.graphql(graphqlOperation(updatePricelist, {
          input: { id: pricelist.id, providerLogo: logoUrl }
        }));
      }

      setSnackbarMessage("Logo uploaded and propagated successfully!");
      setShowFeedback(true);
    } catch (error) {
      console.error("Error uploading logo:", error);
      setSnackbarMessage("Error uploading logo. Please try again.");
      setShowFeedback(true);
    }
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Typography variant="h6" gutterBottom>
        Edit User Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="contactName"
            name="contactName"
            label="Contact Name"
            value={formik.values.contactName}
            onChange={formik.handleChange}
            error={formik.touched.contactName && Boolean(formik.errors.contactName)}
            helperText={formik.touched.contactName && formik.errors.contactName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="cellNumber"
            name="cellNumber"
            label="Cell Number"
            value={formik.values.cellNumber}
            onChange={formik.handleChange}
            error={formik.touched.cellNumber && Boolean(formik.errors.cellNumber)}
            helperText={formik.touched.cellNumber && formik.errors.cellNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="companyName"
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="address"
            name="address"
            label="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            id="city"
            name="city"
            label="City"
            value={formik.values.city}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            id="state"
            name="state"
            label="State"
            value={formik.values.state}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            id="zip"
            name="zip"
            label="Zip Code"
            value={formik.values.zip}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="companyPath"
            name="companyPath"
            label="Company Path"
            value={formik.values.companyPath}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.subscriptionActive}
                onChange={formik.handleChange}
                name="subscriptionActive"
              />
            }
            label="Subscription Active"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="subscription-tier-label">Subscription Tier</InputLabel>
            <Select
              labelId="subscription-tier-label"
              id="subscriptionTier"
              name="subscriptionTier"
              value={formik.values.subscriptionTier}
              onChange={formik.handleChange}
              label="Subscription Tier"
            >
              <MenuItem value="basic provider">Basic Provider</MenuItem>
              <MenuItem value="enterprise provider">Enterprise Provider</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="subscription-term-label">Subscription Term</InputLabel>
            <Select
              labelId="subscription-term-label"
              id="subscriptionTerm"
              name="subscriptionTerm"
              value={formik.values.subscriptionTerm}
              onChange={formik.handleChange}
              label="Subscription Term"
            >
              <MenuItem value="month to month">Month to Month</MenuItem>
              <MenuItem value="annual">Annual</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="searchesRemaining"
            name="searchesRemaining"
            label="Searches Remaining"
            type="number"
            value={formik.values.searchesRemaining}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Primary Color</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(colors).map(([colorName, colorShades]) => (
              <ColorSwatch
                key={colorName}
                color={colorShades[500]}
                onClick={() => handleColorChange("primaryColor", colorShades[500])}
                selected={formik.values.primaryColor === colorShades[500]}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Secondary Color</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(colors).map(([colorName, colorShades]) => (
              <ColorSwatch
                key={colorName}
                color={colorShades[300]}
                onClick={() => handleColorChange("secondaryColor", colorShades[300])}
                selected={formik.values.secondaryColor === colorShades[300]}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Company Logo</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                style={{ display: "none" }}
                id="logo-file-input"
              />
              <label htmlFor="logo-file-input">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<UploadFileIcon />}
                >
                  Upload Logo
                </Button>
              </label>
              {logo && (
                <Box sx={{ maxWidth: '100px', maxHeight: '100px', overflow: 'hidden' }}>
                  <img 
                    src={logo} 
                    alt="Company Logo" 
                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }} 
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ marginTop: '20px' }}>
        <Button 
          variant="contained" 
          type="submit" 
          disabled={formik.isSubmitting}
          size="large"
        >
          {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>
      <Snackbar
        open={showFeedback}
        autoHideDuration={3000}
        onClose={() => setShowFeedback(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default SuperAdminUpdateForm;
