import React, { useEffect, useState } from "react";
import { Divider, Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import WikiImage from "./WikiImage";
// import rawCali from "../cali-data.json";
import { useTheme } from "@mui/material/styles";

export default function VehicleCard({ car }) {
  const [mlPrice, setMlPrice] = useState(null);
  console.log("car in vehicle card", car);
  const theme = useTheme();

  if (car === undefined) {
    return null;
  }

  const wikiModel = car.Make === "Ford" && car.Model.indexOf("F-") > -1
    ? "F-Series"
    : car.Model;

  const articleTitle = `${car.Make} ${wikiModel}`;

  return (
    <Card
      sx={{
        minWidth: "200px",
        maxWidth: "400px",
        marginTop: "10px",
        marginBottom: "20px",
        borderRadius: "10px",
      }}
    >
      <CardActionArea>
        {/* <WikiImage articleTitle={`${car.Make}`} /> */}
        <WikiImage articleTitle={articleTitle} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {car.Year} {car.Make} {car.Model}
          </Typography>
          {car["Number of Converters"] && (
            <Typography variant="body2" color="text.secondary">
              <strong> Number of Converters:</strong>{" "}
              {car["Number of Converters"]}
            </Typography>
          )}
          {car["Locations"] && (
            <Typography variant="body2" color="text.secondary">
              <strong>Converter Locations:</strong> {car["Locations"]}
            </Typography>
          )}

          <div style={{ marginTop: "15px", marginBottom: "15px" }}>
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: "10px",
                marginBottom: "15px",
              }}
            >
              <Typography
                variant="p"
                color="text.secondary"
                style={{
                  color: "#fff",
                }}
              >
                <strong>ESTIMATED PRICE RANGE FOR CONVERTERS</strong>{" "}
              </Typography>
            </div>

            <Divider />

            <Typography
              sx={{ marginTop: "5px", textAlign: "center" }}
              variant="h6"
              color="text.primary"
            >
              {car.priceRange}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      {/* <div>
        <Button variant="outlined" color="error" style={{ margin: "10px" }}>
          <DeleteIcon sx={{ mr: 1 }} />
          DELETE
        </Button>
        <Button variant="outlined" color="success" style={{ margin: "10px" }}>
          <EditIcon sx={{ mr: 1 }} />
          EDIT
        </Button>
      </div> */}
    </Card>
  );
}
