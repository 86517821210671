import React from 'react';
import {
  Paper,
  Typography,
  Badge,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useUser } from '../../../UserContext'; // Add this import
import { useNavigate } from 'react-router-dom'; // Add this import

const SearchCounter = ({ searchesRemaining }) => {
  const theme = useTheme();
  const { activeProvider } = useUser(); // Add this line
  const navigate = useNavigate(); // Add this line

  // Add this function
  const getRelativeLink = (path) => {
    if (activeProvider) {
      return `/${activeProvider.companyPath}${path}`;
    }
    return path;
  };

  // Modify handleGetMoreSearches
  const handleGetMoreSearches = () => {
    navigate(getRelativeLink('/contact'));
  };

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "200px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        padding: "20px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows[10],
        },
        marginBottom: "15px",
      }}
    >
      <Badge
        badgeContent={searchesRemaining}
        color={searchesRemaining > 10 ? "primary" : "error"}
        sx={{ marginBottom: "10px" }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
          Remaining Searches
        </Typography>
      </Badge>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ShoppingCartIcon />}
          onClick={handleGetMoreSearches}
        >
          Get More Searches
        </Button>
      </Box>
    </Paper>
  );
};

export default SearchCounter;