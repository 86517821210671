import React, { useContext } from 'react';
import { Badge, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppContext } from '../App';

const NotificationIcon = () => {
  const { notifications } = useContext(AppContext);

  return (
    <IconButton color="inherit">
      <Badge badgeContent={notifications.length} color="secondary">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
};

export default NotificationIcon;