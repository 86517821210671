import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import Disclaimer from "./Disclaimer";
import MappedVINsUpload from "./MappedVINsUpload";
import MetalPriceChart from "./MetalsChart"; // Add this import
import { useUser } from "../UserContext"; // Add this import

const IconTile = ({ icon, title, onClick }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "200px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        padding: "20px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows[10],
        },
      }}
      onClick={onClick}
    >
      {React.cloneElement(icon, { sx: { fontSize: 60, mb: 2, color: theme.palette.secondary.main } })}
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </Paper>
  );
};

const LiteDashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { userClass } = React.useContext(AppContext);
  const { activeProvider } = useUser(); // Add this line
  const [showMappedVINsUpload, setShowMappedVINsUpload] = React.useState(true);

  // Add this function
  const getRelativeLink = (path) => {
    if (activeProvider) {
      return `/${activeProvider.companyPath}${path}`;
    }
    return path;
  };

  const tiles = [
    {
      icon: <SearchIcon />,
      title: "Search Pricelists",
      onClick: () => navigate(getRelativeLink("/search-pricelist")),
      showAlways: true,
    },
    {
      icon: <MonetizationOnIcon />,
      title: "Manage Pricelists",
      onClick: () => navigate(getRelativeLink("/view-pricelists")),
      showAdmin: true,
    },
    {
      icon: <PeopleOutlineIcon />,
      title: "Manage Customers",
      onClick: () => navigate(getRelativeLink("/users")),
      showAdmin: true,
    },
    {
      icon: <GroupsIcon />,
      title: "Manage User Groups",
      onClick: () => navigate(getRelativeLink("/user-group-manager")),
      showAdmin: true,
    },
    // {
    //   icon: <AdminPanelSettingsIcon />,
    //   title: "Admin Page",
    //   onClick: () => navigate("/admin"),
    //   showAdmin: true,
    // },
    // {
    //   icon: <UploadFileIcon />,
    //   title: "Upload Mapped VIN",
    //   onClick: () => setShowMappedVINsUpload(true),
    //   showSuperAdmin: true,
    // },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ marginTop: '20px' }}>
          Welcome to Catalyst and Core
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph>
          Revolutionizing the buying and selling of Catalytic Converters
        </Typography>
        
        

        <Box sx={{ mt: 6, mb: 8 }}>
          <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'center' }}>
            {tiles.map((tile, index) => (
              (tile.showAlways || (userClass === "Admin" && tile.showAdmin) || (userClass === 'SuperAdmin' && (tile.showAdmin || tile.showSuperAdmin))) && (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <IconTile {...tile} />
                </Grid>
              )
            ))}
          </Grid>
        </Box>
        {/* Add MetalPriceChart component here */}
        <Stack style={{marginTop: "20px", marginBottom: "20px"}}>
          <MetalPriceChart metalSymbols={["PD", "PT", "RH"]} />
        </Stack>
        {showMappedVINsUpload && userClass === 'SuperAdmin' && (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Paper elevation={3} sx={{ p: 4, maxWidth: 600, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h5" align="center" gutterBottom>
                SuperAdmin Only
              </Typography>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <MappedVINsUpload />
              </Box>
            </Paper>
          </Box>
        )}
        <Box sx={{ mt: 8 }}>
          <Disclaimer />
        </Box>
      </Box>
    </Container>
  );
};

export default LiteDashboard;
