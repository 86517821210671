import axios from "axios";

export const getML = async (year, cc, fuelgrade) => {
  const response = await fetch(
    `https://816b-74-139-72-113.ngrok-free.app/getmlprice`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the type of content being sent
        "ngrok-skip-browser-warning": true,
      },
      body: JSON.stringify({ year, cc, fuelgrade }),
    }
  );

  const cleanResponse = await response.json();
  console.log("got response", cleanResponse);
};

export const getMLconsumption = async (year, consumption) => {
  try {
    const response = await fetch(
      `https://816b-74-139-72-113.ngrok-free.app/getmlprice`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the type of content being sent
          "ngrok-skip-browser-warning": true,
        },
        body: JSON.stringify({ year, consumption }),
      }
    );

    const cleanResponse = await response.json();
    console.log("got response", cleanResponse);
    return cleanResponse.price;
  } catch (err) {
    return false;
  }
};
export const fetchVehicleInfo = async (vin) => {
  try {
    const link = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`;
    console.log(link);
    const response = await axios.get(link);

    return response.data;
  } catch (error) {
    console.error("Error fetching vehicle info:", error);
  }
};

export const fetchVehicleInfoExtended = async (vin) => {
  try {
    const link = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinExtended/${vin}?format=json`;
    console.log(link);
    const response = await axios.get(link);

    return response.data;
  } catch (error) {
    console.error("Error fetching vehicle info:", error);
  }
};

export const motorSample = {
  Attributes: {
    AxleTypes: [],
    BedTypes: [
      {
        BedTypeID: 1,
        Length: "N/R",
        LengthMetric: "N/R",
        Type: "N/R",
      },
    ],
    BodyStyles: [
      {
        BodyStyleID: 1,
        DoorCount: "4",
        Type: "Sedan",
      },
    ],
    Brakes: [
      {
        ABS: "4-Wheel ABS",
        BrakeID: 2,
        FrontType: "Disc",
        RearType: "Disc",
        System: "Power",
      },
    ],
    CabTypes: [],
    Countries: [
      {
        Code: "USA",
        CountryID: 1,
        Name: "United States",
      },
    ],
    DriveTypes: [
      {
        DriveTypeID: 4,
        Type: "RWD",
      },
    ],
    Engines: [
      {
        Aspiration: "Naturally Aspirated",
        BlockType: "V",
        CID: "-",
        CylinderCC: "3498",
        CylinderHeadType: "DOHC",
        CylinderLiter: "3.5",
        Cylinders: "6",
        Description: "3.5L V6 (272.961) GAS FI",
        Designation: "272.961",
        EngineBoreInch: "3.66",
        EngineBoreMetric: "93.0",
        EngineID: 3442,
        EngineStrokeInch: "3.39",
        EngineStrokeMetric: "86.1",
        EngineVIN: "-",
        FuelDeliveryInfo: {
          ControlType: "Electronic",
          FuelDeliveryID: 144,
          SubType: "MFI",
          SystemDesign: "Bosch ME-9.7",
          Type: "FI",
        },
        FuelType: "GAS",
        HorsePower: "268",
        IgnitionSystem: "Distributorless",
        KilowattPower: "200",
        Manufacturer: "Mercedes-Benz",
        Valves: "24",
        Version: "N/R",
      },
    ],
    ManufacturerBodyCodes: [
      {
        Code: "204.056",
        ManufacturerBodyCodeID: 2463,
      },
    ],
    Springs: [
      {
        FrontType: "Coil",
        RearType: "Coil",
        SpringID: 1,
      },
    ],
    Steering: [
      {
        SteeringID: 1,
        System: "Power",
        Type: "Rack",
      },
    ],
    SubModels: [
      {
        SubModelID: 74,
        SubModelName: "Sport",
      },
    ],
    Transmissions: [
      {
        ControlType: "Automatic",
        ElectronicControl: "Y",
        ManufacturerCode: "722.9",
        Speed: "7",
        TransmissionID: 1365,
        Type: "Transmission",
      },
    ],
    Wheel: [
      {
        WheelBase: "-",
        WheelBaseID: 1,
        WheelBaseMetric: "-",
      },
    ],
  },
  ValidVehicleConfigurations: [
    {
      AxleTypeID: 0,
      BaseVehicleID: 22203,
      BodyStyleID: 1,
      CabTypeID: 0,
      CountryID: 1,
      DriveTypeID: 4,
      EngineID: 3442,
      IsActiveBaseVehicle: true,
      IsActiveValidVehicleConfiguration: true,
      SubModelID: 74,
      TransmissionID: 1365,
      ValidVehicleConfigurationID: 76186,
      VehicleID: 61216,
    },
  ],
};
