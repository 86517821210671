import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SearchIcon from "@mui/icons-material/Search";

function PricelistInfoSection() {
  const theme = useTheme();

  return (
    <Card sx={{ mb: 3, p: 2, backgroundColor: "#f5f5f5", borderRadius: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MonetizationOnIcon
              sx={{ color: theme.palette.secondary.main }}
              fontSize="large"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h5" component="div" gutterBottom>
              Manage Your Pricelists Efficiently
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Use this section to upload, manage, and distribute your catalytic
              converter pricelists. Assign pricelists to specific customers or
              groups, ensuring they always have access to the most up-to-date
              pricing information. Maximize your sales by using our advanced
              features like real-time market monitoring, AI-driven insights, and
              inventory management.
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" aria-label="learn more">
              <InfoIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PricelistInfoSection;
