import { API, graphqlOperation } from "aws-amplify";
import { listUsers } from "../graphql/queries";

const listUsersCustom = `
  query ListUsers(
    $limit: Int,
    $filter: ModelUserFilterInput
  ) {
    listUsers(limit: $limit, filter: $filter) {
      items {
        id
        username
        email
        activePricelistID
        activePricelist {
          id
          name
        }
      }
    }
  }
`;

export const fetchUsers = async (userRecord, limit = 100) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listUsersCustom, {
        limit,
        filter: {
          priceListInvites: {
            contains: userRecord.id,
          },
        },
      })
    );
    return response.data.listUsers.items || [];
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

export const fetchUserByEmail = async (email) => {
  try {
    const userData = await API.graphql(graphqlOperation(listUsers, {
      filter: { email: { eq: email } }
    }));
    return userData.data.listUsers.items[0];
  } catch (error) {
    console.error("Error fetching user by email:", error);
    return null;
  }
};