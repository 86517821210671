import React, { useEffect, useState } from "react";

const WikipediaImage = ({ articleTitle }) => {
  const [imageUrl, setImageUrl] = useState("");

  console.log("articleTitle", articleTitle);

  useEffect(() => {
    const fetchWikipediaImage = async () => {
      try {
        // First, search for the page
        const searchResponse = await fetch(
          `https://en.wikipedia.org/w/api.php?action=opensearch&search=${encodeURIComponent(articleTitle)}&limit=1&namespace=0&format=json&origin=*`
        );
        const [, , , urls] = await searchResponse.json();
        
        if (urls && urls.length > 0) {
          const pageTitle = urls[0].split('/').pop();
          console.log("pageTitle", pageTitle);
          
          // Then, fetch the page image
          const imageResponse = await fetch(
            `https://en.wikipedia.org/w/api.php?action=query&titles=${encodeURIComponent(pageTitle)}&prop=pageimages&format=json&pithumbsize=400&origin=*`
          );
          const data = await imageResponse.json();
          
          const pages = data.query.pages;
          const firstPage = pages[Object.keys(pages)[0]];
          console.log("firstPage", firstPage);
          if (firstPage.thumbnail && firstPage.thumbnail.source) {
            setImageUrl(firstPage.thumbnail.source);
          } else {
            console.log(`No image found for ${articleTitle}`);
            setImageUrl("");
          }
        } else {
          console.log(`No Wikipedia page found for ${articleTitle}`);
          setImageUrl("");
        }
      } catch (error) {
        console.error("Failed to fetch Wikipedia image", error);
        setImageUrl("");
      }
    };

    fetchWikipediaImage();
  }, [articleTitle]);

  return (
    <div>
      {imageUrl && (
        <img src={imageUrl} alt={articleTitle} width="100%" />
      )}
    </div>
  );
};

export default WikipediaImage;
