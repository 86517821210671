import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ContactIcon from "@mui/icons-material/ContactSupport";
import AIIcon from "@mui/icons-material/ArtTrack";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import OurAppBar from "./OurAppBar";
import { Link, useLocation, useParams } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../config"; // Import the appConfig object from config.js
import { AppContext } from "../App";
import NotificationIcon from "./NotificationIcon";
import { useUser } from "../UserContext";


const drawerWidth = 240;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ResponsiveDrawer(props) {
  const { window, signOut } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const open = Boolean(anchorEl);

  const { userClass, userRecord } = useUser();

  console.log("user", userRecord)

  const activeProvider = userRecord

  console.log("userClass", userClass);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((prevMobileOpen) => !prevMobileOpen);
  };

  const handleMenuItemClick = (event, index) => {
    // setSeflectedIndex(index);
    // setAnchorEl(null);
  };

  const handleClickUserMenu = (event) => {
    console.log("clicked");
    setAnchorEl(event.currentTarget);
  };

  async function signOutUser() {
    console.log("signing out user");
    try {
      await Auth.signOut({ global: true });
      console.log("User signed out successfully");
      // Additional code to handle user sign-out (e.g., redirect to login page)
    } catch (error) {
      console.error("Error signing out user:", error);
    }
  }

  const location = useLocation();
  const { providerName } = useParams();

  const getRelativeLink = (path) => {
    // console.log('activeProvider', activeProvider)
    if (activeProvider) {
      return `/${activeProvider.companyPath}${path}`;
    }
    return path;
  };

  const drawer = (
    <div>
      {userRecord && userRecord.companyLogo ? (
        <img
          src={userRecord.companyLogo}
          alt="Company Logo"
          style={{ width: "100%", padding: "20px" }}
          onError={(e) => {
            console.error("Error loading logo:", e);
            e.target.src = process.env.PUBLIC_URL + "/CC Logo (Black Background).png";
          }}
        />
      ) : (
        <img
          src={process.env.PUBLIC_URL + "/CC Logo (Black Background).png"}
          alt="Default Logo"
          style={{ width: "100%" }}
        />
      )}
      <List>
        <ListItem key="Home" disablePadding>
          <ListItemButton component={Link} to={getRelativeLink('/')}>
            <ListItemIcon color="inherit">
              <HomeIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>

            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider
        style={{
          backgroundColor: "white",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      />

      <List>
        <ListItem key="Search" disablePadding>
          <ListItemButton component={Link} to={getRelativeLink('/search-pricelist')}>
            <ListItemIcon color="inherit">
              <SearchIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>
            <ListItemText primary="Search" />
          </ListItemButton>
        </ListItem>

        {["Admin", "SuperAdmin"].includes(userClass) && (
          <ListItem key="AdminHeader" sx={{ width: "100%" }}>
            <ListSubheader
              primary="Admin"
              sx={{
                fontWeight: "bold !important",
                color: theme.palette.primary.main,
                // marginLeft: "20px",
              }}
            >
              Admin
            </ListSubheader>
          </ListItem>
        )}

        {["Admin", "SuperAdmin"].includes(userClass) && (
          <Divider
            style={{
              backgroundColor: "white",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          />
        )}

        {["SuperAdmin", "Admin"].includes(userClass) && (
          <ListItem key="Pricing" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/manage-pricelists')}>
              <ListItemIcon color="inherit">
                <MonetizationOnIcon
                  sx={{ color: theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <ListItemText primary="Pricing" />
            </ListItemButton>
            {/* <Chip
              label="Admin"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            /> */}
          </ListItem>
        )}

        {["Admin", "SuperAdmin"].includes(userClass) && (
          <ListItem key="Your Customers" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/users')}>
              <ListItemIcon color="inherit">
                <PeopleOutlineIcon
                  sx={{ color: theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <ListItemText primary="Your Customers" />
            </ListItemButton>
            {/* <Chip
              label="Admin"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            /> */}
          </ListItem>
        )}

        {["Admin", "SuperAdmin"].includes(userClass) && (
          <ListItem key="Manage User Groups" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/user-group-manager')}>
              <ListItemIcon color="inherit">
                <PeopleOutlineIcon
                  sx={{ color: theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <ListItemText primary=" User Groups" />
            </ListItemButton>
            {/* <Chip
              label="Admin"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            /> */}
          </ListItem>
        )}

{["Admin", "SuperAdmin"].includes(userClass) && (
          <ListItem key="Invoicing" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/invoices')}>
              <ListItemIcon color="inherit">
                <SettingsIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Invoicing" />
            </ListItemButton>
           
          </ListItem>
        )}
      </List>
      <List>
        {["Admin", "SuperAdmin"].includes(userClass) && (
          <ListSubheader
            primary="Admin"
            sx={{
              fontWeight: "bold !important",
              color: theme.palette.primary.main,
              // marginLeft: "20px",
            }}
          >
            SuperAdmin
          </ListSubheader>
        )}
        <Divider
          style={{
            backgroundColor: "white",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        />

{userClass === "SuperAdmin" && (
          <ListItem key="Invoicing" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/super-admin-users')}>
              <ListItemIcon color="inherit">
                <SettingsIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Global Users" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        {userClass === "SuperAdmin" && (
          <ListItem key="Invoicing" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/invoices')}>
              <ListItemIcon color="inherit">
                <SettingsIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Invoicing" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        {/* <ListItem key="Your Company" disablePadding>
          <ListItemButton component={Link} to="/users">
            <ListItemIcon color="inherit">
              <BusinessIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>
            <ListItemText primary="Your Company" />
          </ListItemButton>
        </ListItem> */}

        {userClass === "SuperAdmin" && (
          <ListItem key="Customer Notifications" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/alerts')}>
              <ListItemIcon color="inherit">
                <NotificationsIcon
                  sx={{ color: theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <ListItemText primary="Customer Notifications" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        {userClass === "SuperAdmin" && (
          <ListItem key="Market News" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/news')}>
              <ListItemIcon color="inherit">
                <NewspaperIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Market News" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        {userClass === "SuperAdmin" && (
          <ListItem key="Analytics" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/analytics')}>
              <ListItemIcon color="inherit">
                <AnalyticsIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        {userClass === "SuperAdmin" && (
          <ListItem key="Trending Units" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/trending')}>
              <ListItemIcon color="inherit">
                <TrendingUpIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Trending Units" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}
      </List>
      <List>
  

        <ListItem key="Account Settings" disablePadding>
          <ListItemButton component={Link} to={getRelativeLink('/settings')}>
            <ListItemIcon color="inherit">
              <SettingsIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>

        <ListItem key="Contact Support" disablePadding>
          <ListItemButton component={Link} to={getRelativeLink('/contact')}>
            <ListItemIcon color="inherit">
              <ContactIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>
            <ListItemText primary="Contact Support" />
          </ListItemButton>
        </ListItem>

        {userClass === "SuperAdmin" && (
          <ListItem key="Catalyst AI" disablePadding>
            <ListItemButton component={Link} to={getRelativeLink('/catalyst-ai')}>
              <ListItemIcon color="inherit">
                <AIIcon sx={{ color: theme.palette.secondary.main }} />
              </ListItemIcon>
              <ListItemText primary="Catalyst AI" />
            </ListItemButton>
            <Chip
              label="SA"
              size="small"
              color="primary"
              sx={{ marginRight: "10px" }}
            />
          </ListItem>
        )}

        <ListItem key="Sign Out" disablePadding>
          <ListItemButton onClick={signOutUser}>
            <ListItemIcon color="inherit">
              <ExitToAppIcon sx={{ color: theme.palette.secondary.main }} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Stack
      sx={{ display: "flex", maxWidth: "100%", flex: 1, overlowX: "hidden" }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        maxWidth="100%"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <OurAppBar handleDrawerToggle={handleDrawerToggle}>
          <NotificationIcon />
        </OurAppBar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          paddingRight: "20px",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              marginRight: "40px",
            },
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
        
          {drawer}
          <img
            src={process.env.PUBLIC_URL + "/CC Logo (Black Background).png"}
            style={{ width: "100%" }}
          />
        </Drawer>
      </Box>
      <Box
        style={{
          marginTop: "40px",
          maxWidth: "100%",
          lg: { marginLeft: "50px" },
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
}
