import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Input,
  Container,
  Fab,
} from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import NavigationIcon from "@mui/icons-material/Navigation";

export default function NewsEntryModal() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);
  const handleDateChange = (date) => setSelectedDate(date);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Do something with the file (e.g., upload to server)
    }
  };

  const handleSubmit = () => {
    // Submit your blog entry here
    console.log({
      title,
      content,
      selectedDate,
    });
    handleClose();
  };

  return (
    <div>
      <Fab
        variant="extended"
        style={{ position: "fixed", bottom: "20px", right: "20px" }}
        color="primary"
        onClick={handleOpen}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        Add News Item
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ backgroundColor: "#000", padding: "20px" }}
      >
        <Container>
          <h2>Enter News Item</h2>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={handleTitleChange}
          />
          <TextField
            fullWidth
            label="Content"
            variant="outlined"
            multiline
            rows={4}
            value={content}
            onChange={handleContentChange}
          />
          <FormControl>
            <InputLabel htmlFor="featured-image">Featured Image</InputLabel>
            <Input
              id="featured-image"
              type="file"
              onChange={handleImageUpload}
            />
          </FormControl>
          <DateTimePicker
            label="Publish Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />

          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </Container>
      </Modal>
    </div>
  );
}
