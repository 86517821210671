import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Fab,
  Divider,
  Box,
  IconButton,
  Typography,
  ButtonGroup,
  FormControl,
  TextField,
  InputAdornment,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Avatar,
  Stack,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../App"; // Import the AppContext from App.js
import { listUsers } from "../graphql/queries";
import { deleteUser } from "../graphql/mutations";
import {
  listUserGroupsForUser,
  listCognitoUserGroups,
  addUserToCognitoUserGroup,
  removeUserFromCognitoUserGroup,
} from "../services/user-services";
import OtherUserForm from "./OtherUserForm"; // Make sure to update the import path
import { fetchUsers } from "../services/userService";
import { fetchUserByEmail } from "../services/userService"; // Add this import
import { useUser } from '../UserContext'; // Add this import

const UserSidebar = ({
  user,
  open,
  onClose,
  onEditUser,
  userGroups,
  onManageGroups,
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {user && !editing && (
        <Box
          sx={{
            width: 300,
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Avatar
            sx={{ width: 100, height: 100, marginBottom: 2 }}
            src={user?.profileImage || "/path/to/placeholder/avatar.png"} // Add your placeholder image path here
            alt={user?.username || "User Avatar"}
          />
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {user?.username || "User Name"}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Email: {user?.email || "N/A"}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Active Pricelist: {user?.activePricelist?.name || "N/A"}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Second Pricelist: {user?.secondPricelist?.name || "N/A"}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Groups:
          </Typography>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: 2,
            }}
            spacing={1}
          >
            {userGroups.map((group) => (
              <Button
                key={group.GroupName}
                variant={
                  user.groups.includes(group.GroupName)
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  user.groups.includes(group.GroupName)
                    ? onManageGroups(user, group.GroupName, "remove")
                    : onManageGroups(user, group.GroupName, "add")
                }
              >
                {group.GroupName}
              </Button>
            ))}
          </Stack>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => setEditing(true)}
          >
            Edit User
          </Button>
        </Box>
      )}
      {user && editing && (
        <OtherUserForm
          email={user.email}
          user={user}
          onComplete={() => setEditing(false)}
        />
      )}
    </Drawer>
  );
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { userRecord } = useContext(AppContext); // Get the user from the AppContext
  const { activeProvider } = useUser(); // Add this line

  // Define the custom GraphQL operation
  const listUsersCustom = `
  query ListUsers(
    $limit: Int,
    $filter: ModelUserFilterInput
  ) {
    listUsers(limit: $limit, filter: $filter) {
      items {
        id
        username
        email
        activePricelistID
        activePricelist {
          id
          name
        }
      }
    }
  }
`;

  useEffect(() => {
    const loadUsers = async () => {
      if (userRecord) {
        const fetchedUsers = await fetchUsers(userRecord);
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      }
    };

    loadUsers();
  }, [userRecord]);

  useEffect(() => {
    const filterUsers = () => {
      let filtered = users;

      if (search) {
        filtered = filtered.filter((user) =>
          Object.values(user).some((value) =>
            value?.toString().toLowerCase().includes(search.toLowerCase())
          )
        );
      }

      if (selectedGroup) {
        filtered = filtered.filter((user) =>
          user.groups.includes(selectedGroup)
        );
      }

      setFilteredUsers(filtered);
    };

    filterUsers();
  }, [search, selectedGroup, users]);

  const handleViewEdit = async (user) => {
    console.log("editing user", user);
    // Fetch the full user details by email
    const fullUserDetails = await fetchUserByEmail(user.email);
    setSelectedUser(fullUserDetails);
    setSidebarOpen(true);
  };

  const handleManageGroups = async (user, group, action) => {
    // if (action === "add") {
    //   await addUserToCognitoUserGroup(
    //     user.username,
    //     group,
    //     "us-east-1_xrenJGENT"
    //   );
    // } else if (action === "remove") {
    //   await removeUserFromCognitoUserGroup(
    //     user.username,
    //     group,
    //     "us-east-1_xrenJGENT"
    //   );
    // }
    // const updatedGroups = await listUserGroupsForUser(
    //   user.username,
    //   "us-east-1_xrenJGENT"
    // );
    // user.groups = updatedGroups.map((g) => g.GroupName);
    // setUsers([...users]);
    // setFilteredUsers([...filteredUsers]);
  };

  // Add this function
  const getRelativeLink = (path) => {
    if (activeProvider) {
      return `/${activeProvider.companyPath}${path}`;
    }
    return path;
  };

  return (
    <div style={{ margin: "10px", marginTop: "20px" }}>
      <Divider />
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#000",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          display: "flex",
          marginLeft: "10px",
        }}
      >
        <FormControl variant="outlined">
          <TextField
            id="filled-basic"
            label="Search"
            placeholder="Search Users"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "30px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      color: "black",
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: "50%",
                      padding: "11px",
                      left: "2px",
                    }}
                    edge="end"
                  >
                    <SearchIcon
                      sx={{
                        fontSize: "35px",
                        color: theme.palette.primary.secondary,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Divider />
      <Typography
        variant="h6"
        color="text.primary"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        Filter users with the buttons below.
      </Typography>
      <ButtonGroup
        color="primary"
        aria-label="user groups"
        sx={{ paddingTop: "20px", display: "flex", justifyContent: "center" }}
      >
        {userGroups.map((group) => (
          <Button
            key={group.GroupName}
            variant={
              selectedGroup === group.GroupName ? "contained" : "outlined"
            }
            onClick={() => setSelectedGroup(group.GroupName)}
          >
            {group.GroupName}
          </Button>
        ))}
      </ButtonGroup>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ color: theme.palette.secondary.main }}
          disabled={!selectedGroup}
          variant="text"
          onClick={() => setSelectedGroup(null)}
        >
          Remove Filters
        </Button>
      </Stack>
      <Divider sx={{ margin: "20px" }} />

      <Fab
        variant="extended"
        style={{ position: "fixed", bottom: "20px", right: "20px" }}
        color="primary"
        onClick={() => navigate(getRelativeLink('/email-invite'))}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        Invite a New User
      </Fab>
      {filteredUsers.length === 0 ? (
        <Typography
          variant="h6"
          color="text.primary"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          You do not currently have any customers of this type. Start by adding
          one.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Name</TableCell>

                <TableCell align="right">Active Pricelist</TableCell>
                <TableCell align="right">Searches Remaining</TableCell>
                <TableCell align="right">User Groups</TableCell>
                <TableCell align="right">Actions</TableCell>
                {/* <TableCell align="right">Delete</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow
                  key={user.email}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {user.username}
                  </TableCell>
                  <TableCell align="right">
                    {user.activePricelist?.name || "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {user.searchesRemaining || "100"}
                  </TableCell>
                  <TableCell align="right">
                    {user.groups?.map((group) => (
                      <Chip key={group} label={group} />
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleViewEdit(user)}>
                      View/Edit
                    </Button>
                  </TableCell>
                  {/* <TableCell align="right">
                    <Button onClick={() => removeUser(user)}>
                      Delete User
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <UserSidebar
        user={selectedUser}
        open={sidebarOpen}
        userGroups={userGroups}
        onClose={() => setSidebarOpen(false)}
        onEditUser={(user) => setSelectedUser(user)}
        onManageGroups={handleManageGroups}
      />
    </div>
  );
};

export default Users;
