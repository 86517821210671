import * as React from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayArrow";
import NewsItem from "./NewsItem";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const News = ({ showEdit }) => {
  const theme = useTheme();
  const drawerWidth = 240;

  const navigate = useNavigate();

  const newsItems = [
    {
      title: "Platinum Deficit Forecast 2023",
      category: "South Africa Mining News",
      subtitle: "Heating Up",
      content:
        "The core drivers of platinum’s expected 27% demand growth in 2023 – including strong growth in automotive and industrial demand – were clear to see in Q2, and built upon foundations laid in the previous two quarters. These favourable conditions are expected to continue in 2023. Looking beyond today’s Platinum Quarterly, our research shows that automotive and industrial demand growth underpin total demand growth in 2024 and beyond. This offers both short and long-term value incentives for investors, as well as protection from downside risks presented by inflationary headwinds and high interest rates. Meanwhile, continuing electricity shortages in major producer South Africa have exacerbated the deficit and maintained downside risk to mined supply. In Q2’23, this combination of demand growth and constrained supply was met by strong ETF inflows, all of which have contributed to a widening gap between the supply of, and demand for, platinum.",
    },
    {
      title: "Palladium Shift Into Surplus",
      category: "Copper Industry Updates",
      subtitle: "Movers and Shakers",
      content:
        "In August 2022, Russia-based palladium giant Nornickel said that supply chain disruptions caused by the Western sanctions have reduced its sales for the first half of 2022.  In its second-half report, the company pointed out that supply was expected to grow in 2022, however, “the downside risk remains due to the infrastructure flooding at the Stillwater mine and a potential strike at the Sibanye-Stillwater assets in South Africa”. Johannesburg-based precious metal producer Sibanye-Stillwater’s mine operations in Montana were hurt by a significant flooding event in June 2022. It forced the firm to cut its output forecast to 445,000-460,000 ounces in 2022, according to the latest half-year report.",
    },
    {
      title: "Impala Surprised By Sudden Price Collapse",
      category: "Palladium Industry Updates",
      subtitle: "Movers and Shakers",
      content:
        "Impala Platinum (IMPJ.J) Chief Executive Nico Muller said a rapid decline in palladium and rhodium prices that has squeezed profits, lowered dividend payouts and shifted the focus to cutting costs caught platinum miners off guard. Muller said there was no immediate risk of mine closures, but management would weigh each mine's potential to generate profit, given the price fall. The Johannesburg-based miner on Thursday said headline earnings in the year to June 30 declined 41% to 18.8 billion rand and cut its full-year dividend by 65% to 5.85 rand per share. Impala and its South African peers, including Sibanye Stillwater (SSWJ.J) and Anglo American Platinum (AMSJ.J), had been making record returns when rhodium hit almost $30,000 an ounce in 2021 and palladium surged to more than $3,400 an ounce after Russia's invasion of Ukraine last year. Rhodium , which was trading around $4,100 per ounce on Thursday, has dropped more than two thirds in 2023 so far, while palladium , at around $1,230 an ounce, is down 31% this year.",
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", fontFamily: "Poppins", fontWeight: 800 }}
      >
        Market News
      </Typography>
      <Grid container sx={{ flexWrap: "wrap" }}>
        {newsItems.map((u, i) => {
          return (
            <Grid item xs={12} sm={4} key={i}>
              <NewsItem
                content={u.content}
                title={u.title}
                category={u.category}
                subtitle={u.subtitle}
              />
            </Grid>
          );
        })}
      </Grid>
      <Stack spacing={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "40px",
            width: "100%",
            color: "black",
            fontFamily: "Poppins",
            fontWeight: 700,
            borderColor: "black",
          }}
        >
          View Forge Metal Market News
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/podcast")}
          sx={{
            borderRadius: "40px",
            width: "100%",
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 700,
            backgroundColor: "black",
            fontSize: "20px",
          }}
          startIcon={
            <PlayIcon
              fontSize="40px"
              sx={{ color: theme.palette.primary.main, fontSize: "40px" }}
            />
          }
        >
          Listen to MetalCast
        </Button>
      </Stack>
    </Box>
  );
};

export default News;
