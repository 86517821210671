import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import InnerPage from "../components/InnerPage";

export default function Contact({ user }) {
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ticketType, setTicketType] = useState("catalyst");
  const [supportCategory, setSupportCategory] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <InnerPage title="Contact Support">
      <Paper elevation={3} sx={{ padding: "30px", maxWidth: "600px", margin: "0 auto", marginTop: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Open a Support Ticket
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Select Support Type</FormLabel>
            <RadioGroup
              row
              aria-label="ticket-type"
              name="ticket-type"
              value={ticketType}
              onChange={(e) => setTicketType(e.target.value)}
            >
              <FormControlLabel value="catalyst" control={<Radio />} label="Catalyst and Core Team" />
              <FormControlLabel value="pricelist" control={<Radio />} label="Pricelist Provider" />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="support-category-label">Support Category</InputLabel>
            <Select
              labelId="support-category-label"
              id="support-category"
              value={supportCategory}
              label="Support Category"
              onChange={(e) => setSupportCategory(e.target.value)}
            >
              <MenuItem value="billing">Billing Issues</MenuItem>
              <MenuItem value="technical">Technical Issues</MenuItem>
              <MenuItem value="account">Account Issues</MenuItem>
              <MenuItem value="feature">Feature Request</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            multiline
            rows={6}
            margin="normal"
            variant="outlined"
          />
          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Submit Ticket
            </Button>
          </Box>
        </form>
      </Paper>
    </InnerPage>
  );
}
