/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      username
      tier
      email
      searches {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      newsitems {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      addedParts {
        nextToken
        __typename
      }
      address
      city
      state
      zip
      contactName
      companyName
      phoneNumber
      cellNumber
      idImage
      businessLicense
      priceListInvites
      acceptedInvites
      ownedInvoices {
        nextToken
        __typename
      }
      ownedUserGroups {
        nextToken
        __typename
      }
      belongsToUserGroups {
        nextToken
        __typename
      }
      CSVFiles {
        nextToken
        __typename
      }
      Pricelists {
        nextToken
        __typename
      }
      createdByUserId
      activeInvoiceID
      activeInvoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      activePricelistID
      activePricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      secondPricelistID
      secondPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      companyLogo
      companyPath
      primaryColor
      secondaryColor
      supportTickets {
        nextToken
        __typename
      }
      searchesRemaining
      createdAt
      updatedAt
      themeOptions
      subscriptionActive
      lastPaidDate
      subscriptionTier
      subscriptionTerm
      isProvider
      pricelistSubscribersId
      userGroupUsersId
      userInventoryId
      __typename
    }
  }
`;
export const getPricelist = /* GraphQL */ `
  query GetPricelist($id: ID!) {
    getPricelist(id: $id) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      parentPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      derivedPricelists {
        nextToken
        __typename
      }
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      hedges {
        nextToken
        __typename
      }
      status
      searches {
        nextToken
        __typename
      }
      provider {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      subscribers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      pricelistDerivedPricelistsId
      userPricelistsId
      __typename
    }
  }
`;
export const listPricelists = /* GraphQL */ `
  query ListPricelists(
    $filter: ModelPricelistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricelists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSecondPricelist = /* GraphQL */ `
  query GetSecondPricelist($id: ID!) {
    getSecondPricelist(id: $id) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSecondPricelists = /* GraphQL */ `
  query ListSecondPricelists(
    $filter: ModelSecondPricelistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecondPricelists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCSVFile = /* GraphQL */ `
  query GetCSVFile($id: ID!) {
    getCSVFile(id: $id) {
      id
      uploadedAt
      username
      validRows
      dataCount
      s3Key
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userCSVFilesId
      __typename
    }
  }
`;
export const listCSVFiles = /* GraphQL */ `
  query ListCSVFiles(
    $filter: ModelCSVFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCSVFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uploadedAt
        username
        validRows
        dataCount
        s3Key
        createdAt
        updatedAt
        userCSVFilesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearch = /* GraphQL */ `
  query GetSearch($id: ID!) {
    getSearch(id: $id) {
      id
      ipAddress
      location
      latitude
      longitude
      price
      partNumber
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearched {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearchedID
      pricelistID
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistSearchesId
      userSearchesId
      __typename
    }
  }
`;
export const listSearches = /* GraphQL */ `
  query ListSearches(
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipAddress
        location
        latitude
        longitude
        price
        partNumber
        userID
        userSearchedID
        pricelistID
        createdAt
        updatedAt
        pricelistSearchesId
        userSearchesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      tier
      email
      searches {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      newsitems {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      addedParts {
        nextToken
        __typename
      }
      address
      city
      state
      zip
      contactName
      companyName
      phoneNumber
      cellNumber
      idImage
      businessLicense
      priceListInvites
      acceptedInvites
      ownedInvoices {
        nextToken
        __typename
      }
      ownedUserGroups {
        nextToken
        __typename
      }
      belongsToUserGroups {
        nextToken
        __typename
      }
      CSVFiles {
        nextToken
        __typename
      }
      Pricelists {
        nextToken
        __typename
      }
      createdByUserId
      activeInvoiceID
      activeInvoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      activePricelistID
      activePricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      secondPricelistID
      secondPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      companyLogo
      companyPath
      primaryColor
      secondaryColor
      supportTickets {
        nextToken
        __typename
      }
      searchesRemaining
      createdAt
      updatedAt
      themeOptions
      subscriptionActive
      lastPaidDate
      subscriptionTier
      subscriptionTerm
      isProvider
      pricelistSubscribersId
      userGroupUsersId
      userInventoryId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($id: ID!) {
    getUserGroup(id: $id) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userOwnedUserGroupsId
      userBelongsToUserGroupsId
      __typename
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        userID
        createdAt
        updatedAt
        userOwnedUserGroupsId
        userBelongsToUserGroupsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userAlertsId
      __typename
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        userID
        createdAt
        updatedAt
        userAlertsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsItem = /* GraphQL */ `
  query GetNewsItem($id: ID!) {
    getNewsItem(id: $id) {
      id
      title
      content
      img
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userNewsitemsId
      __typename
    }
  }
`;
export const listNewsItems = /* GraphQL */ `
  query ListNewsItems(
    $filter: ModelNewsItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        img
        createdAt
        updatedAt
        userNewsitemsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      boxes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      inventoryUserId
      __typename
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPart = /* GraphQL */ `
  query GetPart($id: ID!) {
    getPart(id: $id) {
      id
      partNumber
      secondPartNumber
      price
      addedByUser
      fromPricelist
      status
      cost
      grade
      createdAt
      updatedAt
      pricelistPartsId
      secondPricelistPartsId
      userAddedPartsId
      boxPartsId
      invoicePartsId
      __typename
    }
  }
`;
export const listParts = /* GraphQL */ `
  query ListParts(
    $filter: ModelPartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partNumber
        secondPartNumber
        price
        addedByUser
        fromPricelist
        status
        cost
        grade
        createdAt
        updatedAt
        pricelistPartsId
        secondPricelistPartsId
        userAddedPartsId
        boxPartsId
        invoicePartsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBox = /* GraphQL */ `
  query GetBox($id: ID!) {
    getBox(id: $id) {
      id
      name
      parts {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      invoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      fromPricelist
      status
      createdAt
      updatedAt
      inventoryBoxesId
      invoiceBoxesId
      __typename
    }
  }
`;
export const listBoxes = /* GraphQL */ `
  query ListBoxes(
    $filter: ModelBoxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        fromPricelist
        status
        createdAt
        updatedAt
        inventoryBoxesId
        invoiceBoxesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      boxes {
        nextToken
        __typename
      }
      dateSent
      owner {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      ownerID
      recipientID
      recipient {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      invoiceNumber
      status
      name
      parts {
        nextToken
        __typename
      }
      partCount
      createdAt
      updatedAt
      userOwnedInvoicesId
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHedge = /* GraphQL */ `
  query GetHedge($id: ID!) {
    getHedge(id: $id) {
      id
      numberOfPieces
      status
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistHedgesId
      __typename
    }
  }
`;
export const listHedges = /* GraphQL */ `
  query ListHedges(
    $filter: ModelHedgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHedges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        numberOfPieces
        status
        createdAt
        updatedAt
        pricelistHedgesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMappedVIN = /* GraphQL */ `
  query GetMappedVIN($id: ID!) {
    getMappedVIN(id: $id) {
      id
      s3Key
      uploadDate
      recordCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMappedVINS = /* GraphQL */ `
  query ListMappedVINS(
    $filter: ModelMappedVINFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMappedVINS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        s3Key
        uploadDate
        recordCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupportTicket = /* GraphQL */ `
  query GetSupportTicket($id: ID!) {
    getSupportTicket(id: $id) {
      id
      subject
      message
      ticketType
      supportCategory
      status
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userSupportTicketsId
      __typename
    }
  }
`;
export const listSupportTickets = /* GraphQL */ `
  query ListSupportTickets(
    $filter: ModelSupportTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        message
        ticketType
        supportCategory
        status
        userID
        createdAt
        updatedAt
        userSupportTicketsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
