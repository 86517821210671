import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { AppBar, Stack, Box, IconButton, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CarIcon from "@mui/icons-material/DirectionsCar";
import CameraIcon from "@mui/icons-material/CameraAlt";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PrintIcon from "@mui/icons-material/Print";

export default function InvoiceOptionBar({ handleDrawerToggle, navigation }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        borderRadius: "10px",
        // backgroundColor: theme.palette.primary.main,
        // padding: "10px",
        // color: "white",
        fontWeight: "bold",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "10px",
      }}
      spacing={2}
      direction={"row"}
    >
      <Link
        to="/invoices"
        style={{
          textAlign: "center",
          fontFamily: "Poppins",
          textDecoration: "none", // Remove underlines
          // color: "white",
        }}
      >
        <Stack
          direction={"column"}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            sx={{ backgroundColor: "#F2EEFE" }}
            onClick={handleDrawerToggle}
          >
            <PrintIcon
              sx={{ fontSize: "50px", color: theme.palette.primary.main }}
            />
          </IconButton>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Poppins",
              color: theme.palette.primary.main,
            }}
          >
            Print Invoice
          </Typography>
        </Stack>
      </Link>
      <Link
        to="/invoices"
        style={{
          textAlign: "center",
          fontFamily: "Poppins",
          textDecoration: "none", // Remove underlines
          // color: "white",
        }}
      >
        <Stack
          direction={"column"}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            sx={{ backgroundColor: "#F2EEFE" }}
            onClick={handleDrawerToggle}
          >
            <AddCircleIcon
              sx={{ fontSize: "50px", color: theme.palette.primary.main }}
              fontSize="large"
            />
          </IconButton>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Poppins",
              color: theme.palette.primary.main,
            }}
          >
            Add Unit Manually
          </Typography>
        </Stack>
      </Link>
      <Link
        to="/invoices"
        style={{
          textAlign: "center",
          fontFamily: "Poppins",
          textDecoration: "none", // Remove underlines
          // color: "white",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            sx={{ backgroundColor: "#F2EEFE" }}
            onClick={handleDrawerToggle}
          >
            <SaveIcon
              sx={{ fontSize: "50px", color: theme.palette.primary.main }}
              fontSize="large"
            />
          </IconButton>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Poppins",
              textDecorationStyle: "none !important",
              color: theme.palette.primary.main,
            }}
          >
            Save
          </Typography>
        </Stack>
      </Link>
    </Stack>
  );
}
