import React from 'react';
import { Dialog, DialogContent, Typography, CircularProgress, Box } from '@mui/material';

const facts = [
  "Catalytic converters contain precious metals like platinum, palladium, and rhodium.",
  "The recycling of catalytic converters helps reduce the need for mining new precious metals.",
  "A single catalytic converter can contain up to 7 grams of platinum.",
  "The global catalytic converter recycling market is expected to reach $20 billion by 2026.",
  "Recycling one catalytic converter can save up to 40 pounds of ore from being mined.",
  "The first catalytic converters were introduced in the 1970s to reduce harmful emissions.",
  "Palladium, found in catalytic converters, is more valuable than gold by weight.",
  "The recycling process for catalytic converters is 100% environmentally friendly.",
  "Some countries have laws mandating the recycling of catalytic converters.",
  "The precious metals in catalytic converters can be recycled indefinitely without losing their properties."
];

const SearchProgressModal = ({ open }) => {
  const randomFact = facts[Math.floor(Math.random() * facts.length)];

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="200px">
          <CircularProgress size={60} thickness={4} sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom align="center">
            Searching...
          </Typography>
          <Typography variant="body1" align="center" sx={{ mt: 2, fontStyle: 'italic' }}>
            Did you know?
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            {randomFact}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SearchProgressModal;