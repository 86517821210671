import React, { useState, useEffect } from "react";
import axios from "axios";
import { scaleTime, scaleLinear } from "@visx/scale";
import { Typography, Stack, Divider, Button } from "@mui/material";
import MetalSymbol from "./MetalSymbol";
import SubscribeIcon from "@mui/icons-material/Email";
import { grabMetalPrices } from "../services/metals-services";
import ReactApexChart from "react-apexcharts";

const MetalPriceChart = ({ metalSymbols, showChart = true }) => {
  const [metalsData, setMetalsData] = useState([]);
  const [fetchDateTime, setFetchDateTime] = useState(new Date());
  const [selectedMetals, setSelectedMetals] = useState(new Set(metalSymbols));
  const [series, setSeries] = useState([]);
  const [selectedMetal, setSelectedMetal] = useState("PT");

  console.log("metalSymbols", metalSymbols);

  const toggleMetal = (symbol) => {
    setSelectedMetals((prevSelected) => {
      const newSelected = new Set();
      if (newSelected.has(symbol)) {
        newSelected.delete(symbol);
      } else {
        newSelected.add(symbol);
      }
      return newSelected;
    });
  };

  const metalNames = {
    PD: "palladium",
    PT: "platinum",
    RH: "rhodium",
  };

  useEffect(() => {
    const fetchSeriesData = async () => {
      try {
        const metalData = await grabMetalPrices();

        console.log("metalData", metalData);

        const metalWord = metalNames[selectedMetal];
        const newX = [];
        const newY = [];

        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        for (const t of metalData) {
          if (t[metalWord]) {
            const date = new Date(t[metalWord].date);
            if (date >= sevenDaysAgo) {
              newX.push(date.getTime());
              newY.push(t[metalWord].price);
            }
          }
        }

        const newData = {
          name: selectedMetal,
          data: newY.map((y, i) => ({ x: newX[i], y })),
        };
        console.log("newData", newData);
        setSeries([newData]);

        const allMetalsData = [];

        for (const m of ["PD", "PT", "RH"]) {
          const metalWord = metalNames[m];
          const newX = [];
          const newY = [];

          for (const t of metalData) {
            if (t[metalWord]) {
              const date = new Date(t[metalWord].date);
              if (date >= sevenDaysAgo) {
                newX.push(date.getTime());
                newY.push(t[metalWord].price);
              }
            }
          }

          const newData = {
            name: m,
            data: newY.map((y, i) => ({ x: newX[i], y })),
          };

          allMetalsData.push(newData);
        }
        setMetalsData(allMetalsData);
      } catch (error) {
        console.error("Error fetching series data:", error);
        // Handle the error here, e.g. show an error message to the user
      }
    };

    fetchSeriesData();
  }, [selectedMetal]);
  // }, [metalSymbols]);

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <Stack
      sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      spacing={1}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: "space-between", alignItems: "space-between" }}
      >
        {metalsData.map((metal, i) => (
          <div
            onClick={() => setSelectedMetal(metal.name)}
            style={{ cursor: "pointer" }}
            key={i}
          >
            <MetalSymbol metal={metal} toggled={selectedMetal === metal.name} />
          </div>
        ))}
      </Stack>
      {showChart === true && (
        <>
          <Typography
            variant="h6"
            sx={{ fontFamily: "Poppins", fontWeight: 500 }}
          >
            PGM Market Overview
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 400,
              color: "#5A5C5E",
            }}
          >
            Current as of: {fetchDateTime.toLocaleString()}{" "}
          </Typography>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            // height={250}
            width={600}
          />

          <Divider variant="middle" style={{ marginTop: "10px" }} />
         
        </>
      )}
    </Stack>
  );
};

export default MetalPriceChart;
