import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Container,
  ListItemSecondaryAction,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { API, graphqlOperation } from "aws-amplify";
import { listUserGroups } from "../graphql/queries"; // Assuming you have these queries/mutations

import { createUserGroup, deleteUserGroup } from "../graphql/mutations"; // Assuming you have these queries/mutations

const UserGroupManager = () => {
  const [userGroups, setUserGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const result = await API.graphql(graphqlOperation(listUserGroups));
        setUserGroups(result.data.listUserGroups.items);
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    };

    fetchUserGroups();
  }, []);

  const handleAddGroup = async () => {
    if (newGroupName.trim() === "") {
      setSnackbarMessage("Group name cannot be empty.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const input = { title: newGroupName, content: "" };
      const result = await API.graphql(
        graphqlOperation(createUserGroup, { input })
      );
      setUserGroups([...userGroups, result.data.createUserGroup]);
      setNewGroupName("");
      setSnackbarMessage(`Group "${newGroupName}" added successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding group:", error);
      setSnackbarMessage("Failed to add group.");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      const input = { id: deleteGroupId };
      await API.graphql(graphqlOperation(deleteUserGroup, { input }));
      setUserGroups(userGroups.filter((group) => group.id !== deleteGroupId));
      setSnackbarMessage(`Group deleted successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting group:", error);
      setSnackbarMessage("Failed to delete group.");
      setSnackbarOpen(true);
    } finally {
      setDialogOpen(false);
    }
  };

  const openDeleteDialog = (groupId) => {
    setDeleteGroupId(groupId);
    setDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDialogOpen(false);
    setDeleteGroupId(null);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4, textAlign: "center", pt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Manage User Groups
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          User groups allow administrators to efficiently manage and distribute pricelists to multiple users simultaneously, 
          streamlining the process of sharing information across your organization.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Create new groups, delete existing ones, and organize your users for easier pricelist distribution.
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
          <TextField
            label="New Group Name"
            variant="outlined"
            fullWidth
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddGroup}
          >
            Add Group
          </Button>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <List>
          {userGroups.map((group) => (
            <ListItem
              key={group.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => openDeleteDialog(group.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <GroupIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={group.title}
                secondary={`Group ID: ${group.id}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Dialog open={dialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete User Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user group? This action cannot
            be undone and may affect pricelist distribution for associated users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnackbar} severity="info" sx={{ width: "100%" }} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserGroupManager;
