import * as React from "react";
import ViewPricelists from "../components/pricing/CSVUpload";
import InnerPage from "../components/InnerPage";

export default function ViewPricelistsScreen({ user }) {
  return (
    <InnerPage title="View Pricelists">
      <ViewPricelists user={user} />
    </InnerPage>
  );
}
