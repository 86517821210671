import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import Autocomplete from "@mui/material/Autocomplete";
import PartRow from "../PartRow";
import PartCard from "../PartCard";
import { getPricelist } from "../../graphql/queries";
import { Auth } from "aws-amplify";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputAdornment,
  IconButton,
  Box,
  Card,
  Stack,
  Button,
  Typography,
  Alert,
  Modal,
  Snackbar,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import Boxes from "../invoices/Boxes";
import Invoices from "../invoices/InvoicesSelect";
import PartsChart from "../PartsChart";

import rawPricelist from "../../dev-pricelist.json";
import { getHistoricalPrices } from "./services";
import { useTheme } from "@mui/material/styles";
import { fetchInvoices, hasOpenInvoices } from "../invoices/services";
import { AppContext } from "../../App"; // Import the AppContext from App.js
import PricelistSelect from "../pricing/PricelistSelect";
import CloseIcon from "@mui/icons-material/Close";
import Disclaimer from "../Disclaimer";
import {
  updateInvoice,
  createPart,
  deleteInvoice,
  createInvoice,
  createSearch,
} from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import PartDetailsModal from '../parts/PartDetailsModal';

const SearchPricelist = ({ defaultSearch, onPartSelect, showPricelistSelect = true, showDisclaimers = true }) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearch || "");
  const [csvData, setCsvData] = useState(null);
  const [activePricelist, setActivePricelist] = useState(null);
  const [filteredCsvData, setFilteredCsvData] = useState([]);
  const [lastCSV, setLastCSV] = useState({});
  const [searchedPart, setSearchedPart] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [priceHistory, setPriceHistory] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [doesHaveOpenInvoices, setDoesHaveOpenInvoices] = useState(false);
  const { userRecord, refreshUser } = useContext(AppContext); // Get the user from the AppContext
  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState(false);
  const handleClose = () => setShowFeedback(false);

  const theme = useTheme();

  const checkInvoices = async () => {
    try {
      const invoices = await fetchInvoices();
      setInvoices(invoices);
      const hasOpen = hasOpenInvoices(invoices);

      setDoesHaveOpenInvoices(hasOpen);
    } catch (error) {
      console.log("Error checking invoices", error);
    }
  };

  useEffect(() => {
    checkInvoices();
  }, []);

  const handleCreateInvoice = async (name) => {
    try {
      const input = {
        name: name,
        status: "open",
        ownerID: userRecord.id,
        recipientID: userRecord.activePricelist.userId,
      };
      const response = await API.graphql(
        graphqlOperation(createInvoice, { input })
      );

      const newInvoice = response.data.createInvoice;
      setInvoices((prevInvoices) => [...prevInvoices, newInvoice]);
      fetchInvoices();
    } catch (error) {
      console.log("Error creating invoice:", error);
    }
  };

  const getUserLocation = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      const ipAddress = data.ip;

      const locationResponse = await fetch(
        `https://ipapi.co/${ipAddress}/json/`
      );
      const locationData = await locationResponse.json();
      const latitude = locationData.latitude;
      const longitude = locationData.longitude;

      return {
        latitude,
        longitude,
        ipAddress,
      };
    } catch (error) {
      console.log("Error getting user location:", error);
    }
  };

  const handleSearch = async (item) => {
    const results = csvData.filter(
      (row) =>
        row["PART NUMBER"].toString().replace(/\s+/g, "").toLowerCase() ==
        item.toString().replace(/\s+/g, "").toLowerCase()
    );

    const result = results[0];

    // const histPrices = await getHistoricalPrices(item, 398, 400);
    // histPrices.push(parseFloat(result.Price));
    // setPriceHistory(histPrices);
    setSearchTerm("");
    setFilteredCsvData([]);

    const userDeets = await getUserLocation();

    const search = {
      ipAddress: userDeets.ipAddress,
      latitude: userDeets.latitude,
      longitude: userDeets.longitude,
      price: parseFloat(result["adjustedPrice"]),
      partNumber: result["PART NUMBER"],
      userSearchedID: userRecord.id,
      pricelistID: userRecord.activePricelistID, // Add this line
    };

    try {
      const searchResponse = await API.graphql(
        graphqlOperation(createSearch, { input: search })
      );
    } catch (error) {
      console.error("Error creating search:", error);
    }

    const part = {
      partNumber: result["PART NUMBER"],
      minPrice: result["adjustedPrice"],
      maxPrice: result["adjustedPrice"],
      image1: result.IMAGE1,
      image2: result.IMAGE2,
      image3: result.IMAGE3,
    };

    setSearchedPart(part);
  };

  const addPartToInvoice = async (part, grade) => {
    if (onPartSelect) {
      onPartSelect(part, grade);
      setSearchedPart(null);
      return;
    }

    const partToAdd = {
      partNumber: part.partNumber,
      price: parseFloat(part.minPrice),
      fromPricelist: userRecord.activePricelistID,
      grade: parseFloat(grade),
      invoicePartsId: userRecord.activeInvoiceID,
    };

    console.log("partToAdd", partToAdd);

    try {
      // Run the addPart mutation
      const partResponse = await API.graphql(
        graphqlOperation(createPart, { input: partToAdd })
      );

      const invoiceResponse = await API.graphql(
        graphqlOperation(updateInvoice, {
          input: {
            id: userRecord.activeInvoiceID,
            partCount: userRecord.activeInvoice.partCount + 1,
          },
        })
      );
      setShowFeedback(true);
      setSearchedPart(null);
      refreshUser();
    } catch (error) {
      console.error("Error adding part to invoice:", error);
    }
  };

  const fetchPricesByOEMs = (oem) => {
    const oemToSearch = oem.replace("-0", "");

    const results = rawPricelist.filter((row) =>
      row.PartNumber.toString()
        .toLowerCase()
        .includes(oemToSearch.toString().toLowerCase())
    );

    return results;
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        const groups = usr.signInUserSession.idToken.payload["cognito:groups"];

        setUser(usr);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchLatestCSV = async () => {
      console.log("fetching latest");
      try {
        const response = await API.graphql(
          graphqlOperation(getPricelist, { id: userRecord.activePricelistID })
        );

        const latestFile = response.data.getPricelist;
        setLastCSV(response.data.getPricelist);

        // Check if the uploadedAt date is the same in local storage
        const cachedDate = localStorage.getItem("uploadedAt");
        let jsonData;
        if (
          cachedDate &&
          new Date(cachedDate).getTime() ===
            new Date(latestFile.createdAt).getTime()
        ) {
          // Use cached data
          // console.log("Using cached data");
          jsonData = JSON.parse(localStorage.getItem("csvData"));
        } else {
          setLoading(true);
          // Fetch the file from S3
          console.log("latestFile.s3Key", latestFile.s3Key);
          const fileUrl = await Storage.get(latestFile.s3Key, {
            download: true,
          });
          // console.log("fileUrl", fileUrl);
          if (fileUrl.Body) {
            // Parse the data as JSON
            const blob = new Blob([fileUrl.Body], { type: "application/json" });
            const jsonText = await blob.text();
            jsonData = JSON.parse(jsonText);
            // console.log("jsonData", jsonData);

            // Cache
            // Cache the data in local storage
            localStorage.setItem("csvData", JSON.stringify(jsonData));
            localStorage.setItem("uploadedAt", latestFile.createdAt);
          }
          setLoading(false);
        }

        setCsvData(jsonData);
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      }
    };

    if (userRecord && userRecord.activePricelistID) {
      fetchLatestCSV();
    }
  }, [userRecord]);

  useEffect(() => {
    function filterOem() {
      if (searchTerm.length > 2) {
        let results = fetchPricesByOEMs(searchTerm);
        results = results.slice(0, 20);

        setFilteredCsvData(results);
      }
    }

    filterOem();
  }, [searchTerm]);

  const handlePartSelect = (part, grade) => {
    if (onPartSelect) {
      // If onPartSelect is provided, we're in "add to invoice" mode
      onPartSelect(part, grade);
      setSearchedPart(null);
    } else {
      // Otherwise, just show the part details
      setSearchedPart(part);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <>
        {showPricelistSelect && <PricelistSelect />}
        {csvData && (
          <>
            {showPricelistSelect && (
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  marginTop: "15px",
                  marginBottom: "15px",
                  color: "#aaa",
                }}
              >
                {lastCSV
                  ? `You are searching ${lastCSV.name}.`
                  : "No active pricelist selected."}{" "}
                <span style={{ color: "#aaa", fontSize: "10px" }}>
                  {lastCSV
                    ? `Uploaded on ${new Date(
                        lastCSV.createdAt
                      ).toLocaleDateString()}`
                    : ""}
                </span>
              </Typography>
            )}
            
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#000",
                borderRadius: "20px",
                marginTop: "20px",
              }}
            >
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <TextField
                  id="filled-basic"
                  label="Search"
                  placeholder="e.g. EC4R 3TE"
                  value={searchTerm}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "30px",
                    marginBottom: "10px",
                    marginTop: "20px",
                    marginLeft: "40px",
                    marginRight: "40px",
                  }}
                  onChange={(evt) => setSearchTerm(evt.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: theme.palette.secondary.main,
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: "50%",
                            padding: "11px",
                            left: "2px",
                          }}
                          edge="end"
                        >
                          <SearchIcon color="black" sx={{ fontSize: "35px" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>

            {showDisclaimers && filteredCsvData.length === 0 && !searchedPart && (
              <Alert variant="p" sx={{ textAlign: "center" }}>
                Pricing provided by your Buyer are not guaranteed and are
                subject to change. If you would like to lock in any units at
                these prices, please contact your buyer.
              </Alert>
            )}

            {showDisclaimers && <Disclaimer />}
            
            {filteredCsvData.map((u, i) => {
              const part = {
                partNumber: u.PartNumber,
                price: u.Price,
                image1: u.Image1,
                image2: u.Image2,
                image3: u.Image3,
              };
              return (
                <PartRow
                  part={part}
                  key={i}
                  onClick={() => handleSearch(u.PartNumber)}
                />
              );
            })}
          </>
        )}

        <Snackbar
          open={showFeedback}
          autoHideDuration={3000}
          onClose={handleClose}
          message={`Part successfully added to invoice.`}
        />
      </>

      <PartDetailsModal
        part={searchedPart}
        open={searchedPart !== null}
        onClose={() => setSearchedPart(null)}
        onAddPart={addPartToInvoice}
        showAddButtons={onPartSelect} // Only show add buttons in normal search mode
      />
    </div>
  );
};

export default SearchPricelist;
