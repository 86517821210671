import * as React from "react";
import { Alert } from "@mui/material";

const AdminView = ({ user }) => {
  return (
    <Alert variant="p" sx={{ textAlign: "center", fontSize: "12px" }}>
      <strong>Disclaimer:</strong> This software provides estimated prices for
      catalytic converters based on the vehicle's VIN number and/or identifying
      unit number. These estimates are not guarantees and may not reflect the
      actual recovery value. Please note that the information provided may be
      inaccurate or outdated, as the software is in its{" "}
      <strong>Beta Testing</strong> phase. As Beta software, this application is
      still under development, and new features will be added as it evolves. We
      encourage users to report any errors, bugs, or inaccuracies they encounter
      to help us improve the software. By using this application, you
      acknowledge that the estimates are for informational purposes only and
      that we are not liable for any discrepancies, losses, or damages resulting
      from its use. Users are responsible for any decisions made based on the
      information provided.
      <br />
      <strong>Version: 0.1.1-beta</strong>
    </Alert>
  );
};

export default AdminView;
