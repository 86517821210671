import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { listPricelists, listUsers, listUserGroups } from "../../graphql/queries";
import {
  Box,
  Button,
  Card,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Divider,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppContext } from "../../App";
import UserCard from "../user/UserCard";
import PricelistSidebar from "./PricelistSidebar";
import { fetchUsers } from "../../services/userService";

const AssignPricelist = () => {
  const [activeUser, setActiveUser] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [activePricelist, setActivePricelist] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState("");
  const { userRecord } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    fetchUsersAndGroups();
    fetchUploadedFiles();
  }, [userRecord]);

  const listUsersCustom = `
  query ListUsers(
    $limit: Int,
    $filter: ModelUserFilterInput
  ) {
    listUsers(limit: $limit, filter: $filter) {
      items {
        id
        username
        email
        activePricelistID
        activePricelist {
          id
          name
        }
      }
    }
  }
`;

  const fetchUsersAndGroups = async () => {
    if (userRecord) {
      try {
        const [fetchedUsers, groupsResponse] = await Promise.all([
          fetchUsers(userRecord),
          API.graphql(graphqlOperation(listUserGroups))
        ]);

        setUsers(fetchedUsers);
        
        const filteredGroups = groupsResponse.data.listUserGroups.items || [];
        setUserGroups(filteredGroups);
      } catch (error) {
        console.error("Error fetching users and groups:", error);
      }
    }
  };

  const fetchUploadedFiles = async () => {
    if (userRecord && userRecord.id) {
      try {
        const response = await API.graphql(
          graphqlOperation(listPricelists, {
            filter: { userId: { eq: userRecord.id } },
            limit: 100,
            sortDirection: "DESC",
            sortField: "createdAt",
          })
        );

        const sortedFiles = response.data.listPricelists.items.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );

        setUploadedFiles(sortedFiles);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    }
  };

  const assignPricelistToUser = async (pricelist, user) => {
    console.log("assigning pricelist to user", pricelist, user);
    const userToUpdate = {
      email: user.email,
      id: user.id,
      activePricelistID: pricelist.id,
    };

    popFeedback("Pricelist assigned to user successfully!");
    await API.graphql(graphqlOperation(updateUser, { input: userToUpdate }));
    return true;
  };

  const assignPricelist = async (pricelist) => {
    console.log("assigning pricelist", pricelist);
    const assignToUser = async (user) => {
      const userToUpdate = {
        email: user.email,
        id: user.id,
        activePricelistID: pricelist.id,
      };

      await API.graphql(graphqlOperation(updateUser, { input: userToUpdate }));
    };

    if (activeUser) {
      console.log("assigning pricelist to user", pricelist, activeUser);
      await assignToUser(activeUser);
      popFeedback("Pricelist assigned to user successfully!");
    } else if (activeGroup) {
      console.log("assigning pricelistSS to group", pricelist, activeGroup);

      // Fetch all users and filter by group membership
      const response = await API.graphql(
        graphqlOperation(listUsers, { limit: 1000 })
      );

      const allUsers = response.data.listUsers.items;

      // Filter users based on group membership
      const groupMembers = allUsers.filter(
        (user) => user.groups && user.groups.includes(activeGroup.GroupName)
      );

      console.log("groupMembers", groupMembers);

      // Assign the pricelist to all group members
      await Promise.all(groupMembers.map(assignToUser));

      popFeedback("Pricelist assigned to group successfully!");
    }
  };

  const handleAssignPricelist = () => {
    if (activePricelist) {
      assignPricelist(activePricelist);
    }
  };

  const popFeedback = (message) => {
    setUserFeedback(message);
    setShowFeedback(true);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    if (activeStep === 1) {
      setActiveUser(null);
      setActiveGroup(null);
    } else if (activeStep === 2) {
      setActivePricelist(null);
    }
  };

  const steps = ['Select User or Group', 'Choose Pricelist', 'Confirm Assignment', 'Complete'];

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
      <Typography variant="h4" gutterBottom align="center">
        Assign Pricelist
      </Typography>
      
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep < 3 && (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ mb: 2 }}
        >
          Back
        </Button>
      )}

      {activeStep === 0 && (
        <Box>
          <Typography variant="h6" gutterBottom>Select a User or Group</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Users</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell align="right">
                          <Button 
                            variant="contained" 
                            size="small"
                            onClick={() => {
                              setActiveUser(user);
                              setActiveStep(1);
                            }}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Groups</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userGroups.map((group) => (
                      <TableRow key={group.id}>
                        <TableCell>{group.title}</TableCell>
                        <TableCell align="right">
                          <Button 
                            variant="contained" 
                            size="small"
                            onClick={() => {
                              setActiveGroup(group);
                              setActiveStep(1);
                            }}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )}

      {activeStep === 1 && (
        <Box>
          <Typography variant="h6" gutterBottom>Choose a Pricelist</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Parts</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadedFiles.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>{new Date(file.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{file.validRows}</TableCell>
                    <TableCell align="right">
                      <Button 
                        variant="contained" 
                        size="small"
                        onClick={() => {
                          setActivePricelist(file);
                          setActiveStep(2);
                        }}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {activeStep === 2 && (
        <Box>
          <Typography variant="h6" gutterBottom>Confirm Assignment</Typography>
          <Card sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1">
              {activeUser ? `User: ${activeUser.email}` : `Group: ${activeGroup.name}`}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="subtitle1">
              Pricelist: {activePricelist.name}
            </Typography>
          </Card>
          <Button variant="contained" color="primary" onClick={handleAssignPricelist}>
            Confirm Assignment
          </Button>
        </Box>
      )}

      {activeStep === 3 && (
        <Box>
          <Typography variant="h6" gutterBottom>Assignment Complete</Typography>
          <Typography>
            The pricelist has been successfully assigned. You can now close this page or assign another pricelist.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {
              setActiveStep(0);
              setActiveUser(null);
              setActiveGroup(null);
              setActivePricelist(null);
            }}
            sx={{ mt: 2 }}
          >
            Assign Another Pricelist
          </Button>
        </Box>
      )}

      <Snackbar
        open={showFeedback}
        autoHideDuration={3000}
        onClose={() => setShowFeedback(false)}
        message={userFeedback}
      />

      <PricelistSidebar
        pricelist={activePricelist}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
    </Box>
  );
};

export default AssignPricelist;
