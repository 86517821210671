import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { AppContext } from "../../App"; // Import the AppContext from App.js
import InvoiceChoices from "./InvoiceChoices";
import InvoiceDialog from "./InvoiceDialog";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
const Invoices = ({
  hasOpenInvoices,
  handleCreateInvoice,
  invoices,
  activeInvoice,
}) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  console.log("activeInvoice", activeInvoice);
  const { userRecord, setUserRecord } = useContext(AppContext); // Get the user from the AppContext
  const [showInvoiceChoices, setShowInvoiceChoices] = useState(false);

  const [showFeedback, setShowFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);

  console.log("got userRecord", userRecord);

  const popFeedback = (message) => {
    setUserFeedback(message);
    setShowFeedback(true);
  };

  const assignInvoice = async (invoiceId) => {
    const userToUpdate = {
      email: userRecord.email,
      id: userRecord.id,
      activeInvoiceID: invoiceId,
    };
    console.log("userToUpdate", userToUpdate);

    popFeedback("Pricelist assigned successfully!");
    const updatedUserResponse = await API.graphql(
      graphqlOperation(updateUser, { input: userToUpdate })
    );
    console.log("updatedUserResponse", updatedUserResponse);
    const newUserRecord = updatedUserResponse.data.updateUser;
    setUserRecord(newUserRecord);
    return true;
  };

  const handleShowInvoiceChoices = () => {
    setShowInvoiceChoices(true);
  };

  const handleHideInvoiceChoices = () => {
    setShowInvoiceChoices(false);
  };

  const handleInvoiceSelect = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    assignInvoice(invoiceId);
  };

  console.log("invoices", invoices);

  return (
    <div style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      {userRecord.activeInvoice && invoices.length > 0 && (
        <Typography
          variant="body1"
          sx={{
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Adding to Invoice:{" "}
          {userRecord.activeInvoice.name
            ? userRecord.activeInvoice.name
            : "None"}
        </Typography>
      )}

      {invoices.length > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="invoice-select">Select Invoice</InputLabel>
            <Select
              placeholder="Select Invoice"
              label="Select Invoice"
              value={userRecord.activeInvoiceID}
              onChange={(e) => handleInvoiceSelect(e.target.value)}
            >
              {invoices.map((invoice) => (
                <MenuItem value={invoice.id} key={invoice.id}>
                  {invoice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <>
          <Typography
            variant="body1"
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            There are no open invoices.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <InvoiceDialog handleCreateInvoice={handleCreateInvoice} />
          </Box>
        </>
      )}
      <InvoiceChoices
        modalOpen={showInvoiceChoices}
        handleCloseModal={handleHideInvoiceChoices}
        handleCreateInvoice={handleCreateInvoice}
      />
    </div>
  );
};

export default Invoices;
