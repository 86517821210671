import React, { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { Loader, Text } from "@aws-amplify/ui-react";
import { Card, Container, Box, Typography, Button } from "@mui/material";
import MetalsChart from "../MetalsChart";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App"; // Import the AppContext from App.js
import PricelistInfoSection from "./PricelistInfoSection";
import PricelistUsersTable from "./PricelistUsersTable";
import { useUser } from "../../UserContext";

const ActionButton = ({ onClick, text, icon }) => {
  return (
    <Button
      variant="contained"
      sx={{ margin: "5px" }}
      onClick={onClick}
      endIcon={icon}
      size={"large"}
    >
      {text}
    </Button>
  );
};

const getRelativeLink = (path, activeProvider) => {
  if (activeProvider) {
    return `/${activeProvider.companyPath}${path}`;
  }
  return path;
};

const Pricing = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const { userRecord } = useContext(AppContext);
  const { activeProvider } = useUser(); // Get activeProvider from UserContext

  const navigate = useNavigate();

  const buttons = [
    {
      text: "Manage Your Pricelists",
      icon: <VisibilityIcon />,
      onClick: () => navigate(getRelativeLink("/view-pricelists", activeProvider)),
    },
    {
      text: "Assign a Pricelist to a Customer/Group",
      icon: <ShareIcon />,
      onClick: () => navigate(getRelativeLink("/assign-pricelist", activeProvider)),
    },
  ];

  if (loading) {
    return (
      <Card variation="elevated" style={{ margin: "40px" }}>
        <Text>{loadingText}</Text>
        <Loader size="large" variation="linear" />
      </Card>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          flexDirection: "column",
        }}
      >
        {/* <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 500,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Current Market as of {new Date().toLocaleString()}.
        </Typography>
        <MetalsChart metalSymbols={["PD", "PT", "RH"]} showChart={false} /> */}

        <PricelistInfoSection />
        {userRecord?.activePricelist?.name && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              You are viewing: {userRecord?.activePricelist?.name}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginVertical: "20px",
          }}
        >
          {buttons.map((button) => (
            <ActionButton
              key={uuidv4()}
              text={button.text}
              icon={button.icon}
              onClick={button.onClick}
            />
          ))}
        </Box>
      </Box>
      <PricelistUsersTable />
    </Container>
  );
};

export default Pricing;
