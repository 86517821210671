import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import SearchPricelist from './search/SearchPricelist';
import { createPart, updateInvoice, updateUser, createBox } from "../graphql/mutations";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { listBoxes } from "../graphql/queries";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, ListItem, ListItemAvatar, Avatar, ListItemText, Stack } from '@mui/material';

import {
  Typography,
  Grid,
  Box,
  Divider,
  Button,
} from "@mui/material";

import { getInvoice, getPricelist } from "../graphql/queries";
import { useNavigate, useParams } from "react-router-dom";
import OurLoader from "./OurLoader";
import InvoiceOptionBar from "./invoices/InvoiceOptionBar";

import { AppContext } from "../App"; // Import the AppContext from App.js

const getInvoiceWithBoxes = `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      name
      status
      dateSent
      partCount
      ownerID
      recipientID
      boxes {
        items {
          id
          name
          status
          parts {
            items {
              id
              partNumber
              price
              grade
            }
          }
        }
      }
      parts {
        items {
          id
          partNumber
          price
          grade
        }
      }
    }
  }
`;

const ViewInvoice = () => {
  const [invoice, setInvoice] = useState(null);

  const [hasOpenInvoices, setHasOpenInvoices] = useState(false);
  const { userRecord } = useContext(AppContext); // Get the user from the AppContext

  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const invoiceId = useParams().invoiceId;
  console.log("ViewInvoice component mounted, invoiceId:", invoiceId);

  const navigate = useNavigate();

  const [openSearch, setOpenSearch] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [openBoxDialog, setOpenBoxDialog] = useState(false);
  const [boxName, setBoxName] = useState('');
  const [selectedBox, setSelectedBox] = useState(null);

  const theme = useTheme();

  const setActiveInvoice = async (invoiceId) => {
    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: userRecord.id,
            activeInvoiceID: invoiceId
          }
        })
      );
    } catch (error) {
      console.error("Error setting active invoice:", error);
    }
  };

  const fetchInvoice = async (invoiceId) => {
    console.log("Fetching invoice with id:", invoiceId);
    setLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(getInvoiceWithBoxes, { id: invoiceId })
      );
      console.log("Invoice fetch response:", response);
      
      const invoiceData = response.data.getInvoice;
      if (invoiceData) {
        setInvoice(invoiceData);
        
        await setActiveInvoice(invoiceId);
        
        if (userRecord?.activePricelistID) {
          const response2 = await API.graphql(
            graphqlOperation(getPricelist, { id: userRecord.activePricelistID })
          );
          const pricelistData = response2.data.getPricelist;
          if (pricelistData?.user) {
            setCustomer(pricelistData.user);
          }
        }

        setHasOpenInvoices(invoiceData.status === "open");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoice:", error);
      setLoading(false);
    }
  };
  
  const handleCreateBox = async () => {
    try {
      const input = {
        name: boxName,
        invoiceBoxesId: invoiceId,
        status: 'open',
        fromPricelist: userRecord.activePricelistID
      };

      console.log("input", input);
      
      await API.graphql(graphqlOperation(createBox, { input }));
      setOpenBoxDialog(false);
      setBoxName('');
      fetchInvoice(invoiceId);
    } catch (error) {
      console.error("Error creating box:", error);
    }
  };

  useEffect(() => {
    if (invoiceId && userRecord) {
      console.log("Initiating invoice fetch for id:", invoiceId);
      fetchInvoice(invoiceId);
    }

    return () => {
      if (userRecord) {
        setActiveInvoice(null);
      }
    };
  }, [invoiceId, userRecord]);

  const totalParts = invoice?.parts?.items?.length || 0;
  const totalValue = invoice?.parts?.items?.reduce(
    (total, part) => total + (part?.price || 0),
    0
  ) || 0;
  const averageValue = totalParts > 0 ? totalValue / totalParts : 0;

  const handleAddPart = async (part, grade) => {
    if (!selectedBox) {
      alert("Please select a box first");
      return;
    }

    try {
      const partToAdd = {
        partNumber: part.partNumber,
        price: parseFloat(part.minPrice),
        fromPricelist: userRecord.activePricelistID,
        grade: parseFloat(grade),
        boxPartsId: selectedBox
      };

      await API.graphql(
        graphqlOperation(createPart, { input: partToAdd })
      );

      await API.graphql(
        graphqlOperation(updateInvoice, {
          input: {
            id: invoiceId,
            partCount: (invoice?.parts?.items?.length || 0) + 1
          }
        })
      );

      setShowFeedback(true);
      fetchInvoice(invoiceId);
    } catch (error) {
      console.error("Error adding part to box:", error);
    }
  };

  console.log("invoice", invoice);

  if (loading) {
    return <OurLoader />;
  }

  if (!invoice) {
    return (
      <Grid item xs={12} md={6} sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 2, fontFamily: "Poppins" }}>
          No Invoice Found
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          This invoice may have been deleted or doesn't exist.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }}
          onClick={() => navigate('/invoices')}
        >
          Return to Invoices
        </Button>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{ mt: 4, mb: 2, ml: 2, textAlign: "center" }}
        variant="h3"
        component="div"
      >
        TEST
      </Typography>
      <Typography
        sx={{ mt: 4, mb: 2, ml: 2, textAlign: "center" }}
        variant="h3"
        component="div"
      >
        $
        {invoice &&
          invoice?.parts?.items
            ?.reduce((total, part) => total + (part.price || 0), 0)
            .toFixed(2)}{" "}
      </Typography>

      <Typography
        sx={{ mt: 4, mb: 2, ml: 2, textAlign: "center" }}
        variant="h4"
        component="div"
      >
        {invoice && invoice && invoice.name}
      </Typography>

      <Typography
        sx={{ mt: 4, mb: 2, ml: 2, textAlign: "center" }}
        variant="p"
        component="div"
      >
        {customer && customer.companyName}
      </Typography>

      <Divider />
      <InvoiceOptionBar />
      <Divider />

      <div
        style={{
          margin: "10px",
          // border: "1px solid #1F77FC",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          Recipient: <strong>{customer && customer.username}</strong>
        </Typography>

        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          Email: <strong>{customer && customer.email}</strong>
        </Typography>

        <Typography
          sx={{ mt: 4, mb: 2, ml: 2, fontSize: "13px" }}
          variant="p"
          component="div"
        >
          Date: <strong>{new Date().toLocaleString()}</strong>
        </Typography>
      </div>

      <div
        style={{
          margin: "10px",
          border: "1px solid #1F77FC",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          <strong>
            {invoice &&
              invoice?.parts?.items?.filter((u) => u.grade === 1).length}{" "}
          </strong>
          Whole Units
        </Typography>

        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          <strong>
            {invoice &&
              invoice?.parts?.items?.filter((u) => u.grade === 0.5).length}{" "}
          </strong>
          Half Units
        </Typography>

        <Divider />

        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          <strong>{invoice && invoice?.parts?.items?.length}</strong> Total Units
        </Typography>
      </div>

      <div
        style={{
          margin: "10px",
          border: "1px solid #1F77FC",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          Average Value Per Unit: <strong>${averageValue.toFixed(2)}</strong>
        </Typography>

        <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="p" component="div">
          Amount: <strong>${totalValue ? totalValue.toFixed(2) : 0}</strong>
        </Typography>

        <Typography
          sx={{ mt: 4, mb: 2, ml: 2, fontSize: "13px" }}
          variant="p"
          component="div"
        >
          Transaction ID:{" "}
          <strong>{invoice && invoice?.id ? invoice.id : "N/A"}</strong>
        </Typography>
      </div>

      <Typography sx={{ mt: 4, mb: 2, ml: 2 }} variant="h4" component="div">
        {invoice?.parts?.items?.length || 0} Pieces
      </Typography>
      <Box sx={{ m: 2 }}>
        {(!invoice?.parts?.items || invoice.parts.items.length === 0) ? (
          <Box sx={{ 
            textAlign: 'center', 
            py: 4, 
            backgroundColor: 'rgba(0,0,0,0.02)',
            borderRadius: 2,
            border: '1px dashed rgba(0,0,0,0.1)'
          }}>
            <Typography variant="h6" sx={{ color: 'text.secondary', mb: 1 }}>
              No Parts Added Yet
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Click the + button to add parts to this invoice
            </Typography>
            <Fab
              color="primary"
              size="small"
              onClick={() => setOpenSearch(true)}
            >
              <AddIcon />
            </Fab>
          </Box>
        ) : (
          invoice.parts.items.map((part, i) => (
            <ListItem
              key={i}
              sx={{
                border: "1px solid #1F77FC",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              onClick={() => {
                navigate("/view-invoice/" + invoice.id);
              }}
            >
              <ListItemAvatar>
                <Avatar>{part.grade === 1 ? "1" : "½"}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={part.partNumber}
                secondary={`$${part.price ? part.price.toFixed(2) : "0.00"}`}
              />
            </ListItem>
          ))
        )}
      </Box>

      <Box sx={{ 
        backgroundColor: '#f5f5f5', 
        borderRadius: 2,
        p: 4,
        mb: 4,
        mx: 2
      }}>
        <Typography 
          sx={{ 
            mb: 4,
            fontFamily: "Poppins",
            fontWeight: 600,
            color: theme.palette.primary.main,
            pl: 2
          }} 
          style={{
            marginTop: 10,
            marginBottom: 10
          }}
          variant="h4" 
          component="div"
        >
          Boxes ({invoice?.boxes?.items?.length || 0})
        </Typography>

        <Box sx={{ mx: 1 }}>
          {(!invoice?.boxes?.items || invoice.boxes.items.length === 0) ? (
            <Box sx={{ 
              textAlign: 'center', 
              py: 4, 
              backgroundColor: 'rgba(255,255,255,0.8)',
              borderRadius: 2,
              border: '1px dashed rgba(0,0,0,0.1)'
            }}>
              <Typography variant="h6" sx={{ color: 'text.secondary', mb: 1 }}>
                No Boxes Added Yet
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                Click the + button to add a box to this invoice
              </Typography>
              <Fab
                color="primary"
                size="small"
                onClick={() => setOpenBoxDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Box>
          ) : (
            invoice.boxes.items.map((box) => (
              <Box
                key={box.id}
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.primary.main,
                  borderRadius: '10px',
                  p: 3,
                  mb: 3,
                  backgroundColor: selectedBox === box.id ? 'rgba(31,119,252,0.1)' : 'white'
                }}
                style={{
                  transition: 'all 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 1
                  },
                  marginBottom: 10,
                  marginTop: 10,
                  padding: 5
                }}
              >
                <Stack 
                  direction="row" 
                  justifyContent="space-between" 
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <Stack spacing={1}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontFamily: "Poppins",
                        fontWeight: 600
                      }}
                    >
                      {box.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ color: 'text.secondary' }}
                    >
                      {box.parts?.items?.length || 0} Pieces
                    </Typography>
                  </Stack>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelectedBox(box.id);
                      setOpenSearch(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Parts
                  </Button>
                </Stack>

                {/* Parts List */}
                {box.parts?.items?.map((part) => (
                  <ListItem
                    key={part.id}
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "10px",
                      marginTop: "10px",
                      marginX: 2,
                      backgroundColor: 'white',
                      transition: 'all 0.2s',
                      '&:hover': {
                        transform: 'translateX(4px)',
                        boxShadow: 1
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar 
                        sx={{ 
                          bgcolor: theme.palette.primary.main,
                          fontFamily: "Poppins",
                          fontWeight: 600
                        }}
                      >
                        {part.grade === 1 ? "1" : "½"}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontWeight: 600 }}>
                          {part.partNumber}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ color: theme.palette.success.main }}>
                          ${part.price ? part.price.toFixed(2) : "0.00"}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </Box>
            ))
          )}
        </Box>
      </Box>

      {/* <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000
        }}
        onClick={() => setOpenSearch(true)}
      >
        <AddIcon />
      </Fab> */}

      <Modal
        open={openSearch}
        onClose={() => setOpenSearch(false)}
        aria-labelledby="search-modal"
        aria-describedby="search-parts-to-add"
        sx={{
          overflow: 'auto',
          height: '100%'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: 2
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={() => setOpenSearch(false)}
          >
            <CloseIcon />
          </IconButton>
          <SearchPricelist 
            onPartSelect={(part, grade) => {
              handleAddPart(part, grade);
              setOpenSearch(false);
            }}
            showPricelistSelect={false}
            showDisclaimers={false}
          />
        </Box>
      </Modal>

      <Snackbar
        open={showFeedback}
        autoHideDuration={3000}
        onClose={() => setShowFeedback(false)}
        message="Part successfully added to invoice"
      />

      <Dialog open={openBoxDialog} onClose={() => setOpenBoxDialog(false)}>
        <DialogTitle>Create New Box</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Box Name"
            fullWidth
            value={boxName}
            onChange={(e) => setBoxName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBoxDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateBox} variant="contained">
            Create Box
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Fab
        color="secondary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 96,
          zIndex: 1000
        }}
        onClick={() => setOpenBoxDialog(true)}
      >
        <AddIcon />
      </Fab> */}
    </Grid>
  );
};

export default ViewInvoice;
