import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function AlertsScreen() {
  const [pricelistGroups, setPricelistGroups] = useState([]);

  const [offsetType, setOffsetType] = React.useState("Fixed");
  const [groupOffset, setGroupOffset] = useState(30);
  const [groupName, setGroupName] = useState("");

  const handleOffsetTypeChange = (event) => {
    setOffsetType(event.target.value);
  };

  const handleOffsetChange = (event) => {
    setGroupOffset(event.target.value);
  };

  const doAddGroup = () => {
    console.log("adding group");
    const newGroups = pricelistGroups;
    newGroups.push({
      offsetType: offsetType,
      groupOffset,
      groupName,
    });
    setPricelistGroups(newGroups);
  };

  return (
    <div>
      <Typography
        gutterBottom
        variant="h2"
        component="div"
        style={{ margin: "20px", marginTop: "30px" }}
      >
        Pricelist Groups
      </Typography>

      <Typography
        gutterBottom
        variant="h4"
        component="div"
        style={{ margin: "20px" }}
      >
        Add a Pricelist Group
      </Typography>

      <Typography
        gutterBottom
        variant="p"
        component="div"
        style={{ margin: "20px" }}
      >
        Use the slider or the icons to set your price offset. You can select a
        percentage or a fixed dollar amount. This will offset the price from the
        list given to these users by lowering the price given by that amount.
      </Typography>

      <Box style={{ padding: "20px" }}>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            label="Group Name"
            variant="outlined"
            value={groupName}
            onChange={(evt) => setGroupName(evt.target.value)}
          />
        </FormControl>

        <Typography
          gutterBottom
          variant="h6"
          component="div"
          style={{ margin: "20px" }}
        >
          {offsetType} Offset: -
          {offsetType === "Fixed" && (
            <Typography variant="span" component="span">
              $
            </Typography>
          )}
          <Typography variant="span" component="span">
            {groupOffset}
          </Typography>
          {offsetType === "Percentage" && (
            <Typography variant="span" component="span">
              %
            </Typography>
          )}
        </Typography>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <RemoveIcon onClick={() => setGroupOffset(groupOffset - 1)} />
          <Slider
            aria-label="Volume"
            value={groupOffset}
            onChange={handleOffsetChange}
          />
          <AddIcon onClick={() => setGroupOffset(groupOffset + 1)} />
        </Stack>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Offset Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={offsetType}
            label="Offset Type"
            onChange={handleOffsetTypeChange}
          >
            <MenuItem value={"Fixed"}>Fixed Dollar Amount</MenuItem>
            <MenuItem value={"Percentage"}>Percentage</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          style={{ marginTop: "20px" }}
          onClick={() => doAddGroup()}
        >
          Save Group
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell align="right">Offset Type</TableCell>
              <TableCell align="right">Offset</TableCell>
              <TableCell align="right">Number of Users</TableCell>
              <TableCell align="right">Edit Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricelistGroups.map((row) => (
              <TableRow
                key={row.groupName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.groupName}
                </TableCell>
                <TableCell align="right">{row.offsetType}</TableCell>
                <TableCell align="right">{row.groupOffset}</TableCell>
                <TableCell align="right">0</TableCell>
                <TableCell align="right">
                  <Button variant="contained" style={{ marginTop: "20px" }}>
                    Edit Group
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
