import * as React from "react";
import Typography from "@mui/material/Typography";
import Invoices from "../components/Invoices";
import InnerPage from "../components/InnerPage";
export default function InvoicesScreen() {
  return (
    <InnerPage title="Invoices">
      <Invoices />
    </InnerPage>
  );
}
