import React, { useState } from "react";
import AssignPricelist from "../../components/pricing/AssignPricelist";
import Typography from "@mui/material/Typography";
import InnerPage from "../../components/InnerPage";

export default function CreatePricelistScreen({ user }) {
  const [step, setStep] = useState(1);
  return (
    <InnerPage title={`Create Price List - Step ${step}`}>
      <AssignPricelist user={user} setStep={setStep} />
    </InnerPage>
  );
}
