import React, { useState, useEffect } from "react";
import {
  Box,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { listPricelists } from "../../graphql/queries";
import { useUser } from "../../UserContext";

const PricelistSelect = ({
  pricelistID = null,
  variation = null,
  userID = null,
}) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const { userRecord, refreshUserAndPricelist, user } = useUser();

  const [showFeedback, setShowFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);
  const [pricelists, setPricelists] = useState([]);

  const popFeedback = (message) => {
    setUserFeedback(message);
    setShowFeedback(true);
  };

  const assignPricelist = async (pricelistID) => {
    const userToUpdate = {
      id: userID ? userID : userRecord.id,
      activePricelistID: pricelistID,
    };

    popFeedback("Pricelist assigned successfully!");
    const updatedUserResponse = await API.graphql(
      graphqlOperation(updateUser, { input: userToUpdate })
    );
    console.log("refreshUserAndPricelist", refreshUserAndPricelist);

    refreshUserAndPricelist(user);

    return true;
  };

  const handlePricelistSelect = (pricelistID) => {
    setSelectedInvoiceId(pricelistID);
    assignPricelist(pricelistID);
  };

  const fetchPricelists = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listPricelists, {
          filter: {
            userId: {
              eq: userRecord.id,
            },
          },
        })
      );

      const pricelistsData = response.data.listPricelists.items;

      // Sort pricelists by createdAt date field
      pricelistsData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setPricelists(pricelistsData);
    } catch (error) {
      console.log("Error fetching pricelists:", error);
    }
  };

  useEffect(() => {
    fetchPricelists();
  }, []);

  // if (!pricelists || (pricelists.length === 0 && !variation)) {
  //   return (
  //     <Typography
  //       variant={"h5"}
  //       sx={{
  //         textAlign: "center",
  //         fontFamily: "Poppins",
  //         fontWeight: 600,
  //         marginTop: "15px",
  //         marginBottom: "15px",
  //       }}
  //     >
  //       To search your own pricelists, upload a pricelist first.
  //     </Typography>
  //   );
  // }

  return (
    <div style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      {/* <h2>{pricelistID}</h2>
      <h3>{JSON.stringify(pricelists)}</h3> */}
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="invoice-select">Select Pricelist</InputLabel>
          <Select
            placeholder="Select Invoice"
            label="Select Invoice"
            value={pricelistID ? pricelistID : userRecord.activePricelistID}
            onChange={(e) => handlePricelistSelect(e.target.value)}
          >
            {pricelists.map((pricelist) => (
              <MenuItem value={pricelist.id} key={pricelist.id}>
                {pricelist.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default PricelistSelect;
