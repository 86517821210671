import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
export default function PartCard({ part }) {
  const { minPrice, maxPrice, partNumber, image1 } = part;
  const theme = useTheme();

  return (
    <Card sx={{ marginTop: "10px", borderRadius: "10px" }}>
      <CardActionArea>
        {part.image1 && (
          <CardMedia
            component="img"
            height="200"
            image={`https://s3.us-east-2.amazonaws.com/366images/${part.image1}`}
            // alt="green iguana"
          />
        )}
        <CardContent sx={{ backgroundColor: "black", color: "white" }}>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.secondary.main,
              fontFamily: "Poppins",
              fontWeight: 700,
            }}
          >
            ${parseFloat(minPrice).toFixed(2)}
            {minPrice !== maxPrice && (
              <span> - ${parseFloat(maxPrice).toFixed(2)}</span>
            )}
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "12px" }}
            component="div"
          >
            {part.partNumber}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <div>
        <Button variant="outlined" color="error" style={{ margin: "10px" }}>
          <DeleteIcon sx={{ mr: 1 }} />
          DELETE
        </Button>
        <Button variant="outlined" color="success" style={{ margin: "10px" }}>
          <EditIcon sx={{ mr: 1 }} />
          EDIT
        </Button>
      </div> */}
    </Card>
  );
}
