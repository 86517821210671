import * as React from "react";
import SearchPricelist from "../../components/SearchPricelist";
import { Box, Container } from "@mui/material";
import InnerPage from "../../components/InnerPage";
export default function PricelistsScreen() {
  return (
    <InnerPage title="Search">
      <SearchPricelist />
    </InnerPage>
  );
}
