/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://gpe6jqy3ajdh7eu23k5abiibce.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pglj3peyjbdezdgfcz7gufb4fa",
    "aws_cognito_identity_pool_id": "us-east-1:d028f4ee-b4ee-46a1-af22-941befd29d08",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xrenJGENT",
    "aws_user_pools_web_client_id": "6v52iap1aucqrohoj9s85c9c25",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "surch75288660b0dc4a998a6a66b0c7d30fe49ccf4-nextprod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
