import * as React from "react";

import Settings from "../components/Settings";

import InnerPage from "../components/InnerPage";

export default function AlertsScreen() {
  return (
    <InnerPage title="Settings">
      <Settings />
    </InnerPage>
  );
}
