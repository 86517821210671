/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPricelist = /* GraphQL */ `
  mutation CreatePricelist(
    $input: CreatePricelistInput!
    $condition: ModelPricelistConditionInput
  ) {
    createPricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      parentPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      derivedPricelists {
        nextToken
        __typename
      }
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      hedges {
        nextToken
        __typename
      }
      status
      searches {
        nextToken
        __typename
      }
      provider {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      subscribers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      pricelistDerivedPricelistsId
      userPricelistsId
      __typename
    }
  }
`;
export const updatePricelist = /* GraphQL */ `
  mutation UpdatePricelist(
    $input: UpdatePricelistInput!
    $condition: ModelPricelistConditionInput
  ) {
    updatePricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      parentPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      derivedPricelists {
        nextToken
        __typename
      }
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      hedges {
        nextToken
        __typename
      }
      status
      searches {
        nextToken
        __typename
      }
      provider {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      subscribers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      pricelistDerivedPricelistsId
      userPricelistsId
      __typename
    }
  }
`;
export const deletePricelist = /* GraphQL */ `
  mutation DeletePricelist(
    $input: DeletePricelistInput!
    $condition: ModelPricelistConditionInput
  ) {
    deletePricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      parentPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      derivedPricelists {
        nextToken
        __typename
      }
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      hedges {
        nextToken
        __typename
      }
      status
      searches {
        nextToken
        __typename
      }
      provider {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      subscribers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      pricelistDerivedPricelistsId
      userPricelistsId
      __typename
    }
  }
`;
export const createSecondPricelist = /* GraphQL */ `
  mutation CreateSecondPricelist(
    $input: CreateSecondPricelistInput!
    $condition: ModelSecondPricelistConditionInput
  ) {
    createSecondPricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSecondPricelist = /* GraphQL */ `
  mutation UpdateSecondPricelist(
    $input: UpdateSecondPricelistInput!
    $condition: ModelSecondPricelistConditionInput
  ) {
    updateSecondPricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSecondPricelist = /* GraphQL */ `
  mutation DeleteSecondPricelist(
    $input: DeleteSecondPricelistInput!
    $condition: ModelSecondPricelistConditionInput
  ) {
    deleteSecondPricelist(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userId
      validRows
      dataCount
      s3Key
      notes
      filename
      adjustments
      name
      parts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCSVFile = /* GraphQL */ `
  mutation CreateCSVFile(
    $input: CreateCSVFileInput!
    $condition: ModelCSVFileConditionInput
  ) {
    createCSVFile(input: $input, condition: $condition) {
      id
      uploadedAt
      username
      validRows
      dataCount
      s3Key
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userCSVFilesId
      __typename
    }
  }
`;
export const updateCSVFile = /* GraphQL */ `
  mutation UpdateCSVFile(
    $input: UpdateCSVFileInput!
    $condition: ModelCSVFileConditionInput
  ) {
    updateCSVFile(input: $input, condition: $condition) {
      id
      uploadedAt
      username
      validRows
      dataCount
      s3Key
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userCSVFilesId
      __typename
    }
  }
`;
export const deleteCSVFile = /* GraphQL */ `
  mutation DeleteCSVFile(
    $input: DeleteCSVFileInput!
    $condition: ModelCSVFileConditionInput
  ) {
    deleteCSVFile(input: $input, condition: $condition) {
      id
      uploadedAt
      username
      validRows
      dataCount
      s3Key
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userCSVFilesId
      __typename
    }
  }
`;
export const createSearch = /* GraphQL */ `
  mutation CreateSearch(
    $input: CreateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    createSearch(input: $input, condition: $condition) {
      id
      ipAddress
      location
      latitude
      longitude
      price
      partNumber
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearched {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearchedID
      pricelistID
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistSearchesId
      userSearchesId
      __typename
    }
  }
`;
export const updateSearch = /* GraphQL */ `
  mutation UpdateSearch(
    $input: UpdateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    updateSearch(input: $input, condition: $condition) {
      id
      ipAddress
      location
      latitude
      longitude
      price
      partNumber
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearched {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearchedID
      pricelistID
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistSearchesId
      userSearchesId
      __typename
    }
  }
`;
export const deleteSearch = /* GraphQL */ `
  mutation DeleteSearch(
    $input: DeleteSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    deleteSearch(input: $input, condition: $condition) {
      id
      ipAddress
      location
      latitude
      longitude
      price
      partNumber
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearched {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      userSearchedID
      pricelistID
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistSearchesId
      userSearchesId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      tier
      email
      searches {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      newsitems {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      addedParts {
        nextToken
        __typename
      }
      address
      city
      state
      zip
      contactName
      companyName
      phoneNumber
      cellNumber
      idImage
      businessLicense
      priceListInvites
      acceptedInvites
      ownedInvoices {
        nextToken
        __typename
      }
      ownedUserGroups {
        nextToken
        __typename
      }
      belongsToUserGroups {
        nextToken
        __typename
      }
      CSVFiles {
        nextToken
        __typename
      }
      Pricelists {
        nextToken
        __typename
      }
      createdByUserId
      activeInvoiceID
      activeInvoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      activePricelistID
      activePricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      secondPricelistID
      secondPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      companyLogo
      companyPath
      primaryColor
      secondaryColor
      supportTickets {
        nextToken
        __typename
      }
      searchesRemaining
      createdAt
      updatedAt
      themeOptions
      subscriptionActive
      lastPaidDate
      subscriptionTier
      subscriptionTerm
      isProvider
      pricelistSubscribersId
      userGroupUsersId
      userInventoryId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      tier
      email
      searches {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      newsitems {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      addedParts {
        nextToken
        __typename
      }
      address
      city
      state
      zip
      contactName
      companyName
      phoneNumber
      cellNumber
      idImage
      businessLicense
      priceListInvites
      acceptedInvites
      ownedInvoices {
        nextToken
        __typename
      }
      ownedUserGroups {
        nextToken
        __typename
      }
      belongsToUserGroups {
        nextToken
        __typename
      }
      CSVFiles {
        nextToken
        __typename
      }
      Pricelists {
        nextToken
        __typename
      }
      createdByUserId
      activeInvoiceID
      activeInvoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      activePricelistID
      activePricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      secondPricelistID
      secondPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      companyLogo
      companyPath
      primaryColor
      secondaryColor
      supportTickets {
        nextToken
        __typename
      }
      searchesRemaining
      createdAt
      updatedAt
      themeOptions
      subscriptionActive
      lastPaidDate
      subscriptionTier
      subscriptionTerm
      isProvider
      pricelistSubscribersId
      userGroupUsersId
      userInventoryId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      tier
      email
      searches {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      newsitems {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      addedParts {
        nextToken
        __typename
      }
      address
      city
      state
      zip
      contactName
      companyName
      phoneNumber
      cellNumber
      idImage
      businessLicense
      priceListInvites
      acceptedInvites
      ownedInvoices {
        nextToken
        __typename
      }
      ownedUserGroups {
        nextToken
        __typename
      }
      belongsToUserGroups {
        nextToken
        __typename
      }
      CSVFiles {
        nextToken
        __typename
      }
      Pricelists {
        nextToken
        __typename
      }
      createdByUserId
      activeInvoiceID
      activeInvoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      activePricelistID
      activePricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      secondPricelistID
      secondPricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        __typename
      }
      companyLogo
      companyPath
      primaryColor
      secondaryColor
      supportTickets {
        nextToken
        __typename
      }
      searchesRemaining
      createdAt
      updatedAt
      themeOptions
      subscriptionActive
      lastPaidDate
      subscriptionTier
      subscriptionTerm
      isProvider
      pricelistSubscribersId
      userGroupUsersId
      userInventoryId
      __typename
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userOwnedUserGroupsId
      userBelongsToUserGroupsId
      __typename
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userOwnedUserGroupsId
      userBelongsToUserGroupsId
      __typename
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userOwnedUserGroupsId
      userBelongsToUserGroupsId
      __typename
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userAlertsId
      __typename
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userAlertsId
      __typename
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      title
      content
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userAlertsId
      __typename
    }
  }
`;
export const createNewsItem = /* GraphQL */ `
  mutation CreateNewsItem(
    $input: CreateNewsItemInput!
    $condition: ModelNewsItemConditionInput
  ) {
    createNewsItem(input: $input, condition: $condition) {
      id
      title
      content
      img
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userNewsitemsId
      __typename
    }
  }
`;
export const updateNewsItem = /* GraphQL */ `
  mutation UpdateNewsItem(
    $input: UpdateNewsItemInput!
    $condition: ModelNewsItemConditionInput
  ) {
    updateNewsItem(input: $input, condition: $condition) {
      id
      title
      content
      img
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userNewsitemsId
      __typename
    }
  }
`;
export const deleteNewsItem = /* GraphQL */ `
  mutation DeleteNewsItem(
    $input: DeleteNewsItemInput!
    $condition: ModelNewsItemConditionInput
  ) {
    deleteNewsItem(input: $input, condition: $condition) {
      id
      title
      content
      img
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userNewsitemsId
      __typename
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      boxes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      inventoryUserId
      __typename
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      boxes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      inventoryUserId
      __typename
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      id
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      boxes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      inventoryUserId
      __typename
    }
  }
`;
export const createPart = /* GraphQL */ `
  mutation CreatePart(
    $input: CreatePartInput!
    $condition: ModelPartConditionInput
  ) {
    createPart(input: $input, condition: $condition) {
      id
      partNumber
      secondPartNumber
      price
      addedByUser
      fromPricelist
      status
      cost
      grade
      createdAt
      updatedAt
      pricelistPartsId
      secondPricelistPartsId
      userAddedPartsId
      boxPartsId
      invoicePartsId
      __typename
    }
  }
`;
export const updatePart = /* GraphQL */ `
  mutation UpdatePart(
    $input: UpdatePartInput!
    $condition: ModelPartConditionInput
  ) {
    updatePart(input: $input, condition: $condition) {
      id
      partNumber
      secondPartNumber
      price
      addedByUser
      fromPricelist
      status
      cost
      grade
      createdAt
      updatedAt
      pricelistPartsId
      secondPricelistPartsId
      userAddedPartsId
      boxPartsId
      invoicePartsId
      __typename
    }
  }
`;
export const deletePart = /* GraphQL */ `
  mutation DeletePart(
    $input: DeletePartInput!
    $condition: ModelPartConditionInput
  ) {
    deletePart(input: $input, condition: $condition) {
      id
      partNumber
      secondPartNumber
      price
      addedByUser
      fromPricelist
      status
      cost
      grade
      createdAt
      updatedAt
      pricelistPartsId
      secondPricelistPartsId
      userAddedPartsId
      boxPartsId
      invoicePartsId
      __typename
    }
  }
`;
export const createBox = /* GraphQL */ `
  mutation CreateBox(
    $input: CreateBoxInput!
    $condition: ModelBoxConditionInput
  ) {
    createBox(input: $input, condition: $condition) {
      id
      name
      parts {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      invoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      fromPricelist
      status
      createdAt
      updatedAt
      inventoryBoxesId
      invoiceBoxesId
      __typename
    }
  }
`;
export const updateBox = /* GraphQL */ `
  mutation UpdateBox(
    $input: UpdateBoxInput!
    $condition: ModelBoxConditionInput
  ) {
    updateBox(input: $input, condition: $condition) {
      id
      name
      parts {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      invoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      fromPricelist
      status
      createdAt
      updatedAt
      inventoryBoxesId
      invoiceBoxesId
      __typename
    }
  }
`;
export const deleteBox = /* GraphQL */ `
  mutation DeleteBox(
    $input: DeleteBoxInput!
    $condition: ModelBoxConditionInput
  ) {
    deleteBox(input: $input, condition: $condition) {
      id
      name
      parts {
        nextToken
        __typename
      }
      inventory {
        id
        createdAt
        updatedAt
        inventoryUserId
        __typename
      }
      invoice {
        id
        dateSent
        ownerID
        recipientID
        invoiceNumber
        status
        name
        partCount
        createdAt
        updatedAt
        userOwnedInvoicesId
        __typename
      }
      fromPricelist
      status
      createdAt
      updatedAt
      inventoryBoxesId
      invoiceBoxesId
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      boxes {
        nextToken
        __typename
      }
      dateSent
      owner {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      ownerID
      recipientID
      recipient {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      invoiceNumber
      status
      name
      parts {
        nextToken
        __typename
      }
      partCount
      createdAt
      updatedAt
      userOwnedInvoicesId
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      boxes {
        nextToken
        __typename
      }
      dateSent
      owner {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      ownerID
      recipientID
      recipient {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      invoiceNumber
      status
      name
      parts {
        nextToken
        __typename
      }
      partCount
      createdAt
      updatedAt
      userOwnedInvoicesId
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      boxes {
        nextToken
        __typename
      }
      dateSent
      owner {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      ownerID
      recipientID
      recipient {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      invoiceNumber
      status
      name
      parts {
        nextToken
        __typename
      }
      partCount
      createdAt
      updatedAt
      userOwnedInvoicesId
      __typename
    }
  }
`;
export const createHedge = /* GraphQL */ `
  mutation CreateHedge(
    $input: CreateHedgeInput!
    $condition: ModelHedgeConditionInput
  ) {
    createHedge(input: $input, condition: $condition) {
      id
      numberOfPieces
      status
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistHedgesId
      __typename
    }
  }
`;
export const updateHedge = /* GraphQL */ `
  mutation UpdateHedge(
    $input: UpdateHedgeInput!
    $condition: ModelHedgeConditionInput
  ) {
    updateHedge(input: $input, condition: $condition) {
      id
      numberOfPieces
      status
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistHedgesId
      __typename
    }
  }
`;
export const deleteHedge = /* GraphQL */ `
  mutation DeleteHedge(
    $input: DeleteHedgeInput!
    $condition: ModelHedgeConditionInput
  ) {
    deleteHedge(input: $input, condition: $condition) {
      id
      numberOfPieces
      status
      pricelist {
        id
        userId
        validRows
        dataCount
        s3Key
        notes
        filename
        adjustments
        name
        status
        createdAt
        updatedAt
        pricelistDerivedPricelistsId
        userPricelistsId
        __typename
      }
      createdAt
      updatedAt
      pricelistHedgesId
      __typename
    }
  }
`;
export const createMappedVIN = /* GraphQL */ `
  mutation CreateMappedVIN(
    $input: CreateMappedVINInput!
    $condition: ModelMappedVINConditionInput
  ) {
    createMappedVIN(input: $input, condition: $condition) {
      id
      s3Key
      uploadDate
      recordCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMappedVIN = /* GraphQL */ `
  mutation UpdateMappedVIN(
    $input: UpdateMappedVINInput!
    $condition: ModelMappedVINConditionInput
  ) {
    updateMappedVIN(input: $input, condition: $condition) {
      id
      s3Key
      uploadDate
      recordCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMappedVIN = /* GraphQL */ `
  mutation DeleteMappedVIN(
    $input: DeleteMappedVINInput!
    $condition: ModelMappedVINConditionInput
  ) {
    deleteMappedVIN(input: $input, condition: $condition) {
      id
      s3Key
      uploadDate
      recordCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupportTicket = /* GraphQL */ `
  mutation CreateSupportTicket(
    $input: CreateSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    createSupportTicket(input: $input, condition: $condition) {
      id
      subject
      message
      ticketType
      supportCategory
      status
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userSupportTicketsId
      __typename
    }
  }
`;
export const updateSupportTicket = /* GraphQL */ `
  mutation UpdateSupportTicket(
    $input: UpdateSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    updateSupportTicket(input: $input, condition: $condition) {
      id
      subject
      message
      ticketType
      supportCategory
      status
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userSupportTicketsId
      __typename
    }
  }
`;
export const deleteSupportTicket = /* GraphQL */ `
  mutation DeleteSupportTicket(
    $input: DeleteSupportTicketInput!
    $condition: ModelSupportTicketConditionInput
  ) {
    deleteSupportTicket(input: $input, condition: $condition) {
      id
      subject
      message
      ticketType
      supportCategory
      status
      userID
      user {
        id
        username
        tier
        email
        address
        city
        state
        zip
        contactName
        companyName
        phoneNumber
        cellNumber
        idImage
        businessLicense
        priceListInvites
        acceptedInvites
        createdByUserId
        activeInvoiceID
        activePricelistID
        secondPricelistID
        companyLogo
        companyPath
        primaryColor
        secondaryColor
        searchesRemaining
        createdAt
        updatedAt
        themeOptions
        subscriptionActive
        lastPaidDate
        subscriptionTier
        subscriptionTerm
        isProvider
        pricelistSubscribersId
        userGroupUsersId
        userInventoryId
        __typename
      }
      createdAt
      updatedAt
      userSupportTicketsId
      __typename
    }
  }
`;
