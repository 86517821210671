import * as React from "react";
import { Card, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
export default function PartCard({
  part,
  onClick,
  showPrice = false,
  showPerPound = false,
  localImage = false,
}) {
  const { price, maxPrice, partNumber, image1 } = part;

  const theme = useTheme();

  return (
    <Card sx={{ marginTop: "10px", borderRadius: "10px" }} onClick={onClick}>
      <CardActionArea>
        <CardContent
          sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
        >
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            {part.image1 && (
              <CardMedia
                component="img"
                height="50"
                sx={{ width: "50px" }}
                image={
                  localImage
                    ? `${process.env.PUBLIC_URL}/${part.image1}`
                    : `https://s3.us-east-2.amazonaws.com/366images/${part.image1}`
                }
                // alt="green iguana"
              />
            )}
            {/* <p style={{ color: "#000" }}>{JSON.stringify(theme, null, 2)}</p> */}
            {showPrice && (
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.secondary.main,
                  fontFamily: "Poppins",
                  fontWeight: 700,
                }}
              >
                ${parseFloat(price).toFixed(2)} {showPerPound && "/lb"}
              </Typography>
            )}
            <Typography
              gutterBottom
              sx={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "18px" }}
              component="div"
            >
              {part.partNumber}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>

    </Card>
  );
}
