import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import Autocomplete from "@mui/material/Autocomplete";
import PartDetails from "./PartDetails";
import { Text, Loader } from "@aws-amplify/ui-react";
import { listPricelists } from "../graphql/queries";
import { Amplify, Auth } from "aws-amplify";
import {
  Button,
  ButtonGroup,
  Card,
  Box,
  Stack,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import {
  fetchVehicleInfo,
  fetchVehicleInfoExtended,
  motorSample,
  getML,
} from "../services/search-services";
// import rawBrowns from "../browns-oem-complete.json";
import rawPolk from "../polk-data-complete.json";
import rawPricelist from "../dev-pricelist.json";
import VehicleFilter from "./search/VehicleFilter";
import { getPrediction, flattenObjectToArray } from "./search/services";
import VinSearch from "./search/VinSearch";
import PricelistSearch from "./search/SearchPricelist";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import OurLoader from "./OurLoader";

const SearchPricelist = () => {
  const [csvData, setCsvData] = useState([]);
  const [brownsData, setBrownsData] = useState(null);
  const [polkData, setPolkData] = useState(rawPolk);
  const [lastCSV, setLastCSV] = useState({});
  const [searchedPart, setSearchedPart] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [vinNumber, setVinNumber] = useState("3GNFK12368G231940");
  const [nhtsaNormal, setnhtsaNormal] = useState(null);
  const [nhtsaExtended, setnhtsaExtended] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [brownResult, setBrownResult] = useState(null);
  const [polkResult, setPolkResult] = useState(null);
  const [pricelistResult, setPricelistResult] = useState(null);
  const [truthSource, setTruthSource] = useState(null);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minMLPrice, setMinMLPrice] = useState(0);
  const [maxMLPrice, setMaxMLPrice] = useState(0);

  const { defaultSearchMode, defaultSearch } = useParams();

  const [searchTerm, setSearchTerm] = useState(
    defaultSearch ? defaultSearch : ""
  );

  const [searchMode, setSearchMode] = useState(
    defaultSearchMode ? defaultSearchMode : "oem"
  );
  const [showScanner, setShowScanner] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((usr) => {
        const groups = usr.signInUserSession.idToken.payload["cognito:groups"];

        setUser(usr);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchLatestCSV = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(listPricelists, { limit: 10 })
        );

        const latestFile = response.data.listPricelists.items[0];

        if (!latestFile) {
          return false;
        }

        setLastCSV(latestFile);

        // Check if the uploadedAt date is the same in local storage
        const cachedDate = localStorage.getItem("uploadedAt") || null;
        let jsonData;
        if (
          cachedDate &&
          new Date(cachedDate).getTime() ===
            new Date(latestFile.uploadedAt).getTime()
        ) {
          // Use cached data
          jsonData = JSON.parse(localStorage.getItem("csvData"));
        } else {
          setLoading(true);
          // Fetch the file from S3

          const fileUrl = await Storage.get(latestFile.s3Key, {
            download: true,
          });

          if (fileUrl.Body) {
            // Parse the data as JSON
            const blob = new Blob([fileUrl.Body], { type: "application/json" });
            const jsonText = await blob.text();
            jsonData = JSON.parse(jsonText);

            // Cache the data in local storage
            localStorage.setItem("csvData", JSON.stringify(jsonData));
            localStorage.setItem("uploadedAt", latestFile.uploadedAt);
          }
          setLoading(false);
        }
        setCsvData(jsonData);
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      }
    };

    fetchLatestCSV();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      let results = csvData.filter(
        (item) => item["PART NUMBER"] === searchTerm
      );
      // Limit the results to the first 20 items

      results = results.slice(0, 20);

      let newPart = null;

      if (results.length > 0) {
        newPart = {
          price: results[0]["PRICES"],
          partNumber: results[0]["PART NUMBER"],
        };
      }
      setSearchedPart(newPart);
    } else {
      setSearchedPart(null);
    }
  }, [searchTerm]);

  const resetSearches = () => {
    setnhtsaExtended([]);
    setnhtsaNormal([]);
    setBrownResult([]);
  };

  const doSearchVin = async () => {
    resetSearches();

    const normalData = await fetchVehicleInfo(vinNumber);
    setnhtsaNormal(normalData);

    const extendedData = await fetchVehicleInfoExtended(vinNumber);
    setnhtsaExtended(extendedData);

    let make;
    let model;
    let year;

    for (const prop of normalData.Results) {
      if (prop.Variable === "Make") {
        make = prop.Value;

        setMake(make);
      }
      if (prop.Variable === "Model") {
        model = prop.Value;
        setModel(model);
      }
      if (prop.Variable === "Model Year") {
        year = prop.Value;
        setYear(year);
      }
    }

    searchCsvData(make, model, year, false);
  };

  const motorSampleTransformed = flattenObjectToArray(motorSample);

  const fetchPricesByOEMs = (oems) => {
    const results = [];

    for (const oem of oems) {
      const oemToSearch = oem.replace("-0", "");

      const result = rawPricelist.find(
        (row) => row.PartNumber.toString() === oemToSearch.toString()
      );

      if (result) {
        result.strongFind = true;
        results.push(result);
      }
    }

    for (const oem of oems) {
      const oemToSearch = oem.replace("-0", "");

      const result = rawPricelist.find((row) =>
        row.PartNumber.toString().includes(oemToSearch.toString())
      );

      if (result) {
        result.strongFind = false;
        results.push(result);
      } else {
        const failResult = {
          result: "Failed",
          oem: oemToSearch,
        };
        results.push(failResult);
      }
    }

    return results;
  };

  const searchCsvData = async (make, model, year, reset = true) => {
    if (reset) {
      resetSearches();
    }

    const brownYear = year.slice(2, 4).toString();

    // const brownResult = brownsData.filter(
    //   (row) =>
    //     row.MAKE &&
    //     row.MAKE.toLowerCase() === make.toLowerCase() &&
    //     row.MODEL &&
    //     row.MODEL.toLowerCase() === model.toLowerCase() &&
    //     row.YEAR === brownYear
    // );

    // setBrownResult(brownResult);

    const polkResult = polkData.filter(
      (row) =>
        row.make &&
        row.make.toLowerCase() === make.toLowerCase() &&
        row.model &&
        row.model.toLowerCase() === model.toLowerCase() &&
        row.year.toString() === year.toString()
    );

    const oems = [];

    if (polkResult.length > 0) {
      const mlPred = await getPrediction(
        polkResult[0].year,
        polkResult[0].cc,
        polkResult[0].fuel
      );
      const mlPredAdjusted = mlPred * 5.84;

      setTruthSource("ML Prediction");
      setMinMLPrice(mlPredAdjusted - 14);
      setMaxMLPrice(mlPredAdjusted + 14);
    }

    for (const res of polkResult) {
      oems.push(res.part_no);
    }

    const oemData = fetchPricesByOEMs(oems);

    const min = Math.min(pricelistResultPrices);
    const max = Math.max(pricelistResultPrices);

    setTruthSource("Pricelist Tunnel");

    if (min) {
      setMinPrice(min);
      setMaxPrice(max);
    }

    if (oemData.length > 0) {
      setPricelistResult(oemData);
    } else {
      setTruthSource("ML Prediction");
      setPricelistResult(null);
      setMinPrice(0);
      setMaxPrice(0);
    }

    setPolkResult(polkResult);
  };

  const nhtsaNormalArrayed =
    nhtsaNormal && nhtsaNormal.Results
      ? nhtsaNormal.Results.map((u) => [u.Variable, u.Value])
      : [];

  const nhtsaExtendedArrayed =
    nhtsaExtended && nhtsaExtended.Results
      ? nhtsaExtended.Results.map((u) => [u.Variable, u.Value])
      : [];

  const pricelistResultPrices = pricelistResult
    ? pricelistResult
        .map((u) => {
          if (u.Price !== undefined) {
            return parseFloat(u.Price);
          }
        })
        .filter((price) => price !== undefined)
    : [];

  const vins = [
    {
      vehicle: "2014 Ford Edge",
      vin: "2FMDK3JC4EBA15365",
    },
    {
      vehicle: "1998 Volvo S70",
      vin: "YV1LS5377W1495579",
    },
  ];

  if (loading) {
    return <OurLoader />;
  }

  return (
    <Box
      style={{
        // margin: "10px",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "10px",
          // backgroundColor: "teal",
          margin: "10px",
        }}
      > */}
      <Alert variant="p" sx={{ textAlign: "center" }}>
        Enter the code/number on the catalytic converter unit. If the
        code/number is unavailable, search by Make/Model/Year or by VIN number.
      </Alert>
      {/* </Box> */}
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          width: "100%",
        }}
        direction="row"
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            sx={{
              backgroundColor:
                searchMode === "oem" ? theme.palette.primary : "white",
              color:
                searchMode === "oem"
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
            }}
            onClick={() => setSearchMode("oem")}
          >
            CODE
          </Button>
          <Button
            sx={{
              backgroundColor:
                searchMode === "make_model" ? theme.palette.primary : "white",
              color:
                searchMode === "make_model"
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
            }}
            onClick={() => setSearchMode("make_model")}
            // disabled={true}
          >
            MAKE/MODEL
          </Button>
          <Button
            sx={{
              backgroundColor:
                searchMode === "vin" ? theme.palette.primary : "white",
              color:
                searchMode === "vin"
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
            }}
            onClick={() => setSearchMode("vin")}
          >
            VIN
          </Button>
        </ButtonGroup>
      </Stack>

      {searchMode === "vin" ? (
        <VinSearch />
      ) : searchMode === "make_model" ? (
        <VehicleFilter />
      ) : (
        <PricelistSearch defaultSearch={defaultSearch} />
      )}
    </Box>
  );
};

export default SearchPricelist;
