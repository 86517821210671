import React, { createContext, useState, useEffect, useContext } from "react";
import { Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { listUsers, getPricelist, getUser } from "./graphql/queries";
import { createTheme } from "@mui/material/styles";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userRecord, setUserRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('currentTheme');
    return savedTheme ? createTheme(JSON.parse(savedTheme)) : createTheme();
  });
  const [providerTheme, setProviderTheme] = useState(() => {
    const savedProviderTheme = localStorage.getItem('providerTheme');
    return savedProviderTheme ? createTheme(JSON.parse(savedProviderTheme)) : null;
  });
  const [companyLogo, setCompanyLogo] = useState(null);
  const [activeProvider, setActiveProvider] = useState(null);
  const [userClass, setUserClass] = useState("Customer");

  const getUserGroups = async () => {
    console.log("getUserGroups")
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.idToken.payload["cognito:groups"];
      console.log("groups", groups)

      setUserGroups(groups ? groups.map(group => group.toLowerCase()) : []);
      
      if (!groups) {
        setUserClass("Customer");
      } else if (groups.includes("SuperAdmin")) {
        setUserClass("SuperAdmin");
      } else if (groups.includes("Admin")) {
        setUserClass("Admin");
      } else {
        setUserClass("Customer");
      }
    } catch (err) {
      console.error("Error getting user groups:", err);
      setUserGroups([]);
      setUserClass("Customer");
    }
  };

  const fetchUserInfoAndRefresh = async () => {
    console.log("fetchUserInfoAndRefresh")
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("got user", user)
      setUser(user);

      
      // Fetch user groups
      const groups = user.signInUserSession.idToken.payload["cognito:groups"];
      console.log("fetchUserInfoAndRefresh groups", groups)
      setUserGroups(groups ? groups.map(group => group.toLowerCase()) : []);

      if (!groups) {
        setUserClass("Customer");
      } else if (groups.includes("SuperAdmin")) {
        setUserClass("SuperAdmin");
      } else if (groups.includes("Admin")) {
        setUserClass("Admin");
      } else {
        setUserClass("Customer");
      }

      await refreshUserAndPricelist(user);
    } catch (error) {
      console.error("Error getting auth data", error);
      // Set user to null if not authenticated
      setUser(null);
      setUserRecord(null);
      setUserGroups([]);
      setUserClass("Customer");
    } finally {
      setLoading(false);
    }
  };

  const refreshUserAndPricelist = async (user) => {

    if (!user || !user.attributes) {
      console.error("Invalid user object");
      return;
    }

    const { attributes } = user;

    try {
      const existingUsersResponse = await API.graphql(
        graphqlOperation(listUsers, {
          filter: { email: { eq: attributes.email } },
        })
      );


      const existingUser = existingUsersResponse?.data?.listUsers?.items?.find(
        (userItem) => userItem.email === attributes.email
      );

      if (existingUser) {

        if (existingUser.activePricelistID) {
          await fetchActivePricelistAndProvider(existingUser);
        }

        setUserTheme(existingUser);
        setUserRecord(existingUser);
      } else {
        console.log("No existing user found, might need to create one");
        // You might want to handle user creation here or in App.js
      }
    } catch (error) {
      console.error("Error refreshing user", error);
    }
  };

  const fetchActivePricelistAndProvider = async (existingUser) => {
    console.log("fetchActivePricelistAndProviderINNG", existingUser)
    const activePricelistResponse = await API.graphql(
      graphqlOperation(getPricelist, { id: existingUser.activePricelistID })
    );
    console.log("activePricelist", activePricelistResponse)
    existingUser.activePricelist = activePricelistResponse.data.getPricelist;

    // Set the active provider
    if (existingUser.activePricelist && existingUser.activePricelist.user) {
      setActiveProvider(existingUser.activePricelist.user);
    }

    // Check for user's theme first, then fall back to provider's theme
    let themeOptions = {};
    if (existingUser.themeOptions) {
      themeOptions = JSON.parse(existingUser.themeOptions);
    } else if (existingUser.activePricelist && existingUser.activePricelist.user && existingUser.activePricelist.user.themeOptions) {
      themeOptions = JSON.parse(existingUser.activePricelist.user.themeOptions);
    }

    const newProviderTheme = createTheme({
      palette: {
        primary: {
          main: themeOptions.primary || '#1976d2',
        },
        secondary: {
          main: themeOptions.secondary || '#dc004e',
        },
      },
    });

    setProviderTheme(newProviderTheme);
    localStorage.setItem('providerTheme', JSON.stringify(newProviderTheme));
  };

  const setUserTheme = (existingUser) => {
    if (existingUser.themeOptions) {
      const themeOptions = JSON.parse(existingUser.themeOptions);
      const newCurrentTheme = createTheme({
        palette: {
          primary: {
            main: themeOptions.primary || '#1976d2',
          },
          secondary: {
            main: themeOptions.secondary || '#dc004e',
          },
        },
      });
      setCurrentTheme(newCurrentTheme);
      localStorage.setItem('currentTheme', JSON.stringify(newCurrentTheme));
    }
  };

  useEffect(() => {
    fetchUserInfoAndRefresh();

    const authListener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'signOut':
        case 'tokenRefresh':
          fetchUserInfoAndRefresh();
          break;
        default:
          break;
      }
    });

    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser,
      userRecord, 
      setUserRecord,
      loading, 
      setLoading,
      refreshUserAndPricelist, 
      userGroups, 
      setUserGroups,
      currentTheme, 
      setCurrentTheme,
      providerTheme,
      setProviderTheme,
      companyLogo,
      setCompanyLogo,
      activeProvider,
      setActiveProvider,
      userClass,
      setUserClass
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
