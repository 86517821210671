import * as React from "react";
import { Typography } from "@mui/material/";
import InnerPage from "../components/InnerPage";
import Users from "../components/SuperAdminUsers";

export default function UsersScreen({ user }) {
  return (
    <InnerPage title="Global User View">
      <Users user={user} />
    </InnerPage>
  );
}
