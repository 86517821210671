export const grabMetalPrices = async () => {
  try {
    const response = await fetch(
      "https://second-stud-free-jointly.ngrok-free.app/get-kitco",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );

    // const response = await fetch(
    //   "https://366images.s3.us-east-2.amazonaws.com/metal-prices.json",
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "ngrok-skip-browser-warning": "any",
    //     },
    //   }
    // );

    

    if (!response.ok) {
      throw new Error("Failed to fetch metal prices");
    }

    const data = await response.json();
    console.log("data", data);

    return data;
  } catch (error) {
    console.error(error);
    // Handle the error or throw it to be caught by the caller
    throw error;
  }
};
