import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { listPricelists, listUsers } from "../../graphql/queries";
import {
  Box,
  Button,
  Card,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import { AppContext } from "../../App";
import UserCard from "../user/UserCard";
import PricelistSidebar from "./PricelistSidebar";
import {
  listCognitoUserGroups,
  addUserToCognitoUserGroup,
  removeUserFromCognitoUserGroup,
} from "../../services/user-services";

const CreatePricelist = ({ setStep }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [activeUser, setActiveUser] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [activePricelist, setActivePricelist] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);
  const { userRecord } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const theme = useTheme();

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
    setStep(2);
  };

  const handleClose = () => {
    setShowFeedback(false);
  };

  const popFeedback = (message) => {
    setUserFeedback(message);
    setShowFeedback(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (userRecord) {
        const response = await API.graphql(
          graphqlOperation(listUsers, {
            limit: 10,
            sortDirection: "DESC",
            filter: {
              priceListInvites: {
                contains: userRecord.id,
              },
            },
            include: ["User"],
          })
        );

        const filteredUsers = response.data.listUsers.items || [];
        setUsers(
          Array.isArray(filteredUsers) ? filteredUsers : [filteredUsers]
        );
      } else {
        console.log("no users");
      }
    };

    const fetchGroups = async () => {
      const response = await listCognitoUserGroups("us-east-1_Ijnw6Wgen");
      setGroups(response);
    };

    fetchUsers();
    fetchGroups();
  }, [userRecord]);

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      const response = await API.graphql(
        graphqlOperation(listPricelists, {
          limit: 100,
          sortDirection: "DESC",
          sortField: "createdAt",
        })
      );

      const sortedFiles = response.data.listPricelists.items.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });

      setUploadedFiles(sortedFiles);
    };

    fetchUploadedFiles();
  }, [alert]);

  const assignPricelist = async (pricelist) => {
    const assignToUser = async (user) => {
      const userToUpdate = {
        email: user.email,
        id: user.id,
        activePricelistID: pricelist.id,
      };

      await API.graphql(graphqlOperation(updateUser, { input: userToUpdate }));
    };

    if (activeUser) {
      console.log("assigning pricelist to user", pricelist, activeUser);
      await assignToUser(activeUser);
      popFeedback("Pricelist assigned to user successfully!");
    } else if (activeGroup) {
      console.log("assigning pricelist to group", pricelist, activeGroup);
      const groupMembersResponse = await API.graphql(
        graphqlOperation(listUsers, {
          filter: { groupName: { eq: activeGroup.GroupName } },
        })
      );

      const groupMembers = groupMembersResponse.data.listUsers.items;
      await Promise.all(groupMembers.map(assignToUser));
      popFeedback("Pricelist assigned to group successfully!");
    }
  };

  const handleAssignPricelist = async () => {
    if (activePricelist) {
      await assignPricelist(activePricelist);
    }
  };

  useEffect(() => {
    const fetchUserGroups = async () => {
      const groups = await listCognitoUserGroups("us-east-1_Ijnw6Wgen");
      setUserGroups(groups);
    };

    fetchUserGroups();
  }, [userRecord]);

  if (loading) {
    return (
      <Card variation="elevated" style={{ margin: "40px" }}>
        <Typography>{loadingText}</Typography>
      </Card>
    );
  }

  return (
    <div style={{ margin: "10px" }}>
      <Typography
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "Poppins",
        }}
      >
        {activeUser || activeGroup
          ? "Step 2 - Choose a Pricelist to Assign"
          : "Step 1 - Choose a Customer or Group to Assign a Pricelist"}
      </Typography>

      {users.length === 0 && groups.length === 0 ? (
        <Typography
          variant="h6"
          color="text.primary"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          You do not currently have any customers or groups. Start by adding
          one.
        </Typography>
      ) : (
        <Box sx={{ marginTop: "30px" }}>
          {activeUser ? (
            <UserCard
              key={activeUser.id}
              user={activeUser}
              PrimaryAction={() => (
                <Button onClick={() => setActiveUser(null)}>Deselect</Button>
              )}
            />
          ) : activeGroup ? (
            <UserCard
              key={activeGroup.GroupName}
              user={{
                username: activeGroup.GroupName,
                email: activeGroup.Description,
              }}
              PrimaryAction={() => (
                <Button onClick={() => setActiveGroup(null)}>Deselect</Button>
              )}
            />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
                <Typography
                  variant="h6"
                  sx={{
                    padding: "10px",
                    textAlign: "center",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  Users
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Select</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.username}
                        </TableCell>
                        <TableCell align="right">{user.email}</TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => setActiveUser(user)}
                            color="primary"
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper}>
                <Typography
                  variant="h6"
                  sx={{
                    padding: "10px",
                    textAlign: "center",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  User Groups
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell align="right">Description</TableCell>
                      <TableCell align="right">Select</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groups.map((group) => (
                      <TableRow
                        key={group.GroupName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {group.GroupName}
                        </TableCell>
                        <TableCell align="right">{group.Description}</TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => setActiveGroup(group)}
                            color="primary"
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}

      {activeUser || activeGroup ? (
        <Box sx={{ marginTop: "30px" }}>
          {!activePricelist ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Pricelist Name</TableCell>
                    <TableCell align="right">Created At</TableCell>
                    <TableCell align="right">Parts</TableCell>
                    <TableCell align="right">Uploaded By</TableCell>
                    <TableCell align="right">Select</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.map((file) => (
                    <TableRow
                      key={file.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {file.name}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(file.createdAt).toLocaleDateString()} -{" "}
                        {new Date(file.createdAt).toLocaleTimeString()}
                      </TableCell>
                      <TableCell align="right">{file.validRows}</TableCell>
                      <TableCell align="right">
                        {file?.user?.username}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => {
                            setActivePricelist(file);
                            setSidebarOpen(true);
                          }}
                          color="primary"
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleAssignPricelist}
              >
                Assign Pricelist
              </Button>
            </Box>
          )}
        </Box>
      ) : null}

      <Snackbar
        open={showFeedback}
        autoHideDuration={1000}
        onClose={handleClose}
        message={userFeedback}
      />

      <PricelistSidebar
        pricelist={activePricelist}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
    </div>
  );
};

export default CreatePricelist;
