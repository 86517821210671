import { API, graphqlOperation, Storage } from "aws-amplify";
import { listInvoices } from "../../graphql/queries";

export const fetchInvoices = async () => {
  try {
    const response = await API.graphql(graphqlOperation(listInvoices));

    const invoicesData = response.data.listInvoices.items;
    return invoicesData;
  } catch (error) {
    console.log("Error fetching invoices:", error);
  }
};

export const hasOpenInvoices = (invoices) => {
  return invoices.some((invoice) => invoice.status === "open");
};
