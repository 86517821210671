import React, { useState } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createMappedVIN } from '../graphql/mutations';
import Papa from 'papaparse';
import { Button, Typography, LinearProgress, Box, Alert } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const MappedVINsUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      setProgress(0);
      setError(null);

      try {
        const results = await new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: resolve,
            error: reject,
          });
        });

        const { data } = results;

        console.log(data);
        
        // Filter out empty rows
        const filteredData = data.filter(row => Object.values(row).some(value => value !== ""));
        
        // Upload the CSV data as a JSON file to S3
        const s3Key = `mapped-vins/${uuidv4()}.json`;
        await Storage.put(s3Key, JSON.stringify(filteredData), {
          contentType: 'application/json',
        });

        console.log(filteredData);

        // Create a record of this upload
        await API.graphql(graphqlOperation(createMappedVIN, {
          input: {
            s3Key: s3Key,
            uploadDate: new Date().toISOString(),
            recordCount: filteredData.length,
          }
        }));

        console.log("Upload complete");

        setProgress(100);
        setUploading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        setError('An error occurred while uploading the file. Please try again.');
        setUploading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        textAlign: 'center',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Upload Mapped VINs CSV
      </Typography>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label>
      {uploading && (
        <Box sx={{ width: '80%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2, width: '80%' }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default MappedVINsUpload;