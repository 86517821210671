import AWS from "aws-sdk";
import { PinpointClient, SendMessagesCommand } from "@aws-sdk/client-pinpoint";
import awsconfig from "../aws-exports";
// Configure the AWS SDK
AWS.config.update({
  region: "us-east-1", // e.g. 'us-east-1'
  credentials: new AWS.Credentials(
    "AKIAXPYYCHRAQBSBRCU4",
    "tKXzPyk5ovHXfpEbJ/AeLp3RYb65qbpi8S7LW0va"
  ),
});

// Function to list user groups
export const listCognitoUserGroups = async (
  userPoolId = "us-east-1_Ijnw6Wgen"
) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    UserPoolId: userPoolId,
    // Optional: you can specify Limit and NextToken for pagination
    // Limit: 10,
    // NextToken: 'next-token',
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .listGroups(params)
      .promise();
    console.log("User groups:", result.Groups);
    return result.Groups;
  } catch (error) {
    console.error("Error listing user groups:", error);
    throw error;
  }
};

// Function to create a user group
export const createCognitoUserGroup = async (groupName, userPoolId) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    GroupName: groupName,
    UserPoolId: userPoolId,
    Description: `Group for ${groupName}`,
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .createGroup(params)
      .promise();
    console.log("Group created successfully:", result);
    return result;
  } catch (error) {
    console.error("Error creating group:", error);
    throw error;
  }
};

// Function to add a user to a user group
export const addUserToCognitoUserGroup = async (
  username,
  groupName,
  userPoolId
) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    GroupName: groupName,
    UserPoolId: userPoolId,
    Username: username,
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .adminAddUserToGroup(params)
      .promise();
    console.log("User added to group successfully:", result);
    return result;
  } catch (error) {
    console.error("Error adding user to group:", error);
    throw error;
  }
};

// Function to list user groups for a specific user
export const listUserGroupsForUser = async (username, userPoolId) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    Username: username,
    UserPoolId: userPoolId,
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .adminListGroupsForUser(params)
      .promise();
    console.log("User's groups:", result.Groups);
    return result.Groups;
  } catch (error) {
    console.error("Error listing user's groups:", error);
    throw error;
  }
};

// Function to delete a user group
export const deleteCognitoUserGroup = async (groupName, userPoolId) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    GroupName: groupName,
    UserPoolId: userPoolId,
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .deleteGroup(params)
      .promise();
    console.log("Group deleted successfully:", result);
    return result;
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};

// Function to remove a user from a user group
export const removeUserFromCognitoUserGroup = async (
  username,
  groupName,
  userPoolId
) => {
  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const params = {
    GroupName: groupName,
    UserPoolId: userPoolId,
    Username: username,
  };

  try {
    const result = await cognitoIdentityServiceProvider
      .adminRemoveUserFromGroup(params)
      .promise();
    console.log("User removed from group successfully:", result);
    return result;
  } catch (error) {
    console.error("Error removing user from group:", error);
    throw error;
  }
};

export const sendWelcomeMessages = async (email, phoneNumber) => {
  const pinpointClient = new PinpointClient({ region: "us-east-1" });

  const params = {
    ApplicationId: "5ebbbcb33111488d975a6c493ee663ad",
    MessageRequest: {
      Addresses: {
        [email]: {
          ChannelType: "EMAIL",
        },
        // [phoneNumber]: {
        //   ChannelType: "SMS",
        // },
      },
      MessageConfiguration: {
        EmailMessage: {
          FromAddress: "noreply@yourdomain.com",
          SimpleEmail: {
            Subject: {
              Charset: "UTF-8",
              Data: "Welcome to Catalyst & Core",
            },
            HtmlPart: {
              Charset: "UTF-8",
              Data: "<h1>Welcome to Catalyst & Core!</h1><p>our account has been successfully created, and you are currently subscribed to pricing information provided by your buyer. <br/><br/> To complete your registration, please click the link and use the code provided below to access your account and create a new password. <br/> <br/> https://www.catalystandcore.com/roamrecycling <br/><br/> If you have questions or need any assistance, feel free to reach out to our support team at [support email]. Thanks for joining the Catalyst & Core platform!<br/><br/>Best regards,<br/>The Catalyst & Core Team</p>",
            },
          },
        },
        // SMSMessage: {
        //   Body: "Welcome to Catalyst & Core! Thank you for registering.",
        //   MessageType: "TRANSACTIONAL",
        // },
      },
    },
  };

  try {
    const command = new SendMessagesCommand(params);
    await pinpointClient.send(command);
    console.log("Welcome messages sent successfully!");
  } catch (error) {
    console.error("Error sending welcome messages", error);
  }
};
