import React, { useState, useEffect } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SimpleTable from "../SimpleTable";
import VehicleCard from "../VehicleCard";
import PartCard from "../PartCard";
import BarcodeIcon from "@mui/icons-material/QrCode";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { fetchLatestCSV } from "./services";
import { Skeleton } from "antd";
import { useUser } from "../../UserContext";
import VehicleDialog from './components/VehicleDialog';
import SearchCounter from './components/SearchCounter';
import { useSearchCounter } from '../../hooks/useSearchCounter';
import SearchProgressModal from './components/SearchProgressModal';

const SearchPricelist = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [searchedPart, setSearchedPart] = useState(null);
  const [vinNumber, setVinNumber] = useState("1GNDT33SX92133638");
  const [nhtsaNormal, setnhtsaNormal] = useState(null);
  const [nhtsaExtended, setnhtsaExtended] = useState(null);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [brownResult, setBrownResult] = useState(null);
  const [polkResult, setPolkResult] = useState(null);
  const [pricelistResult, setPricelistResult] = useState(null);
  const [truthSource, setTruthSource] = useState(null);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minMLPrice, setMinMLPrice] = useState(0);
  const [maxMLPrice, setMaxMLPrice] = useState(0);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [converters, setConverters] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [car, setCar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { userRecord, refreshUser } = useUser();
  const {
    searchesRemaining,
    decrementSearchesRemaining,
    openModal: searchCounterOpenModal,
    setOpenModal: setSearchCounterOpenModal,
    handleGetMoreSearches,
  } = useSearchCounter();
  const theme = useTheme();

  const handleScan = (result) => {
    setVinNumber(result);
    setShowScanner(false);
  };

  useEffect(() => {
    if (userRecord) {
      fetchLatestCSV(userRecord);
    }
  }, [userRecord]);

  useEffect(() => {
    if (searchTerm) {
      let results = csvData.filter(
        (item) => item["PART NUMBER"] === searchTerm
      );

      results = results.slice(0, 20);

      let newPart = null;

      if (results.length > 0) {
        newPart = {
          price: results[0]["PRICES"],
          partNumber: results[0]["PART NUMBER"],
        };
      }
      setSearchedPart(newPart);
    } else {
      setSearchedPart(null);
    }
  }, [searchTerm]);

  const resetSearches = () => {
    setnhtsaExtended([]);
    setnhtsaNormal([]);
    setBrownResult([]);
  };

  const testNewAPI = async () => {
    if (searchesRemaining === 0) {
      setSearchCounterOpenModal(true);
      return;
    }

    setIsSearching(true);
    setIsLoading(true);
    try {
      const session = await Auth.currentSession();
      const token2 = session.getIdToken().getJwtToken();

      const config = {
        method: "POST",
        headers: {
          Authorization: `${token2}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vin: vinNumber,
        }),
      };

      const response = await fetch(
        "https://1un9dupmd6.execute-api.us-east-1.amazonaws.com/dev/predict",
        config
      );

      const data = await response.json();
      const apiInfo = JSON.parse(data.body);

      if (apiInfo) {
        const carToSet = {
          Make: apiInfo.vehicle.Make,
          Model: apiInfo.vehicle.Model,
          Year: apiInfo.vehicle.Year,
          Engine: apiInfo.vehicle["Displacement (L)"],
          CC: parseFloat(apiInfo.vehicle["Displacement (CC)"]),
          Cylinders: parseInt(apiInfo.vehicle["Engine Number of Cylinders"]),
          "Number of Converters": apiInfo.converters,
          priceRange: apiInfo.priceRange,
        };

        setVehicleInfo(apiInfo.vehicle);
        setCar(carToSet);
        setOpenResultModal(true);
        setVinNumber('');
        await decrementSearchesRemaining();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
    setIsSearching(false);
  };

  const handleNewSearch = () => {
    setOpenResultModal(false);
    setCar(null);
    setVehicleInfo(null);
  };

  const vehicleInfoArrayed = [];

  if (vehicleInfo) {
    for (const [key, value] of Object.entries(vehicleInfo)) {
      if (value) {
        vehicleInfoArrayed.push([key, value]);
      }
    }
  }

  return (
    <Stack sx={{ marginLeft: "20px", marginRight: "20px" }}>
      <Typography
        variant={"h5"}
        sx={{
          textAlign: "center",
          fontFamily: "Poppins",
          fontWeight: 600,
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        {make} {model} {year}
      </Typography>

      <SearchCounter
        searchesRemaining={searchesRemaining}
        onGetMoreSearches={handleGetMoreSearches}
      />

      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Search VIN</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          onChange={(evt) => setVinNumber(evt.target.value)}
          value={vinNumber}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle vin scanner"
                onClick={() => setShowScanner(true)}
                onMouseDown={() => setShowScanner(true)}
                edge="end"
              >
                <BarcodeIcon />
              </IconButton>
            </InputAdornment>
          }
          label="VIN Number"
        />
      </FormControl>
      <Button
        variant="outlined"
        onClick={testNewAPI}
        sx={{ marginTop: "20px", width: "100%" }}
      >
        Search VIN
      </Button>

      {isLoading && <Skeleton />}

      <VehicleDialog
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        car={car}
        vehicleInfo={vehicleInfo}
        onNewSearch={handleNewSearch}
      />

      <Dialog
        open={searchCounterOpenModal}
        onClose={() => setSearchCounterOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 40, marginRight: 2 }} />
            {"Out of Free Searches"}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have run out of free searches for this month. Please contact us to get more searches or upgrade to a paid plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSearchCounterOpenModal(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => window.location.href = "/contact"} color="secondary" autoFocus>
            Contact Us
          </Button>
        </DialogActions>
      </Dialog>

      <SearchProgressModal open={isSearching} />

    </Stack>
  );
};

export default SearchPricelist;
