import * as React from "react";
import Typography from "@mui/material/Typography";
import News from "../components/News";
import NewsEntryForm from "../components/NewsEntryForm";
import InnerPage from "../components/InnerPage";

export default function NewsScreen() {
  return (
    <InnerPage>
      <News format="column" />
      <NewsEntryForm />
    </InnerPage>
  );
}
