import * as React from "react";
import { Stack, Box, Typography, Alert } from "@mui/material";

export default function InnerPage(props) {
  return (
    <Stack
      sx={{
        backgroundColor: "black",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        // width: "100%",

        marginTop: "15px",
        marginLeft: 0,
        // marginLeft: "180px",
        marginLeft: {
          lg: "240px",
          sm: 0,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 800,
          color: "white",
          marginTop: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          marginTop: "0",
          backgroundColor: "white",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          minHeight: "100vh",
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
}
