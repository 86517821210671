import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";

const Invoices = ({ arr }) => {
  // Updated the function parameter
  if (!arr || arr.length === 0) {
    return null;
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Property</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arr.map(
              (
                item,
                index // Iterate over the name-value pairs
              ) => (
                <>
                  {item[1] && (
                    <TableRow key={index}>
                      <TableCell>{item[0]}</TableCell> {/* Property Name */}
                      <TableCell>{item[1]}</TableCell> {/* Property Value */}
                    </TableRow>
                  )}
                </>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Invoices;
