import * as React from "react";
import LiteDashboard from "../components/LiteDashboard";
import InnerPage from "../components/InnerPage";

export default function DashboardScreen({ user }) {
  return (
    <InnerPage title="Your Dashboard">
      <LiteDashboard user={user} />
    </InnerPage>
  );
}
