import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createPricelist, deletePricelist } from "../../graphql/mutations";
import { listPricelists } from "../../graphql/queries";
import {
  Input,
  IconButton,
  Card,
  Box,
  Typography,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Paper,
  TablePagination,
  Alert,
  Container,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PricelistStep2 from "./PricelistStep2";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

const CSVUpload = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const { user, userRecord } = useContext(AppContext);
  // console.log("User in:", userRecord);

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const parseCSV = () => {
    let validRows = 0;
    let droppedRows = 0;
    let droppedRowIndices = [];

    Papa.parse(csvFile, {
      header: false,
      complete: (results) => {
        const mappedData = results.data.slice(1).map((row) => ({
          "PART NUMBER": row[0],
          PRICE: row[1] === "Do Not Buy" ? 0 : parseFloat(row[1]),
          IMAGE1: row[2],
          IMAGE2: row[3],
        }));

        mappedData.forEach((row, index) => {
          if (isNaN(row.PRICE)) {
            droppedRows += 1;
            droppedRowIndices.push(index + 2); // +2 accounts for header and 1-indexed CSV
          } else {
            validRows += 1;
          }
        });

        setCsvData(mappedData);
        setAlert({
          validRows,
          droppedRows,
          droppedRowIndices,
        });
        setLoadingText(`Uploading ${validRows} parts`);
      },
    });
  };

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      if (!userRecord || !userRecord.id) {
        console.log("User record not available yet");
        return;
      }

      try {
        const response = await API.graphql(
          graphqlOperation(listPricelists, {
            limit: 10, // fetch 10 files
            filter: {
              userId: {
                eq: userRecord.id,
              },
            },
            sortDirection: "DESC", // sort by createdAt in descending order
            sortField: "id", // sort by createdAt field
          })
        );

        console.log("Uploaded files:", response.data.listPricelists.items);

        setUploadedFiles(response.data.listPricelists.items);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    };

    fetchUploadedFiles();
  }, [userRecord]);

  const handleUpload = async (file, adjustments) => {
    console.log("Uploading file:", file, adjustments);
    // {
    //   filename: basePriceList,
    //   name: priceListName,
    //   notes: notes,
    // },
    // {
    //   percentageAdjustment: percentageAdjustment,
    //   dollarAdjustment: dollarAdjustment,
    // }

    setLoading(true);

    // Upload the CSV data as a JSON file to S3
    const s3Key = `csv-data/${uuidv4()}.json`;

    const csvToUpload = [...csvData];

    if (!adjustments.percentageAdjustment) {
      adjustments.percentageAdjustment = 100;
    }

    if (!adjustments.dollarAdjustment) {
      adjustments.dollarAdjustment = 0;
    }

    if (
      adjustments.percentageAdjustment === 100 &&
      adjustments.dollarAdjustment === 0
    ) {
      csvData.forEach((row, index) => {
        row.adjustedPrice = row.PRICE;
      });
    } else {
      csvData.forEach((row, index) => {
        row.adjustedPrice =
          parseFloat(
            row.PRICE -
              row.PRICE * (Math.abs(adjustments.percentageAdjustment) / 100)
          ) + adjustments.dollarAdjustment;
      });
    }

    console.log("uploading", csvToUpload);

    await Storage.put(s3Key, JSON.stringify(csvToUpload), {
      contentType: "application/json",
    });

    // Create a new Pricelist item
    const pricelist = {
      validRows: alert.validRows,
      s3Key: s3Key,
      userId: userRecord.id,
      adjustments: JSON.stringify(adjustments),
      filename: file.filename,
      name: file.name,
      notes: file.notes,
    };

    console.log("Submitting pricelist:", pricelist);

    await API.graphql(graphqlOperation(createPricelist, { input: pricelist }));

    navigate("/manage-pricelists");

    setAlert(null);
    setCsvFile(null);
    setCsvData([]);
    setLoading(false);
    setLoadingText("");
    setShowFeedback(true);

    setFeedbackMessage("Pricelist uploaded successfully!");
  };

  const handleDelete = async (id) => {
    try {
      await API.graphql(graphqlOperation(deletePricelist, { input: { id } }));
      setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
      setShowFeedback(true);
      setFeedbackMessage("Pricelist deleted successfully!");
    } catch (error) {
      console.error("Error deleting pricelist:", error);
      setShowFeedback(true);
      setFeedbackMessage("Error deleting pricelist.");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Card elevation={3} sx={{ p: 4, textAlign: "center" }}>
          <Typography variant="h5" gutterBottom>
            {loadingText}
          </Typography>
          <Skeleton variant="rectangular" height={200} />
        </Card>
      </Container>
    );
  }

  if (step === 2) {
    return (
      <PricelistStep2
        handleUpload={handleUpload}
        csvFile={csvFile}
        alert={alert}
        setAlert={setAlert}
        parseCSV={parseCSV}
      />
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card elevation={3} sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom align="center">
              Upload Latest Pricelist
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                sx={{ display: "none" }}
                id="csv-file-input"
              />
              <label htmlFor="csv-file-input">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<UploadFileIcon />}
                  sx={{ mb: 2 }}
                >
                  Select CSV File
                </Button>
              </label>
              {csvFile && (
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Selected file: {csvFile.name}
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={parseCSV}
                disabled={!csvFile}
              >
                Parse CSV
              </Button>
            </Box>
          </Card>
        </Grid>

        {alert && (
          <Grid item xs={12}>
            <Alert
              severity="info"
              sx={{
                mt: 2,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <Typography gutterBottom>
                Parsing completed. {alert.validRows} row(s) will be uploaded.{" "}
                {alert.droppedRows} row(s) will be dropped.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Dropped row numbers: {alert.droppedRowIndices.join(", ")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button variant="contained" onClick={() => setStep(2)}>
                  Continue with Upload
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAlert(null);
                    setCsvFile(null);
                  }}
                >
                  Cancel Upload
                </Button>
              </Box>
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card elevation={3} sx={{ mt: 4 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Parts</TableCell>
                    <TableCell>Offsets</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((file) => {
                      const parsedAdjustments = JSON.parse(file.adjustments);
                      const percentageAdjustment =
                        parsedAdjustments.percentageAdjustment;
                      const dollarAdjustment = parsedAdjustments.dollarAdjustment;
                      return (
                        <TableRow key={file.id}>
                          <TableCell>{file.name}</TableCell>
                          <TableCell>
                            {new Date(file.createdAt).toLocaleString()}
                          </TableCell>
                          <TableCell>{file.validRows}</TableCell>
                          <TableCell>
                            {percentageAdjustment ? `${percentageAdjustment}%` : ""}
                            {percentageAdjustment && dollarAdjustment ? " and " : ""}
                            {dollarAdjustment ? `$${dollarAdjustment}` : ""}
                            {!percentageAdjustment && !dollarAdjustment ? "None" : ""}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDelete(file.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={uploadedFiles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={showFeedback}
        autoHideDuration={3000}
        onClose={() => setShowFeedback(false)}
        message={feedbackMessage}
      />
    </Container>
  );
};

export default CSVUpload;
