import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LiteDashboardScreen from '../pages/LiteDashboard';
import SearchPricelistScreen from '../pages/pricelists/SearchPricelist';
import ContactScreen from '../pages/Contact';
import SettingsScreen from '../pages/SettingsScreen';
import PricelistsMenuScreen from '../pages/pricelists/PricelistsMenu';
import PricelistGroupsScreen from '../pages/pricelists/PricelistGroups';
import CreatePricelistScreen from '../pages/pricelists/CreatePricelist';
import UploadPricelistScreen from '../pages/pricelists/UploadPricelist';
import AssignPricelistScreen from '../pages/pricelists/AssignPricelist';
import NewsScreen from '../pages/News';
import InvoicesScreen from '../pages/Invoices';
import ViewInvoiceScreen from '../pages/ViewInvoice';
import UsersScreen from '../pages/Users';
import UserGroupsScreen from '../pages/UserGroupManager';
import ViewPricelistScreen from '../pages/ViewPricelists';
import EmailInviteScreen from '../pages/EmailInvite';
import ManagePricelistScreen from '../pages/pricelists/PricelistsMenu';
import SuperAdminUsersScreen from '../pages/SuperAdminUsers';
// Import other screens as needed

const ProviderRoutes = () => {
  console.log("ProviderRoutes rendered");
  return (
    <Routes>
      <Route path="/" element={<LiteDashboardScreen />} />
      <Route path="search-pricelist" element={<SearchPricelistScreen />} />
      <Route path="contact" element={<ContactScreen />} />
      <Route path="settings" element={<SettingsScreen />} />
      <Route path="pricelists" element={<PricelistsMenuScreen />} />
      <Route path="pricelist-groups" element={<PricelistGroupsScreen />} />
      <Route path="create-pricelist" element={<CreatePricelistScreen />} />
      <Route path="upload-pricelist" element={<UploadPricelistScreen />} />
      <Route path="assign-pricelist" element={<AssignPricelistScreen />} />
      <Route path="news" element={<NewsScreen />} />
      <Route path="invoices" element={<InvoicesScreen />} />
      <Route path="view-invoice/:invoiceId" element={<ViewInvoiceScreen />} />
      <Route path="users" element={<UsersScreen />} />
      <Route path="user-group-manager" element={<UserGroupsScreen />} />
      <Route path="view-pricelists" element={<ViewPricelistScreen />} />
      <Route path="email-invite" element={<EmailInviteScreen />} />
      <Route path="manage-pricelists" element={<ManagePricelistScreen />} />
      <Route path="super-admin-users" element={<SuperAdminUsersScreen />} />
      {/* Add other routes as needed */}
      {/* For example:
      <Route path="analytics" element={<AnalyticsScreen />} />
      <Route path="users" element={<UsersScreen />} />
      */}
    </Routes>
  );
};

export default ProviderRoutes;

