import * as React from "react";
import { Typography } from "@mui/material/";
import InnerPage from "../components/InnerPage";
import UserGroupManager from "../components/UserGroupManager";

export default function UsersScreen({ user }) {
  return (
    <InnerPage title="Customers">
      <UserGroupManager user={user} />
    </InnerPage>
  );
}
