import React, { useState, useEffect, useContext } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Grid,
  List,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Stack,
  Badge,
  Fab,
  TextField,
  MenuItem,
} from "@mui/material";

import { listInvoices } from "../graphql/queries";
import { createInvoice, deleteInvoice } from "../graphql/mutations";
import FolderIcon from "@mui/icons-material/Folder";
import InvoiceIcon from "@mui/icons-material/Receipt";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { AppContext } from "../App"; // Import the AppContext from App.js

import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import OurLoader from "./OurLoader";
import { useUser } from "../UserContext";

const listUsersCustom = `
  query ListUsers(
    $limit: Int,
    $filter: ModelUserFilterInput
  ) {
    listUsers(limit: $limit, filter: $filter) {
      items {
        id
        email
        companyName
      }
    }
  }
`;

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [hasOpenInvoices, setHasOpenInvoices] = useState(false);
  const { userRecord } = useUser(); // Get the user from the AppContext
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoiceName, setInvoiceName] = useState('');
  const [customers, setCustomers] = useState([]);

  const StyledBadge = styled(Badge)(({ theme, status }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: status === "open" ? "#44b700" : "#f50057",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const handleDeleteInvoice = async (id) => {
    try {
      const input = {
        id: id,
      };

      console.log("deleting", id);

      const response = await API.graphql(
        graphqlOperation(deleteInvoice, { input })
      );

      console.log("delete response", response);

      console.log("Invoice deleted");
      fetchInvoices();
    } catch (error) {
      console.log("Error deleting invoice", error);
    }
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(listInvoices));
      setLoading(false);
      console.log("response ", response);
      const invoicesData = response.data.listInvoices.items;
      if (invoicesData) {
        setInvoices(invoicesData);
      }
      const openInvoices = invoicesData.filter(
        (invoice) => invoice.status === "open"
      );
      setHasOpenInvoices(openInvoices.length > 0);
    } catch (error) {
      console.log("Error fetching invoices:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listUsersCustom, {
          limit: 10,
          filter: {
            priceListInvites: {
              contains: userRecord.id,
            },
          },
        })
      );
      setCustomers(response.data.listUsers.items || []);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleCreateInvoice = async () => {
    try {
      const input = {
        dateSent: selectedDate.toISOString(),
        ownerID: userRecord.id,
        recipientID: selectedCustomer,
        name: invoiceName,
        status: 'open',
        partCount: 0
      };

      await API.graphql(graphqlOperation(createInvoice, { input }));
      setOpenDialog(false);
      fetchInvoices();
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [userRecord]);

  const getRelativeLink = (path) => {
    if (userRecord) {
      return `/${userRecord.companyPath}${path}`;
    }
    return path;
  };

  if (loading) {
    return <OurLoader />;
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{ mt: 4, mb: 2, ml: 2, fontFamily: "Poppins", textAlign: "center" }}
        variant="h6"
        component="div"
      >
        Invoice List
      </Typography>

      <Typography
        sx={{
          mb: 2,
          ml: 2,
          fontFamily: "Poppins",
          textAlign: "center",
          color: "#aaa",
        }}
        variant="p"
        component="div"
      >
        Tap the invoice icon to view details
      </Typography>

      <div style={{ margin: "20px" }}>
        <Divider flexItem variant="middle" />
      </div>

      <List dense={true}>
        {invoices.length === 0 ? (
          <Typography
            sx={{
              mt: 4,
              mb: 2,
              ml: 2,
              fontFamily: "Poppins",
              textAlign: "center",
            }}
            variant="body1"
            component="div"
          >
            No open invoices found.
          </Typography>
        ) : (
          invoices.map((invoice, i) => (
            <ListItem
              key={i}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteInvoice(invoice.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar
                onClick={() => {
                  navigate(getRelativeLink(`/view-invoice/${invoice.id}`));
                }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  status={invoice.status}
                >
                  <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                    <InvoiceIcon />
                  </Avatar>
                </StyledBadge>
              </ListItemAvatar>
              {/* <p>{JSON.stringify(invoice, null, 2)}</p> */}
              <Stack spacing={0}>
                <Typography
                  sx={{
                    // mb: 2,
                    // ml: 2,
                    ml: 0,
                    fontFamily: "Poppins",
                    // textAlign: "center",
                    color: "#aaa",
                    fontSize: "10px",
                  }}
                  variant="h6"
                  component="div"
                >
                  Date: {new Date(invoice.createdAt).toDateString()}
                </Typography>
                <ListItemText
                  primary={`${invoice.name} - ${invoice.recipient?.companyName}`}
                  secondary={`${
                    invoice.partCount ? invoice.partCount : 0
                  } Parts`}
                />
              </Stack>
            </ListItem>
          ))
        )}
      </List>

      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        onClick={() => setOpenDialog(true)}
      >
        <AddIcon />
      </Fab>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Invoice</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2, minWidth: '300px' }}>
            <TextField
              label="Invoice Name"
              value={invoiceName}
              onChange={(e) => setInvoiceName(e.target.value)}
              fullWidth
            />
            
            <TextField
              type="datetime-local"
              label="Invoice Date"
              value={selectedDate.toISOString().slice(0, 16)}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              select
              label="Customer"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
              fullWidth
            >
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.companyName || customer.email}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateInvoice}
            disabled={!selectedCustomer || !invoiceName}
            variant="contained"
          >
            Create Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );

};

export default Invoices;
