import * as React from "react";
import PricingMenu from "../../components/pricing/PricingMenu";
import Typography from "@mui/material/Typography";
import InnerPage from "../../components/InnerPage";

export default function PricelistsScreen({ user }) {
  return (
    <InnerPage title="Manage Pricelists">
      <PricingMenu user={user} />
    </InnerPage>
  );
}
