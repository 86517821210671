import React from 'react';
import ViewInvoice from '../components/ViewInvoice';
import InnerPage from '../components/InnerPage';

const ViewInvoiceScreen = () => {
  return (
    <InnerPage title="View Invoice">
      <ViewInvoice />
    </InnerPage>
  );
};

export default ViewInvoiceScreen;
