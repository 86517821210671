import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Snackbar,
  Stack,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateUser, createUser } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";

const validationSchema = yup.object({
  contactName: yup.string("Enter contact name").required("Contact name is required"),
  email: yup.string("Enter email").email("Enter a valid email").required("Email is required"),
  cellNumber: yup.string("Enter cell number").required("Cell number is required"),
  companyName: yup.string("Enter company name"),
  companyPath: yup.string("Enter company path"),
  city: yup.string("Enter city name"),
  address: yup.string("Enter address"),
  state: yup.string("Enter state"),
  zip: yup.string("Enter zip"),
  phoneNumber: yup.string("Enter phone number"),
});

const UserForm = ({ onComplete }) => {
  const { user, userRecord, refreshUserAndPricelist } = useUser();
  console.log("userRecord", userRecord);
  
  const [showFeedback, setShowFeedback] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  const deleteUser = async () => {
    try {
      if (!userRecord) {
        setSnackbarMessage("User not found.");
        setShowFeedback(true);
        return;
      }

      const updatedUser = {
        id: userRecord.id,
        createdByUserId: "deleted",
        priceListInvites: [],
      };

      await API.graphql(graphqlOperation(updateUser, { input: updatedUser }));
      setSnackbarMessage("User deleted successfully!");
      setShowFeedback(true);
    } catch (error) {
      console.error("Error deleting user:", error);
      setSnackbarMessage("Error deleting user.");
      setShowFeedback(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      contactName: "",
      email: "",
      cellNumber: "",
      companyName: "",
      companyPath: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("update form submitted", values);
      try {
        const newValues = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== "")
        );

        if (!userRecord) {
          const newUser = {
            ...newValues,
            username: values.email,
            createdByUserId: user.attributes.sub,
          };

          await API.graphql(graphqlOperation(createUser, { input: newUser }));
          setSnackbarMessage("New user created successfully!");
        } else {
          const updatedUser = {
            id: userRecord.id,
            ...newValues,
            createdByUserId: user.attributes.sub,
          };

          await API.graphql(graphqlOperation(updateUser, { input: updatedUser }));
          setSnackbarMessage("User details updated successfully!");
        }
        setShowFeedback(true);
        if (refreshUserAndPricelist) {
          await refreshUserAndPricelist(user);
        }
        if (onComplete) {
          onComplete();
        }
      } catch (error) {
        console.error("Error updating user:", error);
        setSnackbarMessage("Error updating user details.");
        setShowFeedback(true);
      }
    },
  });

  useEffect(() => {
    if (userRecord) {
      formik.setValues({
        contactName: userRecord.contactName || "",
        email: userRecord.email || "",
        cellNumber: userRecord.cellNumber || "",
        companyName: userRecord.companyName || "",
        companyPath: userRecord.companyPath || "",
        address: userRecord.address || "",
        city: userRecord.city || "",
        state: userRecord.state || "",
        zip: userRecord.zip || "",
        phoneNumber: userRecord.phoneNumber || "",
      });
    }
  }, [userRecord]);

  return (
    <Container>
      <Typography variant="h6" gutterBottom sx={{ marginTop: "10px" }}>
          User Details
        </Typography>
      <Stack component="form" onSubmit={formik.handleSubmit} noValidate spacing={2}>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="contactName"
              name="contactName"
              label="Contact Name"
              value={formik.values.contactName}
              onChange={formik.handleChange}
              error={formik.touched.contactName && Boolean(formik.errors.contactName)}
              helperText={formik.touched.contactName && formik.errors.contactName}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Primary Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="cellNumber"
              name="cellNumber"
              label="Primary Phone (Cell)"
              value={formik.values.cellNumber}
              onChange={formik.handleChange}
              error={formik.touched.cellNumber && Boolean(formik.errors.cellNumber)}
              helperText={formik.touched.cellNumber && formik.errors.cellNumber}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName}
              margin="normal"
            />
          </Grid>
         
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address"
              name="address"
              label="Street Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="zip"
              name="zip"
              label="Zip Code"
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone (Business)"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              margin="normal"
            />
          </Grid>
        </Grid>

        <Stack spacing={2} direction="row" sx={{ mt: 4 }}>
          <Button variant="outlined" onClick={() => navigate("/")} sx={{ mt: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={deleteUser}>
            Delete User
          </Button>
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            Save User Details
          </Button>
        </Stack>

        <Snackbar
          open={showFeedback}
          autoHideDuration={3000}
          onClose={() => setShowFeedback(false)}
          message={snackbarMessage}
        />
      </Stack>
    </Container>
  );
};

export default UserForm;