import React, { useState, useEffect, useRef } from "react";

import {
  Stack,
  Box,
  TextareaAutosize,
  TextField,
  Typography,
  Slider,
  Divider,
  Button,
  Alert,
  useTheme,
} from "@mui/material";
import PartCard from "../PartCard";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const CreatePricelist = ({
  handleUpload,
  csvFile,
  alert,
  setAlert,
  parseCSV,
}) => {
  const [priceListName, setPriceListName] = useState(
    csvFile ? csvFile.name : null
  );
  const [basePriceList, setBasePriceList] = useState(
    csvFile ? csvFile.name : null
  );
  const [notes, setNotes] = useState("");
  const [percentageAdjustment, setPercentageAdjustment] = useState(0);
  const [dollarAdjustment, setDollarAdjustment] = useState(0);

  console.log("got csvFile in step 2", csvFile);

  const samplePart = {
    partNumber: "Example Part 123",
    minPrice: 100,
    maxPrice: 100,
    image1: "noimage.jpg",
  };

  const theme = useTheme();

  return (
    <div style={{ margin: "40px" }}>
      <Typography
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "Poppins",
        }}
      >
        Step 2 - Name Your New Price List and Add Your Profit Margin
      </Typography>

      <Stack spacing={2} sx={{ marginTop: "30px" }}>
        <TextField
          label="New Price List Name"
          placeholder="Enter the name of your new price list"
          value={priceListName}
          // disabled={true}
          onChange={(e) => setPriceListName(e.target.value)}
        />
        <TextField
          label="Original Filename"
          placeholder="Original Filename"
          value={basePriceList}
          disabled={true}
          onChange={(e) => setBasePriceList(e.target.value)}
        />

        <TextField
          label="Notes"
          placeholder="Enter any notes or additional information"
          multiline={true}
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />

        <Stack
          direction="row"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              borderRadius: "10px",
              padding: "10px",
              textAlign: "center",
              position: "absolute",
              top: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "200px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Price Before Adjustment
            </Typography>
          </Box>
          <PartCard part={samplePart} />
          <ArrowRightIcon sx={{ fontSize: "50px" }} />
          <Box
            sx={{
              backgroundColor: "black",
              borderRadius: "10px",
              padding: "10px",
              textAlign: "center",
              position: "absolute",
              top: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "200px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Price After Adjustment
            </Typography>
          </Box>
          <PartCard
            part={{
              ...samplePart,
              minPrice:
                samplePart.minPrice * (1 + percentageAdjustment / 100) +
                dollarAdjustment,
              maxPrice:
                samplePart.maxPrice * (1 + percentageAdjustment / 100) +
                dollarAdjustment,
            }}
          />
        </Stack>

        <Box
          sx={{
            backgroundColor: "black",
            borderRadius: "10px",
            padding: "10px",
            textAlign: "center", // Center the note
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Percentage Adjustment {percentageAdjustment}%
          </Typography>
          <Divider sx={{ backgroundColor: "white" }} />
          <Slider
            value={percentageAdjustment}
            onChange={(e, value) => setPercentageAdjustment(value)}
            min={-100}
            max={0}
            step={1}
            color="secondary"
          />
          <Divider sx={{ backgroundColor: "white" }} />
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold", // Make the note bold
            }}
          >
            Slide to adjust price by a percentage value. (i.e. if you adjust the
            price by 10% then move the slider to -10%)
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "black",
            borderRadius: "10px",
            padding: "10px",
            textAlign: "center", // Center the note
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Dollar Adjustment ${dollarAdjustment}
          </Typography>
          <Divider sx={{ backgroundColor: "white" }} />
          <Slider
            value={dollarAdjustment}
            onChange={(e, value) => setDollarAdjustment(value)}
            min={-100}
            max={0}
            step={1}
            color="secondary"
          />
          <Divider sx={{ backgroundColor: "white" }} />
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold", // Make the note bold
            }}
          >
            Slide to adjust price by a dollar value. (i.e. if you adjust the
            price by $10 then move the slider to $-10)
          </Typography>
        </Box>

        {alert ? (
          <div>
            <Stack status="error" style={{ margin: "10px" }}>
              <h2 style={{ textAlign: "center" }}>Are you ready to upload?</h2>
              <h3 style={{ textAlign: "center" }}>
                {alert.validRows} part(s) will be uploaded.
                {alert.droppedRows} part(s) will be dropped. Row numbers:{" "}
                {alert.droppedRowIndices.join(", ")}
              </h3>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{ margin: "10px" }}
                  color="warning"
                  variant="contained"
                  onClick={() => setAlert(null)}
                >
                  CANCEL
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  variant="contained"
                  onClick={() =>
                    handleUpload(
                      {
                        filename: basePriceList,
                        name: priceListName,
                        notes: notes,
                      },
                      {
                        percentageAdjustment: percentageAdjustment,
                        dollarAdjustment: dollarAdjustment,
                      }
                    )
                  }
                >
                  UPLOAD
                </Button>
              </Stack>
            </Stack>
          </div>
        ) : (
          <Button
            onClick={parseCSV}
            style={{ margin: "10px" }}
            variant="contained"
          >
            Confirm and Assign
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default CreatePricelist;
