import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
} from '@mui/material';
import SimpleTable from '../../SimpleTable';
import VehicleCard from '../../VehicleCard';

const VehicleDialog = ({ open, onClose, car, vehicleInfo, onNewSearch }) => {
  const vehicleInfoArrayed = Object.entries(vehicleInfo || {}).filter(([_, value]) => value);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Vehicle Information</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {car && (
            <Stack sx={{ justifyContent: "center", alignItems: "center", marginBottom: 2 }}>
              <VehicleCard car={car} />
            </Stack>
          )}
          <SimpleTable arr={vehicleInfoArrayed} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
        <Button onClick={onNewSearch} variant="contained" color="primary">
          New Search
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleDialog;