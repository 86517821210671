import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Fab,
  Divider,
  Box,
  IconButton,
  Typography,
  ButtonGroup,
  FormControl,
  TextField,
  InputAdornment,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Avatar,
  Stack,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../../App"; // Import the AppContext from App.js
import { listUsers } from "../../graphql/queries";
import {
  listUserGroupsForUser,
  listCognitoUserGroups,
  addUserToCognitoUserGroup,
  removeUserFromCognitoUserGroup,
} from "../../services/user-services";
import PricelistSelect from "./PricelistSelect";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { userRecord } = useContext(AppContext); // Get the user from the AppContext

  // Define the custom GraphQL operation
  const listUsersCustom = `
  query ListUsers(
    $limit: Int,
    $filter: ModelUserFilterInput
  ) {
    listUsers(limit: $limit, filter: $filter) {
      items {
        id
        username
        email
        activePricelistID
        activePricelist {
          id
          name
        }
      }
    }
  }
`;

  console.log("users", users);
  useEffect(() => {
    const fetchUsers = async () => {
      if (userRecord) {
        const response = await API.graphql(
          graphqlOperation(listUsersCustom, {
            limit: 10,
            filter: {
              priceListInvites: {
                contains: userRecord.id,
              },
            },
          })
        );

        const fetchedUsers = response.data.listUsers.items || [];
        console.log("fetchedUsers", fetchedUsers);

        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } else {
        console.log("no users");
      }
    };

    const fetchUserGroups = async () => {
      // const groups = await listCognitoUserGroups("us-east-1_Ijnw6Wgen");
      // setUserGroups(groups);
    };

    fetchUsers();
    fetchUserGroups();
  }, [userRecord]);

  useEffect(() => {
    const filterUsers = () => {
      let filtered = users;

      if (search) {
        filtered = filtered.filter((user) =>
          Object.values(user).some((value) =>
            value?.toString().toLowerCase().includes(search.toLowerCase())
          )
        );
      }

      if (selectedGroup) {
        filtered = filtered.filter((user) =>
          user.groups.includes(selectedGroup)
        );
      }

      setFilteredUsers(filtered);
    };

    filterUsers();
  }, [search, selectedGroup, users]);

  return (
    <div style={{ margin: "10px", marginTop: "20px" }}>
      <Divider />
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#000",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          display: "flex",
          marginLeft: "10px",
        }}
      >
        <FormControl variant="outlined">
          <TextField
            id="filled-basic"
            label="Search"
            placeholder="Search Users"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "30px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      color: "black",
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: "50%",
                      padding: "11px",
                      left: "2px",
                    }}
                    edge="end"
                  >
                    <SearchIcon
                      sx={{
                        fontSize: "35px",
                        color: theme.palette.primary.secondary,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Divider />
      <Typography
        variant="h6"
        color="text.primary"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        Filter users with the buttons below.
      </Typography>
      <ButtonGroup
        color="primary"
        aria-label="user groups"
        sx={{ paddingTop: "20px", display: "flex", justifyContent: "center" }}
      >
        {userGroups.map((group) => (
          <Button
            key={group.GroupName}
            variant={
              selectedGroup === group.GroupName ? "contained" : "outlined"
            }
            onClick={() => setSelectedGroup(group.GroupName)}
          >
            {group.GroupName}
          </Button>
        ))}
      </ButtonGroup>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ color: theme.palette.secondary.main }}
          disabled={!selectedGroup}
          variant="text"
          onClick={() => setSelectedGroup(null)}
        >
          Remove Filters
        </Button>
      </Stack>
      <Divider sx={{ margin: "20px" }} />

      {filteredUsers.length === 0 ? (
        <Typography
          variant="h6"
          color="text.primary"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          You do not currently have any customers of this type. Start by adding
          one.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="right">Name</TableCell>

                <TableCell align="right">Active Pricelist</TableCell>
                <TableCell align="right">Second Pricelist</TableCell>
                <TableCell align="right">User Groups</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow
                  key={user.username}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user.email}</TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {user.username}
                  </TableCell>
                  <TableCell align="right">
                    {user.activePricelist?.name ? (
                      <PricelistSelect
                        userID={user.id}
                        variation="small"
                        pricelistID={user.activePricelistID}
                      />
                    ) : (
                      "User does not have an active pricelist"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {user.secondPricelist?.name || "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {user.groups?.map((group) => (
                      <Chip key={group} label={group} />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Users;
