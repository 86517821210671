export const appConfig = {
  showInvoices: false,
  showCompany: false,
  showMarketNews: false,
  showNotifications: false,
  showAnalytics: false,
  showTrendingUnits: false,
  showCatalystAI: false,
  showCustomers: true,
  showMessages: false,
  companyName: "Roam Recycling",
  companyLogo: "/static/images/roam_recycling.png",
  primaryColor: "#002720",
  secondaryColor: "#ff5d01",
  showMarketData: false,
  version: "lite",
};
