import React from "react";

import {
  Button,
  CardActions,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const UserCard = ({ user, onClick, PrimaryAction }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <div
      style={{ margin: "10px", fontWeight: "bold" }}
      // onClick={
      //   onClick ? onClick : () => navigate(`/user-details/${user.username}`)
      // }
    >
      <Stack>
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#ddd",
            borderRadius: "5px",
            borderColor: theme.palette.primary.main,
            borderWidth: "2px",
            borderStyle: "solid",
          }}
        >
          <CardContent>
            {/* <Typography>{JSON.stringify(user, null, 2)}</Typography> */}
            <Typography
              sx={{ fontSize: 18, fontWeight: "bold" }}
              color="text.secondary"
              gutterBottom
            >
              {user.companyName}
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {user.address}
            </Typography>
            {user.city && (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {user.city}, {user.state} {user.zip}
              </Typography>
            )}
            <Typography
              sx={{
                marginTop: "15px",
                marginBottom: "15px",
                fontWeight: "bold",
              }}
              variant="body2"
            >
              {user.cellNumber}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {user.contactName}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {user.email}
            </Typography>
            {/* {user.activePricelist && ( */}
            {user.activePricelist && (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Active Pricelist: {user.activePricelist.name}
              </Typography>
            )}
            {/* )} */}
            {/* <Typography variant="body2">
              {JSON.stringify(user, null, 2)}
            </Typography> */}
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Button
              size="small"
              onClick={() => navigate(`/user-details/${user.username}`)}
            >
              View Details
            </Button>

            {PrimaryAction && <PrimaryAction />}
          </CardActions>
        </Card>
      </Stack>
    </div>
  );
};

export default UserCard;
