import Papa from "papaparse";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listCSVFiles } from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";


export async function getPrediction(vin) {
  const payload = {
    vin,
  };

  console.log("payload", payload);

  try {
    const response = await fetch(
      "https://1un9dupmd6.execute-api.us-east-1.amazonaws.com/dev/",
      {
        method: "POST",
        // mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    console.log("got raw response", response);

    const data = await response.json();

    return data;
  } catch (err) {
    console.log("err", err);
    return false;
  }
}

export const flattenObjectToArray = (obj, parentKey = "", result = []) => {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey} - ${key}` : key;

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        flattenObjectToArray(item, `${newKey} [${index + 1}]`, result);
      });
    } else if (typeof value === "object" && value !== null) {
      flattenObjectToArray(value, newKey, result);
    } else {
      result.push([newKey, value]);
    }
  }
  return result;
};

export function objectToNameValuePairs(obj) {
  const result = [];

  for (const [name, value] of Object.entries(obj)) {
    result.push([name, value]);
  }

  return result;
}

export async function getHistoricalPrices(partNumber) {
  let prices = [];

  // for (const pricelist of pricelists) {
  //   const price = pricelist.find((u) => u.partNumber === partNumber).price;
  //   prices.push(parseFloat(price));
  // }

  return prices;
}

export const fetchLatestCSV = async (user) => {
  // TODO Encrypt the data
  try {
    const response = await API.graphql(
      graphqlOperation(listCSVFiles, { limit: 1 })
    );
    const latestFile = response.data.listCSVFiles.items[0];
    console.log("latest file is ", latestFile);

    // setLastCSV(latestFile);

    // Check if the uploadedAt date is the same in local storage
    const cachedDate = localStorage.getItem("uploadedAt");
    let jsonData;
    if (
      cachedDate &&
      new Date(cachedDate).getTime() ===
        new Date(latestFile.uploadedAt).getTime()
    ) {
      // Use cached data
      jsonData = JSON.parse(localStorage.getItem("csvData"));
    } else {
      // Fetch the file from S3
      const file = await Storage.get(latestFile.s3Key);

      const response2 = await fetch(file);

      const updatedUser = { ...user, activePricelist: latestFile.s3Key };

      console.log("updating existing user", updatedUser);

      await API.graphql(graphqlOperation(updateUser, { input: updatedUser }));

      // Parse the data as JSON
      jsonData = await response2.json();

      // Cache the data in local storage
      localStorage.setItem("csvData", JSON.stringify(jsonData));
      localStorage.setItem("uploadedAt", latestFile.uploadedAt);
    }
    return jsonData;
  } catch (error) {
    console.error("Error fetching CSV data:", error);
  }
};
