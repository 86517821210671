import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Snackbar,
  Stack,
  InputAdornment,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { getUser, listUsers } from "../../graphql/queries";
import { updateUser, createUser } from "../../graphql/mutations";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { Amplify } from "aws-amplify";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const UserForm = ({ username, onComplete, inviteCognitoUser = true }) => {
  const { userRecord } = useContext(AppContext); // Get the user from the AppContext
  const user = userRecord;
  console.log("user in email form", user);
  const [userDetails, setUserDetails] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);

  const navigate = useNavigate();

  const popFeedback = (message) => {
    setUserFeedback(message);
    setShowFeedback(true);
  };

  const formik = useFormik({
    initialValues: {
      email: userDetails?.email || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      checkUser(values.email);
      
    },
  });

  console.log("got username in params", username);

  async function createCognitoUser(email, password) {
    try {
      const user = await Amplify.Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional attribute, can be used to set custom attributes
        },
      });

      console.log("User signed up:", user);

      // Trigger the verification email

      await Auth.resendSignUp(user);
      await Auth.sendSignUpCode(user);
      console.log(`Verification code sent to ${user.attributes.email}`);
    } catch (error) {
      console.error("Error signing up user:", error);
    }
  }

  async function createCognitoUserBroke(username, password, email) {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
     
      console.log(`Verification code sent to ${email}`);
    } catch (error) {
      console.error("Error signing up user:", error);
    }
  }

  const checkUser = async (email) => {
    const existingUsersResponse = await API.graphql(
      graphqlOperation(listUsers, {
        filter: { email: { eq: email } },
      })
    );

    console.log("existingUsersResponse", existingUsersResponse);

    let existingUser = existingUsersResponse?.data.listUsers.items?.find(
      (user) => user.email === email
    );

    if (inviteCognitoUser) {
      createCognitoUser(email, "Temp1234");
    }

    if (!existingUser) {
      const updatedUser = {
        email: formik.values.email,
        createdByUserId: user.id,
        priceListInvites: [user.id],
      };
      console.log("creating new user", updatedUser);

      const newUserResponse = await API.graphql(
        graphqlOperation(createUser, {
          input: updatedUser,
        })
      );

      console.log("newUserResponse", newUserResponse);
      if (newUserResponse) {
        setShowFeedback(true);
      }

      // popFeedback("User does not exist.  Let's complete their details.");
      navigate(`/users`);
    } else {
      if (!existingUser.priceListInvites) {
        const userToUpdate = {
          email: existingUser.email,
          id: existingUser.id,
          priceListInvites: [user.id],
        };
        popFeedback("An invite has been sent to the user.");
        const updatedUserResponse = await API.graphql(
          graphqlOperation(updateUser, { input: userToUpdate })
        );
        return true;
      }

      if (
        existingUser.priceListInvites &&
        existingUser.priceListInvites.includes(user.id)
      ) {
        popFeedback("An invite has already been sent to the user.");
        return false;
      }

      if (
        existingUser.priceListInvites &&
        !existingUser.priceListInvites.includes(user.id)
      ) {
        const userToUpdate = {
          email: existingUser.email,
          id: existingUser.id,
          priceListInvites: [...existingUser.priceListInvites, user.id],
        };
        const updatedUserResponse = await API.graphql(
          graphqlOperation(updateUser, { input: userToUpdate })
        );

        console.log("got updatedUserResponse", updatedUserResponse);

        popFeedback("An invite has been sent to the user.");
        return false;
      }

    }

    // setUserDetails(existingUser);

    console.log("existingUser", existingUser);
  };

  const handleClose = () => {
    setShowFeedback(false);
  };



  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        email: userDetails.email || "",
      });
    }
  }, [userDetails]);

  return (
    <Container>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 4 }}
      >
        <Typography variant="h6" gutterBottom>
          Customer Registration
        </Typography>
        {/* <Button variant="contained" startIcon={<PhotoCamera />} sx={{ mb: 2 }}>
          Scan QR Code
        </Button> */}

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Primary Email Address"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          margin="normal"
          required
        />

        <hr />
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            disabled={!formik.isValid}
          >
            Submit
          </Button>
        </Stack>

        <Snackbar
          open={showFeedback}
          autoHideDuration={1000}
          onClose={handleClose}
          message={userFeedback}
        />
      </Box>
    </Container>
  );
};

export default UserForm;
