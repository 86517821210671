import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowDownIcon from "@mui/icons-material/ArrowCircleDown";
import { useTheme } from "@mui/material/styles";

export default function MetalsBlock({ metal, toggled }) {
  const theme = useTheme();

  if (!metal || !metal.data || metal.data.length < 2) {
    return null; // or return a placeholder component
  }

  const lastPrice = metal.data[metal.data.length - 1].y;
  const nextToLastPrice = metal.data[metal.data.length - 2].y;

  const up = lastPrice > nextToLastPrice;

  const movement = ((lastPrice - nextToLastPrice) / lastPrice) * 100;

  return (
    <Stack
      sx={{
        flexGrow: 1,
        borderRadius: "10px",
        borderColor: toggled ? theme.palette.primary.main : "gray",
        borderWidth: "2px",
        borderStyle: "solid",
        padding: "5px",
      }}
    >
      <Stack
        direction={"row"}
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography
          variant="h4"
          sx={{ fontFamily: "Poppins", fontWeight: 500 }}
        >
          {metal && metal.name && metal.name[0]}
          {metal && metal.name && metal.name[1].toLowerCase()}
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 500,
            color: up ? "green" : "red",
          }}
        >
          ${parseInt(lastPrice)}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 500,
            color: up ? "green" : "red",
          }}
        >
          {movement.toFixed(1)}%
        </Typography>
        {up ? (
          <ArrowUpIcon sx={{ color: "green" }} />
        ) : (
          <ArrowDownIcon sx={{ color: "red" }} />
        )}
      </Stack>
    </Stack>
  );
}
