import React, { useEffect, useState, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Alert,
  Button,
  CircularProgress,
  Typography,
  Box,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Papa from "papaparse";

import { Auth } from "aws-amplify";
import SimpleTable from "../SimpleTable";
import VehicleCard from "../VehicleCard";
import PartRow from "../PartRow";
import { AppContext } from "../../App";
import { listMappedVINS, getPricelist } from '../../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { getMakes, getYears, getModels, getEngines } from '../../graphql/queries';
import VehicleDialog from "./components/VehicleDialog";
import SearchCounter from './components/SearchCounter';
import { useSearchCounter } from '../../hooks/useSearchCounter';
import SearchProgressModal from './components/SearchProgressModal';
import { useUser } from '../../UserContext'; // Add this import
import { useNavigate } from 'react-router-dom'; // Add this import

function CarSelector() {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [engine, setEngine] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);
  const [carData, setCarData] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [oops, setOops] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [matchedParts, setMatchedParts] = useState([]);
  const { userRecord, refreshUser } = useContext(AppContext);

  const [mappedVINs, setMappedVINs] = useState([]);
  const [pricelistMatches, setPricelistMatches] = useState([]);

  const [makes, setMakes] = useState([]);
  const [years, setYears] = useState([]);
  const [models, setModels] = useState([]);
  const [engines, setEngines] = useState([]);
  const [page, setPage] = useState(0);

  const [openResultModal, setOpenResultModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { activeProvider } = useUser(); // Add this line
  const navigate = useNavigate(); // Add this line

  const {
    searchesRemaining,
    decrementSearchesRemaining,
    openModal,
    setOpenModal,
    handleGetMoreSearches: originalHandleGetMoreSearches,
  } = useSearchCounter();

  // Add this function
  const getRelativeLink = (path) => {
    if (activeProvider) {
      return `/${activeProvider.companyPath}${path}`;
    }
    return path;
  };

  // Modify handleGetMoreSearches
  const handleGetMoreSearches = () => {
    setOpenModal(false);
    navigate(getRelativeLink('/contact'));
  };

  useEffect(() => {
    // Fetch the uniqueMakes.json file
    fetch("/unique_makes.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setMakes(data)})
      .catch((error) => console.error("Error fetching makes:", error));

    // Generate years from 1990 to 2024
    const yearRange = Array.from({ length: 35 }, (_, i) => 2024 - i);
    setYears(yearRange);

  }, []);

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    setYear("");
    setModel("");
    setEngine("");
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear.toString());
    setModel("");
    setEngine("");

    // Fetch the corresponding JSON file
    fetch(`https://s3.us-east-2.amazonaws.com/366images/${selectedYear}-${make}.json`)
      .then((response) => response.json())
      .then((data) => {
        const uniqueModels = [...new Set(data.map((item) => item.Model))];
        setModels(uniqueModels);
      })
      .catch((error) => console.error("Error fetching models:", error));
  };

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);
    setEngine("");

    // Filter engines based on the selected model
    fetch(`https://s3.us-east-2.amazonaws.com/366images/${year}-${make}.json`)
      .then((response) => response.json())
      .then((data) => {
        const filteredEngines = data
          .filter((item) => item.Model === selectedModel)
          .map((item) => item.Engine_Size_L);
        const uniqueEngines = [...new Set(filteredEngines)];
        setEngines(uniqueEngines);
      })
      .catch((error) => console.error("Error fetching engines:", error));
  };

  const handleEngineChange = (e) => {
    setEngine(e.target.value);
  };

  useEffect(() => {
    fetchMappedVINs();
  }, []);

  console.log("mappedVINs", mappedVINs);

  const fetchMappedVINs = async () => {
    try {
      const result = await API.graphql(graphqlOperation(listMappedVINS, {
        limit: 1,
        sortDirection: 'DESC',
      }));
      if (result.data && result.data.listMappedVINS && result.data.listMappedVINS.items.length > 0) {
        const latestUpload = result.data.listMappedVINS.items[0];

        if (latestUpload.s3Key) {
          try {
            const s3Data = await Storage.get(latestUpload.s3Key, { download: true });
            const vinsData = JSON.parse(await s3Data.Body.text());
            setMappedVINs(vinsData);
          } catch (s3Error) {
            console.error('Error fetching or parsing S3 data:', s3Error);
          }
        } else {
          console.error('No S3 key found in the latest upload');
        }
      } else {
        console.log('No MappedVIN items found');
      }
    } catch (error) {
      console.error('Error fetching mapped VINs:', error);
    }
  };

  useEffect(() => {
    let filteredParts = [];
    if (engine) {
      filteredParts = mappedVINs.filter(
        (car) => car.Make === make && car.Year === year && car.Model === model && car["DisplacementL"] === engine
      );
    } else if (model) {
      filteredParts = mappedVINs.filter(
        (car) => car.Make === make && car.Year === year && car.Model === model
      );
    } else if (year) {
      filteredParts = mappedVINs.filter(
        (car) => car.Make === make && car.Year === year
      );
    } else if (make) {
      filteredParts = mappedVINs.filter(
        (car) => car.Make === make
      );
    }
    setMatchedParts(filteredParts);
  }, [make, year, model, engine, mappedVINs]);

  useEffect(() => {
    const fetchPricelistData = async () => {
      if (userRecord && userRecord.activePricelistID && matchedParts.length > 0) {
        try {
          const response = await API.graphql(
            graphqlOperation(getPricelist, { id: userRecord.activePricelistID })
          );
          const latestFile = response.data.getPricelist;

          const fileUrl = await Storage.get(latestFile.s3Key, {
            download: true,
          });

          if (fileUrl.Body) {
            const blob = new Blob([fileUrl.Body], { type: "application/json" });
            const jsonText = await blob.text();
            const jsonData = JSON.parse(jsonText);
            const pricelistMatches = matchedParts.flatMap(part => {
              const partNumbers = [part["Unit 1"], part["Unit 2"], part["Unit 3"], part["Unit 4"], part["Unit 5"]].filter(Boolean);
              return partNumbers.map(partNumber => {
                return jsonData.find(item => item["PART NUMBER"].replace(/\s+/g, '') === partNumber.replace(/\s+/g, ''));
              }).filter(Boolean);
            });
            setPricelistMatches(pricelistMatches);
            const prices = pricelistMatches.map(part => parseFloat(part["adjustedPrice"])).filter(Boolean);

            if (prices.length > 0) {
              setPriceRange({
                min: Math.min(...prices),
                max: Math.max(...prices)
              });
            } else {
              setPriceRange({ min: 0, max: 0 });
            }
          }
        } catch (error) {
          console.error("Error fetching pricelist data:", error);
        }
      }
    };

    fetchPricelistData();
  }, [matchedParts, userRecord]);

  const fetchPrediction = async () => {
    if (searchesRemaining === 0) {
      setOpenModal(true);
      return;
    }

    setIsSearching(true);
    try {
      const engineSizeL = parseFloat(engine);
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      const payload = {
        make: make,
        year: parseInt(year),
        engineSize: engineSizeL,
        cylinders: parseInt(engine.split(' ')[0]),
        cc: engineSizeL * 1000
      };

      console.log("payload", payload);

      const response = await fetch(
        "https://1un9dupmd6.execute-api.us-east-1.amazonaws.com/dev/predict",
        {
          method: 'POST',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      const apiInfo = JSON.parse(data.body);

      if (apiInfo) {

        console.log("apiInfo", apiInfo);
        // const carToSet = {
        //   Make: apiInfo.vehicle.Make,
        //   Model: model, // Using selected model as it's not returned by the API
        //   Year: apiInfo.vehicle.Year,
        //   Engine: apiInfo.vehicle["Displacement (L)"],
        //   CC: parseFloat(apiInfo.vehicle["Displacement (CC)"]),
        //   Cylinders: parseInt(apiInfo.vehicle["Engine Number of Cylinders"]),
        //   "Number of Converters": apiInfo.converters,
        //   priceRange: apiInfo.priceRange,
        // };

        // setVehicleInfo();
        setSelectedCar({
          Make: make,
          Model: model,
          Year: year,
          Engine: engine,
          // CC: apiInfo.vehicle["Displacement (CC)"],
          // Cylinders: parseInt(apiInfo.vehicle["Engine Number of Cylinders"]),
          "Number of Converters": apiInfo.converters,
          priceRange: apiInfo.priceRange,
        });
        setOpenResultModal(true);
        await decrementSearchesRemaining();
      }
    } catch (error) {
      console.error('Error fetching prediction:', error);
    }
    setIsSearching(false);
  };


  const matchedUnitsMake = mappedVINs.filter((car) => car.Make === make).length;
  const matchedUnitsYear = mappedVINs.filter((car) => car.Make === make && car.Year === year).length;
  const matchedUnitsModel = mappedVINs.filter((car) => car.Make === make && car.Year === year && car.Model === model).length;
  const matchedUnitsEngine = mappedVINs.filter((car) => car.Make === make && car.Year === year && car.Model === model && car["DisplacementL"] === engine).length;

  const vehicleInfoArrayed = vehicleInfo ? Object.entries(vehicleInfo).filter(([_, value]) => value) : [];

  const topMatchedParts = matchedParts.slice(0, 10);
  console.log("topMatchedParts", topMatchedParts);

  console.log("pricelistMatches", pricelistMatches);

  if (oops) {
    return (
      <Alert severity="error" style={{ margin: "20px" }}>
        Oops! Something went wrong fetching the data. We are working through
        known issues and will have this fixed soon.
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        width: "100%",
        flexGrow: 1,
        gap: 2,
        padding: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Vehicle Search
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Select your vehicle details to find matching parts. Start with the make and work your way down.
      </Typography>

      <SearchCounter
        searchesRemaining={searchesRemaining}
        onGetMoreSearches={handleGetMoreSearches}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Make</InputLabel>
            <Select value={make} onChange={handleMakeChange}>
              {makes.map((make) => (
                <MenuItem key={make} value={make}>
                  {make}
                </MenuItem>
              ))}
            </Select>
            {/* {make && (
              <Typography variant="caption" color="textSecondary">
                {matchedUnitsMake} matched cars
              </Typography>
            )} */}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth disabled={!make}>
            <InputLabel>Year</InputLabel>
            <Select value={year} onChange={handleYearChange}>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {parseInt(year).toFixed(0)}
                </MenuItem>
              ))}
            </Select>
            {/* {year && (
              <Typography variant="caption" color="textSecondary">
                {matchedUnitsYear} matched cars
              </Typography>
            )} */}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth disabled={!year}>
            <InputLabel>Model</InputLabel>
            <Select value={model} onChange={handleModelChange}>
              {models.sort().map((model) => (
                <MenuItem key={model} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
            {/* {model && (
              <Typography variant="caption" color="textSecondary">
                {matchedUnitsModel} matched cars
              </Typography>
            )} */}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth disabled={!model}>
            <InputLabel>Engine Size</InputLabel>
            <Select value={engine} onChange={handleEngineChange}>
              {engines.sort().map((engine) => (
                <MenuItem key={engine} value={engine}>
                  {engine}
                </MenuItem>
              ))}
            </Select>
            {/* {engine && (
              <Typography variant="caption" color="textSecondary">
                {matchedUnitsEngine} matched cars
              </Typography>
            )} */}
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={2} sx={{display: "flex", justifyContent: "center", marginTop: "10px"}}><Button
        variant="contained"
        color="primary"
        startIcon={!isLoading && <SearchIcon />}
        onClick={fetchPrediction}
        sx={{ mt: 2, alignSelf: "center" }}
        disabled={!make || !year || !model || !engine || isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Search"
        )}
      </Button>
      </Box>

      <VehicleDialog
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        car={selectedCar}
        vehicleInfo={vehicleInfoArrayed}
        onNewSearch={() => {
          setOpenResultModal(false);
          setMake("");
          setYear("");
          setModel("");
          setEngine("");
          setSelectedCar(null);
        }}
      />

      {/* {selectedCar && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Typography variant="h6" gutterBottom align="center">
              Selected Vehicle
            </Typography>
            <VehicleCard car={selectedCar} />
          </Box>
        </Box>
      )}

      {vehicleInfoArrayed.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Vehicle Details
          </Typography>
          <SimpleTable arr={vehicleInfoArrayed} />
        </Box>
      )} */}

      {/* {make && year && (
        <Box sx={{ mt: 4, backgroundColor: 'background.paper', borderRadius: 2, padding: 3, boxShadow: 1 }}>
          <Typography variant="h6" color="primary" gutterBottom>
            Search Results
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Price Range:
              </Typography>
              <Typography variant="h5" color="secondary">
                ${priceRange.min.toFixed(2)} - ${priceRange.max.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Matched Parts:
              </Typography>
              <Typography variant="h5" color="secondary">
                {pricelistMatches.length}
              </Typography>
            </Grid>
          </Grid>
          
        </Box>
      )} */}

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Out of Searches"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've run out of searches. Please purchase more searches to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleGetMoreSearches} color="primary" autoFocus>
            Get More Searches
          </Button>
        </DialogActions>
      </Dialog>

      <SearchProgressModal open={isSearching} />
    </Box>
  );
}

export default CarSelector;