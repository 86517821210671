import * as React from "react";
import EmailInvite from "../components/user/EmailInvite";
import { useParams } from "react-router-dom";
import InnerPage from "../components/InnerPage";

export default function EmailInviteScreen({ user, onComplete }) {
  const { username } = useParams();

  console.log("got username in params page", username);

  return (
    <InnerPage title="Invite a User">
      <EmailInvite username={username} user={user} onComplete={onComplete} />
    </InnerPage>
  );
}
