import { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../graphql/mutations";
import { useUser } from "../UserContext";

export const useSearchCounter = () => {
  const { userRecord, refreshUser } = useUser();
  const [searchesRemaining, setSearchesRemaining] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (userRecord) {
      console.log("userRecord", userRecord);
      const searchesRemaining = userRecord.searchesRemaining ? userRecord.searchesRemaining : 100;
      console.log("searchesRemaining", searchesRemaining);
      setSearchesRemaining(searchesRemaining);
    }
  }, [userRecord]);

  const decrementSearchesRemaining = async () => {
    if (searchesRemaining > 0) {
      const updatedSearchesRemaining = searchesRemaining - 1;
      setSearchesRemaining(updatedSearchesRemaining);

      await API.graphql(graphqlOperation(updateUser, {
        input: {
          id: userRecord.id,
          searchesRemaining: updatedSearchesRemaining,
        },
      }));

      if (updatedSearchesRemaining === 0) {
        setOpenModal(true);
      }
    }
  };

  const handleGetMoreSearches = () => {
    setOpenModal(true);
  };

  return {
    searchesRemaining,
    decrementSearchesRemaining,
    openModal,
    setOpenModal,
    handleGetMoreSearches,
  };
};