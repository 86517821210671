import React from "react";
import { Drawer, Box, Typography, ListItemText, ListItem } from "@mui/material";

const PricelistSidebar = ({ pricelist, open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: 300,
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Pricelist Details
        </Typography>
        {pricelist?.name && (
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Typography variant="h6" color="text.primary">
                  {pricelist?.name}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography variant="body2" color="text.primary">
                    Created On:{" "}
                    {new Date(pricelist.createdAt).toLocaleDateString()} -{" "}
                    {new Date(pricelist.createdAt).toLocaleTimeString()}
                  </Typography>
                  <br />
                  <Typography variant="body2" color="text.primary">
                    Parts: {pricelist.validRows}
                  </Typography>
                  <br />
                  Uploaded by: {pricelist?.user?.username}
                  <br />
                  Pricelist ID: {pricelist.id}
                </React.Fragment>
              }
            />
          </ListItem>
        )}
      </Box>
    </Drawer>
  );
};

export default PricelistSidebar;
