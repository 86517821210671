import React, { useState, useEffect } from "react";
import { useUser } from "../UserContext";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateUser, updatePricelist } from "../graphql/mutations";
import { listPricelists } from "../graphql/queries";
import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert,
  Paper,
  Grid,
  IconButton,
  Stack,
  Input,
} from "@mui/material";
import { colors, lighten } from "@mui/material";
import { Auth } from "aws-amplify";
import UserForm from "./user/UpdateForm";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import  awsmobile  from "../aws-exports";

const SettingsPage = () => {
  const { userGroups, userRecord, setUserRecord, providerTheme } = useUser();
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState(
    theme.palette.secondary.main
  );
  const [themeOptions, setThemeOptions] = useState({
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
  });
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [logo, setLogo] = useState(userRecord?.companyLogo || null);

  console.log(userGroups);

  const isAdmin = userGroups.includes("admin");
  const isCustomer =
    userRecord &&
    userRecord.priceListInvites &&
    userRecord.priceListInvites.length > 0;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
        // If the logo hasn't been set yet, set it from the userRecord
        if (!logo && userRecord?.companyLogo) {
          setLogo(userRecord.companyLogo);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setSnackbarMessage("Failed to fetch user data. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userRecord, logo]);

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("Passwords do not match.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        currentUser.signInUserSession.accessToken.jwtToken,
        newPassword
      );
      setSnackbarMessage("Password changed successfully!");
      setSnackbarSeverity("success");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.deleteUser(currentUser);
      setSnackbarMessage("Account deleted successfully.");
      setSnackbarSeverity("success");
      // Add any additional logout or redirect logic here
    } catch (error) {
      console.error("Error deleting account:", error);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
    }
  };

  const handleColorChange = async (colorType, color) => {
    const newThemeOptions = { ...themeOptions, [colorType]: color };
    setThemeOptions(newThemeOptions);

    try {
      // Update the user's theme options
      const updatedUser = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: userRecord.id,
            themeOptions: JSON.stringify(newThemeOptions),
          },
        })
      );

      // Update the user context
      setUserRecord(updatedUser.data.updateUser);

      const newTheme = createTheme({
        ...currentTheme,
        palette: {
          ...currentTheme.palette,
          [colorType]: {
            main: color,
          },
        },
      });
      setCurrentTheme(newTheme);
      localStorage.setItem("currentTheme", JSON.stringify(newTheme));
      localStorage.setItem("appliedTheme", JSON.stringify(newTheme));

      setSnackbarMessage(
        `${colorType.charAt(0).toUpperCase() + colorType.slice(1)} color updated successfully!`
      );
      setSnackbarSeverity("success");
    } catch (error) {
      console.error(`Error updating ${colorType} color:`, error);
      setSnackbarMessage(`Error updating ${colorType} color: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const fileExtension = file.name.split('.').pop();
      const fileName = `logos/${userRecord.id}-${Date.now()}.${fileExtension}`;
      
      console.log('Uploading file:', fileName);

      const result = await Storage.put(fileName, file, {
        contentType: file.type,
        level: 'public',
      });

      console.log('Storage.put result:', result);

      // Generate the public URL
      const logoUrl = await Storage.get(fileName, { level: 'public' });

      console.log('Generated Logo URL:', logoUrl);

      // Update the user's company logo
      const updatedUser = await API.graphql(graphqlOperation(updateUser, {
        input: { id: userRecord.id, companyLogo: logoUrl }
      }));

      console.log('Updated user:', updatedUser.data.updateUser);

      // Update the user context
      setUserRecord(updatedUser.data.updateUser);

      setLogo(logoUrl);
      
      setSnackbarMessage("Logo uploaded successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error uploading logo:", error);
      setSnackbarMessage(`Error uploading logo: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const ColorSwatch = ({ color, onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        width: 40,
        height: 40,
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
      }}
    />
  );

  const secondaryColors = [
    colors.pink[300],
    colors.purple[300],
    colors.deepPurple[300],
    colors.indigo[300],
    colors.blue[300],
    colors.lightBlue[300],
    colors.cyan[300],
    colors.teal[300],
    colors.green[300],
    colors.lightGreen[300],
    colors.lime[300],
    colors.yellow[300],
    colors.amber[300],
    colors.orange[300],
    colors.deepOrange[300],
    colors.brown[300],
    colors.grey[300],
    colors.blueGrey[300],
    "#ff5e00",
    "#000"
  ];

  if (loading) {
    return (
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 5 }}>
          User not authenticated. Please log in and try again.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
        Settings
      </Typography>

      <Divider sx={{ mb: 4 }} />

      <Stack spacing={2} >
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>

        <TextField
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handlePasswordChange}
          sx={{ mt: 1 }}
        >
          Change Password
        </Button>
      </Stack>

      <Divider sx={{ my: 4 }} />
      <Stack>
        <UserForm user={user} username={user.username} />
        
        <Stack spacing={2} sx={{ mb: 4, p: 2, }}>
        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom color="error">
          Danger Zone
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete Account
        </Button>
        </Stack>
      </Stack>

      {/* {isAdmin && ( */}
        <>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" gutterBottom>
            Customize User Experience
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Primary Color</Typography>
              <Typography color="secondary" variant="subtitle2" sx={{marginBottom: '10px'}}>Click to select color (Changes header and primary buttons)</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                {Object.entries(colors).map(([colorName, colorShades]) => (
                  <ColorSwatch
                    key={colorName}
                    color={colorShades[500]}
                    onClick={() =>
                      handleColorChange("primary", colorShades[500])
                    }
                  />
                ))}
                <ColorSwatch
                  color="#00261f"
                  onClick={() => handleColorChange("primary", "#00261f")}
                />

                <ColorSwatch
                  color="#d3302f"
                  onClick={() => handleColorChange("primary", "#d3302f")}
                />

                {/* <TextField
                  label="Hex Code"
                  size="small"
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                  onBlur={() => handleColorChange("primary", primaryColor)}
                  sx={{ width: "120px", ml: 1 }}
                /> */}
                
              </Box>
              
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">Secondary Color</Typography>
              <Typography color="secondary" variant="subtitle2" sx={{ marginBottom: '10px' }}>Click to select color (Changes icon colors and secondary buttons)</Typography>
              
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                {secondaryColors.map((color, index) => (
                  <ColorSwatch
                    key={index}
                    color={color}
                    onClick={() => handleColorChange("secondary", color)}
                  />
                ))}
                
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Company Logo
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  sx={{ display: "none" }}
                  id="logo-file-input"
                />
                <label htmlFor="logo-file-input">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<UploadFileIcon />}
                    disabled={loading}
                  >
                    {loading ? "Uploading..." : "Upload Logo"}
                  </Button>
                </label>
                {logo && (
                  <Box sx={{ maxWidth: '100px', maxHeight: '100px', overflow: 'hidden' }}>
                    <img 
                      src={logo} 
                      alt="Company Logo" 
                      style={{ width: '100%', height: 'auto', objectFit: 'contain' }} 
                      onError={(e) => {
                        console.error("Error loading logo:", e);
                        e.target.src = "/CC Logo (Black Background).png"; // Fallback image
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      {/* )} */}

      {isCustomer && (
        <Typography variant="body1" sx={{ mt: 3 }}>
          Theme customization is managed by your pricelist provider.
        </Typography>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
    </Container>
  );
};

export default SettingsPage;
