import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react"; // Make sure this import is correct
import CustomAuthenticator from "./components/CustomAuthenticator";
import UserDetailsScreen from "./pages/UserDetails";
import ProviderList from "./components/ProviderList";
import LiteDashboardScreen from "./pages/LiteDashboard";
import { UserProvider, useUser } from "./UserContext";
import ProviderRoutes from './components/ProviderRoutes';
import AdminView from "./components/AdminView";

import "@aws-amplify/ui-react/styles.css";

import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { listUsers, getPricelist } from "./graphql/queries";
import { createUser } from "./graphql/mutations";
import { API, graphqlOperation, Hub } from "aws-amplify";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CircularProgress, Box } from "@mui/material";

import DashboardScreen from "./pages/Dashboard";
import ContactScreen from "./pages/Contact";
import AnalyticsScreen from "./pages/Analytics";
import ViewPricelistsScreen from "./pages/ViewPricelists";
import PricelistsMenuScreen from "./pages/pricelists/PricelistsMenu";
import SearchPricelistScreen from "./pages/pricelists/SearchPricelist";
import AIScreen from "./pages/AI";
import PricelistGroupsScreen from "./pages/pricelists/PricelistGroups";
import CreatePricelistScreen from "./pages/pricelists/CreatePricelist";
import UploadPricelistScreen from "./pages/pricelists/UploadPricelist";
import AssignPricelistScreen from "./pages/pricelists/AssignPricelist";
import NewsScreen from "./pages/News";
import InvoicesScreen from "./pages/Invoices";
import ViewInvoiceScreen from "./pages/ViewInvoice";
import UsersScreen from "./pages/Users";
import UserGroupManagerScreen from "./pages/UserGroupManager";
import AlertsScreen from "./pages/Alerts";
import MessagesScreen from "./pages/Messages";
import NotificationsScreen from "./pages/Notifications";
import EmailInvite from "./pages/EmailInvite";
import TrendingUnitsScreen from "./pages/Trending";
import PlayerScreen from "./pages/PlayerScreen";
import SuperAdminUsersScreen from "./pages/SuperAdminUsers"
import SettingsScreen from "./pages/SettingsScreen";

import { appConfig } from "./config";

import { useNavigate } from "react-router-dom";

import AdminPage from './components/AdminPage';
import NotificationIcon from "./components/NotificationIcon";
import { useAuthenticator } from '@aws-amplify/ui-react';

// Useful Info - To switch between different AWS accounts, set the AWS_profile environment variable at the command line via export AWS_PROFILE=profile_name. Setting the env variable changes the default profile until the end of your shell session or until you set the variable to a different value.

export const AppContext = createContext();

const customConfig = {
  ...awsconfig,
  API: {
    ...awsconfig.API,
    endpoints: [
      ...(awsconfig.API?.endpoints || []),
      {
        name: "vehicleAPI",
        endpoint: "https://7q7dpbzfta.execute-api.us-east-1.amazonaws.com/dev",
        service: "execute-api",
        region: "us-east-1",
        custom_header: async () => {
          const session = await Auth.currentSession();
          const token = session.getAccessToken().getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    ],
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: "5ebbbcb33111488d975a6c493ee663ad", // Replace with your Pinpoint App ID
      region: "us-east-1", // Replace with your Pinpoint region
    },
  },
};

Amplify.configure(customConfig);

const AppContent = () => {
  const { 
    user, 
    setUser,
    userRecord, 
    setUserRecord,
    loading,
    setLoading,
    providerTheme,
    currentTheme,
    refreshUser,
    userClass
  } = useUser();
  
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('appliedTheme');
    return savedTheme ? createTheme(JSON.parse(savedTheme)) : createTheme();
  });

  const [userGroup, setUserGroup] = useState(null);
  const [selectedPricelist, setSelectedPricelist] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [providers, setProviders] = useState([]);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: appConfig.primaryColor,
      },
      secondary: {
        main: appConfig.secondaryColor,
      },
    },
  });

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setUserRecord(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    async function getAuthData() {

      try {
        const authUser = await Auth.currentAuthenticatedUser();

        setUser(authUser);
        await validateExistingUser(authUser);
      } catch (error) {
        console.error("Error getting auth data", error);
        setUser(null);
        setUserRecord(null);
      } finally {
        setLoading(false);
      }
    }
    if (authStatus === 'authenticated') {
      getAuthData();
    } else {
      setLoading(false);
    }
  }, [authStatus]);

  const validateExistingUser = async (user) => {

    const { attributes } = user;
    if (!attributes || !attributes.email) {
      console.error("User attributes or email is missing.");
      return;
    }

    try {
      // Attempt to find an existing user
      const existingUsersResponse = await API.graphql(
        graphqlOperation(listUsers, {
          filter: { email: { eq: attributes.email } },
        })
      );


      const existingUser = existingUsersResponse?.data?.listUsers?.items?.find(
        (userItem) => userItem.email === attributes.email
      );

      if (!existingUser) {
        // User does not exist, proceed to create a new user in DB
        const newUser = {
          username: attributes.email,
          email: attributes.email,
          searchesRemaining: 100, // Set default value for new users
        };

        // Create user in DB
        const createUserResponse = await API.graphql(graphqlOperation(createUser, { input: newUser }));
        // console.log("New user created:", createUserResponse);
        setUserRecord(createUserResponse.data.createUser);
      } else {

        // User already exists, set the user record as needed
        if (existingUser.activePricelistID) {
          const activePricelistResponse = await API.graphql(
            graphqlOperation(getPricelist, { id: existingUser.activePricelistID })
          );
          existingUser.activePricelist = activePricelistResponse.data.getPricelist;
        }

        setUserRecord(existingUser);
      }
    } catch (error) {

      if (error.errors) {
        error.errors.forEach((err) => {
          console.error("GraphQL Error:", err.message);
        });
      }
      throw new Error("Failed to validate or create user.");
    }
  };

  const handleUserDetailsComplete = async () => {
    // Refresh user data after details are updated
    if (user) {
      await refreshUser(user);
    }
  };

  const isUserSubscribed = (providerUsername) => {
    if (!userRecord || !userRecord.activePricelist) return false;
    return userRecord.activePricelist.user.username === providerUsername;
  };


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContext.Provider
          value={{
            user,
            setUser,
            userRecord,
            setUserRecord,
            setSelectedPricelist,
            userGroup,
            userClass,
            notifications,
            setNotifications,
            isUserSubscribed,
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Routes>
              <Route path="/" element={
                authStatus !== 'authenticated' ? <CustomAuthenticator /> : 
                !userRecord ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                    <CircularProgress />
                  </Box>
                ) :
                // !userRecord.username ? (
                //   <UserDetailsScreen 
                //     onComplete={handleUserDetailsComplete}
                //   />
                // ) :
                <ProviderList />
              } />
              <Route path="/:providerName/*" element={
                <AdminView>
                  <ProviderRoutes />
                </AdminView>
              } />
            </Routes>
          )}
        </AppContext.Provider>
      </Router>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Authenticator.Provider>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </Authenticator.Provider>
  );
};

export default App;