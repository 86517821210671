import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Stack,
  Switch,
  Chip,
  FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../App";
import { listUsers } from "../graphql/queries";
import { deleteUser, updateUser } from "../graphql/mutations";
import SuperAdminUpdateForm from "./user/SuperAdminUpdateForm";
import EmailInvite from './user/EmailInvite';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const ColorPicker = ({ color, onChange }) => {
  return (
    <input
      type="color"
      value={color}
      onChange={(e) => onChange(e.target.value)}
      style={{ width: '30px', height: '30px', padding: 0, border: 'none' }}
    />
  );
};

const UserDialog = ({ user, open, onClose, onEditUser }) => {
  const [editing, setEditing] = useState(false);
  const [colorDialogOpen, setColorDialogOpen] = useState(false);

  const handleColorChange = async (colorType, color) => {
    try {
      const updatedUser = await API.graphql(graphqlOperation(updateUser, {
        input: {
          id: user.id,
          [colorType]: color,
        }
      }));
      onEditUser(updatedUser.data.updateUser);
    } catch (error) {
      console.error(`Error updating ${colorType}:`, error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-dialog-title"
      aria-describedby="user-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="user-dialog-title">User Details</DialogTitle>
      <DialogContent>
        {!editing ? (
          <Stack spacing={2}>
            <Avatar
              sx={{ width: 100, height: 100, marginBottom: 2 }}
              src={user.companyLogo || "/path/to/placeholder/avatar.png"}
              alt={user.username || "User Avatar"}
            />
            <Typography variant="h6">{user.username || "User Name"}</Typography>
            <Typography>Email: {user.email || "N/A"}</Typography>
            <Typography>Active Pricelist: {user.activePricelist?.name || "N/A"}</Typography>
            <Typography>Second Pricelist: {user.secondPricelist?.name || "N/A"}</Typography>
            <Typography>Subscription: {user.subscriptionActive ? "Active" : "Inactive"}</Typography>
            <Typography>Subscription Tier: {user.subscriptionTier || "N/A"}</Typography>
            <Typography>Subscription Term: {user.subscriptionTerm || "N/A"}</Typography>
            <Typography>Searches Remaining: {user.searchesRemaining || "N/A"}</Typography>
            <Button variant="contained" onClick={() => setEditing(true)}>
              Edit User
            </Button>
            <Button variant="outlined" onClick={() => setColorDialogOpen(true)}>
              Edit Colors
            </Button>
          </Stack>
        ) : (
          <SuperAdminUpdateForm
            user={user}
            onComplete={(updatedUser) => {
              onEditUser(updatedUser);
              setEditing(false);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <Dialog open={colorDialogOpen} onClose={() => setColorDialogOpen(false)}>
        <DialogTitle>Edit User Colors</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Box display="flex" alignItems="center">
              <Typography sx={{ mr: 2 }}>Primary Color:</Typography>
              <ColorPicker
                color={user.primaryColor || "#000000"}
                onChange={(color) => handleColorChange("primaryColor", color)}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography sx={{ mr: 2 }}>Secondary Color:</Typography>
              <ColorPicker
                color={user.secondaryColor || "#000000"}
                onChange={(color) => handleColorChange("secondaryColor", color)}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setColorDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const CreateUserDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        <EmailInvite 
          inviteCognitoUser={false}
          onComplete={() => {
            onClose();
            // You might want to refresh the users list here
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const theme = useTheme();
  const { userRecord } = useContext(AppContext);

  useEffect(() => {
    const fetchUsers = async () => {
      if (userRecord) {
        const response = await API.graphql(graphqlOperation(listUsers, { limit: 100 }));
        const fetchedUsers = response.data.listUsers.items || [];
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      }
    };

    fetchUsers();
  }, [userRecord]);

  useEffect(() => {
    const filtered = users.filter((user) =>
      Object.values(user).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  const handleViewEdit = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleProviderToggle = async (user) => {
    try {
      const updatedUser = await API.graphql(graphqlOperation(updateUser, {
        input: {
          id: user.id,
          isProvider: !user.isProvider
        }
      }));
      
      setUsers(users.map(u => u.id === user.id ? {...u, isProvider: !u.isProvider} : u));
      setFilteredUsers(filteredUsers.map(u => u.id === user.id ? {...u, isProvider: !u.isProvider} : u));
      
      console.log("User provider status updated successfully");
    } catch (error) {
      console.error("Error updating user provider status:", error);
    }
  };

  const removeUser = async (user) => {
    try {
      await API.graphql(graphqlOperation(deleteUser, { input: { id: user.id } }));
      setUsers(users.filter((u) => u.id !== user.id));
      setFilteredUsers(filteredUsers.filter((u) => u.id !== user.id));
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <Box sx={{ margin: "20px" }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search Users"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 3 }}
      />
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Active Pricelist</TableCell>
              <TableCell>Searches Remaining</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.activePricelist?.name || "N/A"}</TableCell>
                <TableCell>{user.searchesRemaining || "N/A"}</TableCell>
                <TableCell>
                  <Chip 
                    label={user.subscriptionActive ? "Active" : "Inactive"} 
                    color={user.subscriptionActive ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={user.isProvider || false}
                        onChange={() => handleProviderToggle(user)}
                      />
                    }
                    label={user.isProvider ? "Yes" : "No"}
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleViewEdit(user)}>View/Edit</Button>
                  <Button color="error" onClick={() => removeUser(user)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <UserDialog
        user={selectedUser}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onEditUser={(updatedUser) => {
          setUsers(users.map(u => u.id === updatedUser.id ? updatedUser : u));
          setFilteredUsers(filteredUsers.map(u => u.id === updatedUser.id ? updatedUser : u));
          setSelectedUser(updatedUser);
        }}
      />

      <CreateUserDialog 
        open={createUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
      />

      <Fab 
        color="primary" 
        sx={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16 
        }}
        onClick={() => setCreateUserModalOpen(true)}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default Users;