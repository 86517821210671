import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listSearches, getUserInfo } from "../graphql/queries";
import { Chart } from "primereact/chart";
import { AppContext } from "../App";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  Alert,
  Button,
  Card,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Stack,
} from "@mui/material";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/north-america.json";

const Dashboard = () => {
  const [searches, setSearches] = useState([]);
  const [tooltipContent, setTooltipContent] = useState("");
  const [searches24Hours, setSearches24Hours] = useState(0);
  const [searches7Days, setSearches7Days] = useState(0);
  const [searches30Days, setSearches30Days] = useState(0);
  const [searchesByPartNumber, setSearchesByPartNumber] = useState({});
  const [searchesByDate, setSearchesByDate] = useState({});
  const [userInfo, setUserInfo] = useState(null); // New state for user info

  const { userRecord } = useContext(AppContext);

  const fetchSearches = async () => {
    if (userRecord && userRecord.id) {
      try {
        const response = await API.graphql(
          graphqlOperation(listSearches, {
            filter: {
              userSearchedID: { eq: userRecord.id },
            },
            limit: 1000,
          })
        );
        const searchesData = response.data.listSearches.items;
        if (searchesData) {
          setSearches(searchesData);
          processSearchData(searchesData);
        }
      } catch (error) {
        console.log("Error fetching searches:", error);
      }
    }
  };

  const fetchUserInfo = async () => {
    if (userRecord && userRecord.id) {
      try {
        const response = await API.graphql(
          graphqlOperation(getUserInfo, { id: userRecord.id })
        );
        setUserInfo(response.data.getUserInfo);
      } catch (error) {
        console.log("Error fetching user info:", error);
      }
    }
  };

  const processSearchData = (searchesData) => {
    const last24Hours = new Date();
    last24Hours.setDate(last24Hours.getDate() - 1);
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30);

    const partNumberCounts = {};
    const dateCounts = {};

    searchesData.forEach((search) => {
      const createdAt = new Date(search.createdAt);

      // Count searches by part number
      partNumberCounts[search.partNumber] = (partNumberCounts[search.partNumber] || 0) + 1;

      // Count searches by date
      const dateString = createdAt.toISOString().split('T')[0];
      dateCounts[dateString] = (dateCounts[dateString] || 0) + 1;

      // Count searches for different time periods
      if (createdAt > last24Hours) setSearches24Hours(prev => prev + 1);
      if (createdAt > last7Days) setSearches7Days(prev => prev + 1);
      if (createdAt > last30Days) setSearches30Days(prev => prev + 1);
    });

    setSearchesByPartNumber(partNumberCounts);
    setSearchesByDate(dateCounts);
  };

  useEffect(() => {
    fetchSearches();
    fetchUserInfo(); // Fetch user info on component mount
  }, [userRecord]);

  // Prepare data for charts
  const top10Searches = Object.entries(searchesByPartNumber)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10);

  const lineChartData = {
    labels: Object.keys(searchesByDate).slice(-30),
    datasets: [{
      label: 'Searches',
      data: Object.values(searchesByDate).slice(-30),
      fill: false,
      borderColor: '#4bc0c0'
    }]
  };

  const lineChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Searches'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      }
    }
  };

  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 800,
          color: "#000",
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Map of Recent Searches
      </Typography>

      {userRecord && userRecord.id && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            height: "500px",
          }}
        >
          <MapContainer
            center={[35.4676, -97.5164]}
            zoom={4}
            scrollWheelZoom={false}
            // style={{ width: "100%", height: "500px" }}
            style={{ flexGrow: 1 }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {searches.map((search) => (
              <Marker
                key={search.id}
                position={[search.latitude, search.longitude]}
              >
                <Popup>
                  Part Number: {search.partNumber}, Price: ${search.price}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      )}
      <div style={{ margin: "20px" }}>
        <Divider flexItem variant="middle" />
      </div>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 800,
          color: "#000",
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Search Analytics
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginBottom: 4 }}>
        {[
          { label: "Last 24 Hours", value: searches24Hours },
          { label: "Last 7 Days", value: searches7Days },
          { label: "Last 30 Days", value: searches30Days },
        ].map((item) => (
          <Card key={item.label} sx={{ minWidth: 200, textAlign: 'center', padding: 2 }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{item.value}</Typography>
            <Typography variant="subtitle1">{item.label}</Typography>
          </Card>
        ))}
      </Stack>

      <div style={{ marginBottom: 40 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 800,
            color: "#000",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          Search Trend (Last 30 Days)
        </Typography>
        <Chart type="line" data={lineChartData} options={lineChartOptions} />
      </div>

      <Typography
        variant="h6"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 800,
          color: "#000",
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Top 10 Most Searched Parts
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Part Number</TableCell>
            <TableCell># of Searches</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {top10Searches.map(([partNumber, count]) => (
            <TableRow key={partNumber}>
              <TableCell>{partNumber}</TableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ margin: "20px" }}>
        <Divider flexItem variant="middle" />
      </div>

      {userInfo && (
        <div>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 800,
              color: "#000",
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            User Information
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginBottom: 4 }}>
            <Card sx={{ minWidth: 200, textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{userInfo.userCount}</Typography>
              <Typography variant="subtitle1">Total Users</Typography>
            </Card>
            <Card sx={{ minWidth: 200, textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{new Date(userInfo.lastLogin).toLocaleDateString()}</Typography>
              <Typography variant="subtitle1">Last Login</Typography>
            </Card>
            <Card sx={{ minWidth: 200, textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{new Date(userInfo.creationDate).toLocaleDateString()}</Typography>
              <Typography variant="subtitle1">Creation Date</Typography>
            </Card>
          </Stack>
        </div>
      )}

      <div
        className="tooltip"
        style={{ position: "fixed", pointerEvents: "none", zIndex: 9999 }}
      >
        {tooltipContent}
      </div>
    </div>
  );
};

export default Dashboard;
