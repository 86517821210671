import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listUsers } from '../graphql/queries';
import { useUser } from '../UserContext';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText, 
  Typography, 
  Avatar, 
  Divider, 
  Container, 
  AppBar, 
  Toolbar, 
  Button,
  IconButton
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';

function ProviderList() {
  const [providers, setProviders] = useState([]);
  const { userRecord } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProviders();
  }, [userRecord]);

  async function fetchProviders() {
    if (!userRecord) return;

    try {
      const providerData = await API.graphql(graphqlOperation(listUsers));
      const filteredProviders = providerData.data.listUsers.items.filter(provider => 
        userRecord.priceListInvites?.includes(provider.id) || (provider.id === userRecord.id && provider.isProvider)
      );
      console.log("filteredProviders", filteredProviders)
      setProviders(filteredProviders);
    } catch (err) {
      console.error('Error fetching providers:', err);
    }
  }

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Provider Selection
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ marginTop: '40px' }}>
        <Typography variant="h4" gutterBottom>
          Select a Provider
        </Typography>
        <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
          {providers.map((provider, index) => (
            <React.Fragment key={provider.id}>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={`/${provider.companyPath}`}>
                  <Avatar sx={{ marginRight: '20px', bgcolor: provider.id === userRecord.id ? 'secondary.main' : 'primary.main' }}>
                    <BusinessIcon />
                  </Avatar>
                  <ListItemText 
                    primary={
                      <Typography variant="subtitle1">
                        {provider.companyName || 'My Company'}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {provider.id === userRecord.id ? 'Your Company' : `Provider ID: ${provider.id}`}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              {index < providers.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
        {providers.length === 0 && (
          <Typography variant="body1" sx={{ textAlign: 'center', mt: 4 }}>
            No providers found.
          </Typography>
        )}
      </Container>
    </Box>
  );
}

export default ProviderList;